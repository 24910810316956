<template>
  <div>
    <ChatPanel
      v-show="showChat"
      :name="product?.name"
      :nft-id="$route.params.id"
      :visible="showChat"
      @close="showChat=false"
    />
    <AuBreadcrumb
      parent-name="Маркетплейс"
      parent-path="/autentic-market/marketplace"
    />

    <div class="info">
      <div class="d-lg-flex">
        <div class="col d-flex justify-content-start">
          <div class="name">
            {{ product.name }}
          </div>
          <div class="tag">
            {{ product.type }}
          </div>
        </div>

        <div class="col-lg d-lg-flex justify-content-lg-end gap-2 align-items-center">
          <span
            class="text_addon fs12"
          >Размещение на листинге: </span>
          <span
            class=" fs12"
          >{{ product.date }}</span>
        </div>
      </div>

      <div class="rating">
        <AuIcon icon="star_gold" />
        <div class="fs11">
          {{ 5 }}
        </div>
      </div>


      <div class="d-lg-flex justify-content-between">
        <div class="col d-flex gap-2 align-items-center">
          <div
            class=" fs15"
          >
            Стоимость 1 лота =
          </div>
          <div class="price">
            <AuDecimalStatic
              :value="product.price"
              :decimal="product.tokenQuote?.decimals"
              :addition="product.tokenQuote?.mnemonicEng"
            />
          </div>
        </div>

        <AuButton
          width="auto"
          height="41px"
          bordered
        >
          Проверить продавца
        </AuButton>
      </div>


      <div class="divider" />

      <div class="infos col-lg-10">
        <div class="row ms-3">
          <div class="col infos__item">
            <div
              class="text_addon fs13"
            >
              Продавец
            </div>
            <div
              class=" d-flex justify-content-center align-items-center fs13"
            >
              <span class="me-2">
                {{ product.company }}
              </span>
              <a
                href="#"
                @click="showChat=true"
              >
                Задать вопрос
              </a>
            </div>
          </div>

          <div class="col infos__item">
            <div
              class="text_addon fs13"
            >
              Страна
            </div>
            <div
              class=" d-flex justify-content-center fs13"
            >
              Россия
            </div>
          </div>

          <div class="col infos__item">
            <div
              class="text_addon fs13"
            >
              Остаток товара
            </div>
            <div
              class=" d-flex justify-content-center fs13"
            >
              125 шт.
            </div>
          </div>

          <div class="col infos__item">
            <div
              class="text_addon fs13"
            >
              Вид ЦФА
            </div>
            <div
              class=" d-flex justify-content-center fs13"
            >
              УЦП
            </div>
          </div>

          <div class="col infos__item">
            <div
              class="text_addon fs13"
            >
              Рынок сбыта
            </div>
            <div
              class=" d-flex justify-content-center fs13"
            >
              РФ, СНГ, Европа
            </div>
          </div>

          <div class="col infos__item">
            <div
              class="text_addon fs13"
            >
              Приобретено
            </div>
            <div
              class=" d-flex justify-content-center fs13"
            >
              1094 шт.
            </div>
          </div>
        </div>
      </div>

      <div class="main-panel col-lg-10 gap-10 gap-3">
        <div class="col-lg-6">
          <img
            class="img-fluid"
            :src="product.image"
            :alt="product.name"
          >
        </div>
        <div class="col-lg-6">
          <div>
            <div
              class="fs15"
            >
              Описание:
            </div>

            <div class="description">
              {{ product.description }}
            </div>

            <div
              class="fs15"
            >
              Информация:
            </div>

            <div class="info-body col-lg-12 col-sm-8 col-8">
              <div
                class="d-flex justify-content-between align-center"
              >
                <div
                  class="text_addon fs13"
                >
                  Ед.изм.:
                </div>
                <div
                  class=" fs13"
                >
                  шт.
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-center"
              >
                <div
                  class="text_addon fs13"
                >
                  Производитель:
                </div>
                <div
                  class=" fs13"
                >
                  Cherry
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-center"
              >
                <div
                  class="text_addon fs13"
                >
                  Место отгрузки:
                </div>
                <div
                  class=" fs13"
                >
                  Москва
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-center"
              >
                <div
                  class="text_addon fs13"
                >
                  Номер токена:
                </div>
                <div class="fs13 text_green">
                  #{{ product.tokenNumber }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between align-center"
              >
                <div
                  class="text_addon fs13"
                >
                  Адрес NFT контракта:
                </div>
                <div class="fs13 text_green">
                  0x63a18689c031aBFb167e5eBb018492742dd171E1
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-panel col-lg-10 gap-10 gap-3">
        <div
          class="col-lg-6 docs-panel"
        >
          <div class="">
            Документы
          </div>

          <div class="container-fluid">
            <div class="row docs">
              <div class="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p-1">
                <div class="doc">
                  Сертификат
                  соответствия
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p-1">
                <div class="doc">
                  Договор поставки
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p-1">
                <div class="doc">
                  Экономическое заключение
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p-1">
                <div class="doc">
                  Решение
                  об эмиссии
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p-1">
                <div class="doc">
                  Договор инвестирования FIAT
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-4 col-sm-6 col-6 p-1">
                <div class="doc">
                  Договор инвестирования USDT
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6"
        >
          <div class="">
            Оформление заказа
          </div>

          <div class="card">
            <div class="col d-flex gap-2 align-items-center">
              <div
                class=" fs15"
              >
                Стоимость 1 лота =
              </div>
              <div class="price">
                <AuDecimalStatic
                  :value="product.price"
                  :decimal="product.tokenQuote?.decimals"
                  :addition="product.tokenQuote?.mnemonicEng"
                />
              </div>
            </div>

            <AuButton
              bordered
              width="100%"
              height="52px"
              center
              active-bg-color="#279063"
              @click="showDialog=true"
            >
              Купить
            </AuButton>
          </div>

          <a
            class="contact"
          >
            Связаться с проектом
          </a>
        </div>
      </div>
    </div>
    <AuModal
      v-model="showDialog"
      width="400px"
    >
      <template #header>
        <div class="modal_header">
          Покупка продукта
        </div>
      </template>
      <template #body>
        <div class="modal_text_small">
          Вы собираетесь приобрести продукт: {{ product.name }}
        </div>
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80"
              @click="buyNft"
            >
              Купить
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80"
              @click="showDialog=false"
            >
              Отмена
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import moment from "moment";

import ChatPanel from "@/components/ChatPanel";
import { mapActions } from "vuex";

export default {
  name: "ProductCard",
  components: { ChatPanel },
  data() {
    return {
      product: {},
      showDialog: false,
      showChat: false
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    ...mapActions({
      defaultRequest: "defaultRequest",
      getNftById: "marketplace/getNftById",
      sendTokenEvents: "sendTokenEvents"
    }),

    async getData() {
      try {
        const item = await this.getNftById(this.$route.params.id);
        if (item?.isMultiToken) {
          this.$router.replace({ name: "multitoken card", params: { id: this.$route.params.id } });
        }

        this.product = {
          id: item.id,
          price: item.price,
          tokenQuote: item.tokenQuote,
          type: item.section.name,
          name: item.productName,
          company: item.company.nameEng,
          owner: item.owner,
          date: moment(item.issuedAt).format("DD.MM.YYYY"),
          description: item.description,
          image: item.image,
          tokenNumber: item.tokenNumber
        };
      }
      catch (e) {
        console.log("error", e);
      }
    },

    async getInfo(value) {
      try {
        return this.defaultRequest(value);
      }
      catch (e) {
        console.log("error", e);
      }
    },

    showSuccess(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    async buyNft() {
      this.showDialog = false;
      const params = {
        user: `api/users/${this.$auth.user().id}`,
        createdAt: moment().format("DD.MM.YYYY"),
        type: "token_nft_buy",
        description: "buy",
        value: "1",
        address: "",
        isTask: true,
        nftNumber: this.product.id
      };

      try {
        await this.sendTokenEvents(params);
        this.showSuccess("Распоряжение на покупку NFT товара отправлено!");
      }
      catch (e) {
        this.showError("Ошибка при совершении покупки!");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.main-panel {
  display: flex;
  margin-top: 80px;
  flex-direction: row;

  @media screen and (max-width:768px) {
    flex-direction: column;
  }
}

.bottom-panel {
  display: flex;
  margin-top: 85px;
  flex-direction: row;

  @media screen and (max-width:768px) {
    flex-direction: column;
  }
}

.rating {
  display: flex;
  align-items: center;
  gap: 5px;
}

.card {
  height: 142px;
  padding: 20px;
  gap: 20px;
  margin-top: 20px;
}

.description {
  font-size: 13px;
  color: var(--color-60);
  margin-bottom: 40px;
}

.divider {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px dashed var(--color-80);
}

.infos {
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    gap: 20px
  }
}

.info-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.tag
{
  background: lightgray;
  border-radius: 10px;
  padding: 7px;
  color: black;
  font-weight: 450;
  font-size: 11px;
  margin-left: 10px;
}

.name
{
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  letter-spacing: -0.03em;
}

.text_addon{
  color: var(--color-60);
}

.contact {
  color: var(--color-60);
  font-size: 11px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.price
{
  font-style: normal;
  font-weight: 450;
  font-size: 15px;
  line-height: 100%;
  color: #28E593;
}

.docs-panel {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.docs {
  row-gap: 10px;
}

.doc
{
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: var(--card-color);
  padding: 0 10px;
  font-style: normal;
  font-size: 11px;
  height: 60px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.text_green {
  color: #5AC396;
}
.modal_button {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.modal_button_margin {
  margin-right: 10px;
}
.modal_header{
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-70);
}
.modal_text_small {
  font-size: 12px;
}
</style>
