<template>
  <div class="panel">
    <div class="top-panel__logo">
      <div class="current-page">
        <!-- {{ $t(title) }} -->
      </div>
      <div class="logo">
        <a
          href="#"
          @click="$router.push(path)"
        >
          <AuIcon :icon="$route.meta.logo" />
        </a>
        <span
          v-if="showTestnet"
          class="testnet-mobile"
          :dark="!isLightTheme"
        >
          Testnet
        </span>
      </div>

      <ThemeSwitcher class="theme-switcher" />
    </div>

    <div class="profile-item">
      <Profile />
    </div>
  </div>
</template>


<script>
import _ from "lodash";

import Profile from "./components/Profile.vue";
import ThemeSwitcher from "./components/ThemeSwitcher";
import { mapGetters } from "vuex";

export default {
  components: {
    Profile,
    ThemeSwitcher
  },

  data() {
    return {
      showMenu: false
    };
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),

    allSections() {
      return [
        {
          title: this.$t("router.wallet"),
          name: "wallet",
          route: "/wallet"
        },
        {
          title: this.$t("router.capital"),
          name: "autentic capital",
          route: "/autentic-capital"
        },
        {
          title: this.$t("router.market"),
          name: "autentic market",
          route: "/autentic-market"
        }
      ];
    },

    path() {
      let parent = _.find(this.$route.matched, match => match.meta.isMain);
      return _.get(parent, "path");
    },

    showTestnet() {
      return process.env.VUE_APP_SHOW_TESTNET == 1;
    }
  },

  methods: {
    isCurrent(path) {
      let parent = _.find(this.$route.matched, match => match.meta.isMain);
      return parent.name == path;
    }
  }
};
</script>

<style scoped lang="scss">
.panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 25px;
  height: 67px;

  border-bottom: 1px solid var(--color-90);

  @media screen and (max-width:640px) {
    background: var(--card-color);
    height: 69px;

    border-color: var(--color-70);
  }
}

.current-page {
  display: block;
  font-size: 16px;

  @media screen and (max-width:1024px) {
    display: none;
  }
}

.logo {
  display: none;

  @media screen and (max-width:1024px) {
    display: flex;
    align-items: end;
    gap: 5px;
  }
}

.theme-switcher {
  display: none !important;

  @media screen and (max-width:640px) {
    display: flex !important;
  }
}

.top-panel__logo {
  display: flex;
  gap: 10px;
  font-weight: bold;
  font-size: 16px;
  white-space: nowrap;
}

.section {
  font-size: 14px;
  text-decoration: none;
  color: var(--text-color);
  white-space: nowrap;

  &:hover {
    color: var(--color-60);
  }
}

.sections {
  display: flex;
  flex-direction: row;
  gap: 50px;

  @media screen and (max-width:1024px) {
    display: none;
  }

  &-mobile {
    display: none;
    position: relative;

    @media screen and (max-width:1024px) {
      display: block;
    }

    @media screen and (max-width:640px) {
      display: none;
    }
  }

  &-selector {
    margin-left: 10px;
  }

  &-menu {
    position: fixed;
    top: 60px;
    z-index: 100;
    background-color: var(--menu-color);
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 17px;
  }
}

.router-link-active {
  color: #279063;
}
.testnet-mobile {
  font-size: 12px;
  color: #00000066;
  &[dark=true] {
    color: #FFFFFF80;
  }
}
</style>
