<template>
  <div
    :style="cssVars"
    class="card exchange-rate"
    @click="showTokenInfo"
  >
    <div class="exchange-rate__item chard-item">
      <div class="exchange-rate__left">
        <div
          v-if="name ==='Товары' || name ==='Криптоактивы' || info !== null"
          class="rate-icon-wrap"
        >
          <AuIcon
            class="rate-icon"
            :icon="icon"
          />
        </div>

        <img
          v-else
          class="img-icon"
          :src="icon"
          alt=""
        >

        <div class="rate-name">
          {{ name }}
        </div>
      </div>

      <div
        v-if="chart"
        class="rate-chard"
      >
        <Apexchart
          :options="options"
          :series="series"
        />

        <div
          v-if="chart"
          class="growth-percent"
        >
          <AuIcon :icon="percent > 0 ? 'rate_up_green' : 'rate_down_red'" />
          <div
            v-if="!isNaN(percent) && percent !== Infinity"
            class="fs12"
          >
            {{ Math.abs(percent) }}%
          </div>
        </div>
      </div>
    </div>

    <div class="exchange-rate__item">
      <div class="rate">
        <AuDecimalStatic
          v-if="!info"
          :value="rateValue"
          :addition="mnemonic"
          :decimal="decimals"
          :format-value="false"
        />
        <span v-else>{{ info }}</span>
        <div class="min-text">
          <span v-if="valueDefault.length ===0">&nbsp;</span>
          <div v-else>
            <AuDecimalStatic
              v-if="isFinite(valueDefault)"
              :value="valueDefault"
              :addition="mnemonicDefault"
              :indent="decimalDefault"
              :decimal="decimalDefault"
            />
            <span v-else>{{ valueDefault }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    name: {
      type: String,
      required: true
    },
    info: {
      type: String,
      default: null
    },
    chart: {
      type: Boolean,
      default: true
    },
    values: {
      type: Array,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    decimals: {
      type: Number,
      default: 0
    },
    mnemonic: {
      type: String,
      default: ""
    },
    valueDefault: {
      type: String,
      default: ""
    },
    decimalDefault: {
      type: Number,
      default: 2
    },
    mnemonicDefault: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      series: [{
        name: "Изменение показателя",
        data: this.values
      }]
    };
  },

  computed: {
    rateValue() {
      return this.values[3].toLocaleString("ru");
    },

    percent() {
      return Number(((this.values[this.values.length - 1] * 100 / this.values[this.values.length - 2]) - 100).toFixed(1));
    },

    currentColor() {
      if (this.percent > 0) {
        return "#5AC396";
      }

      return "#E94545";
    },

    cssVars() {
      return {
        "--color": this.currentColor
      };
    },

    options() {
      return {
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        stroke: {
          width: 3,
          curve: "smooth"
        },
        chart: {
          type: "line",
          sparkline: {
            enabled: true
          },
          labels: {
            show: false
          }
        },
        title: {
          show: false
        },
        yaxis: {
          min: _.min(this.values) - _.sum(this.values) / 100,
          max: _.max(this.values) + _.sum(this.values) / 100,
          labels: {
            show: false
          }
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            colorStops: [
              [
                {
                  offset: 0,
                  color: this.currentColor,
                  opacity: 0
                },
                {
                  offset: 50,
                  color: this.currentColor,
                  opacity: 1
                },
                {
                  offset: 100,
                  color: this.currentColor,
                  opacity: 0
                }
              ]
            ]
          }
        }
      };
    }
  },

  methods: {
    showTokenInfo() {
      this.$router.push({ name: this.link, params: { id: this.id } });
    }
  }
};
</script>

<style scoped lang="scss">
  .rate-icon {
    &-wrap {
      width: 26px;
      height: 26px;

      background: var(--color-00);
      border-radius: 7px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .growth-percent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 5px;

    i, div {
      color: var(--color);
    }
  }

  .chard-item {
    align-items: flex-end;
  }

  .rate-chard {
    width: 50px;
  }

  .rate-name {
    margin-top: 15px;
    color: var(--color-50);
    font-weight: 500;
    font-size: 12px;
  }

  .rate {
    font-weight: 500;
    font-size: 15px;
    color: var(--color-20);
  }

  .exchange-rate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 15px;
    cursor: pointer;

    height: 133px;
    width: 25%;

    flex: 1 1 auto;

    &__item {
      width: 100%;
      display: flex;
      flex-direction: initial;
      justify-content: space-between;
    }

    &__left {
      display: flex;
      flex-direction: column;
    }

    @media screen and (max-width: 640px) and (min-width: 450px) {
      width: 100%;
      flex: 1 1 calc((100% / 3) - 20px);

      &:last-child {
        flex: 0 1 31%;
      }
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  .img-icon {
    width: 25px;
    object-fit: cover;
  }
  .min-text{
    font-size: 11px;
    color: var(--color-60);
  }
  </style>
