<template>
  <div
    v-if="isPanelsDontNeed"
    class="wrapper"
    :style="cssVars"
  >
    <RouterView />
    <ChatPanel
      v-if="isChatVisible"
      :name="$t('chat.title')"
      :visible="isChatVisible"
      @close="setChatVisible(false)"
    />
  </div>

  <GlobalLoader v-else-if="loading" />

  <div
    v-else
    :style="cssVars"
    class="app"
  >
    <div class="app__left">
      <LeftPanel />
    </div>

    <div class="app__right">
      <TopPanel />

      <RouterView />
      <ChatPanel
        v-if="isChatVisible"
        :name="$t('chat.title')"
        :visible="isChatVisible"
        @close="setChatVisible(false)"
      />

      <BottomPanel />
    </div>
  </div>
</template>

<script>
import BottomPanel from "@/panels/BottomPanel.vue";
import ChatPanel from "@/components/ChatPanel";
import GlobalLoader from "@/components/GlobalLoader.vue";
import LeftPanel from "@/panels/LeftPanel.vue";
import localStorageController from "@/help/localStorageController";
import TopPanel from "@/panels/TopPanel.vue";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",

  components: {
    ChatPanel,
    LeftPanel,
    TopPanel,
    BottomPanel,
    GlobalLoader
  },

  data() {
    return {
      loading: true,
      chatMenu: false
    };
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme",
      isChatVisible: "isChatVisible",
      tokens: "tokens",
      tokensGold: "tokensGold",
      defaultTokenId: "defaultToken/defaultTokenId"
    }),

    isPanelsDontNeed() {
      return (
        !this.$auth.user() ||
          this.$route?.name === "welcome" ||
          this.$route?.name === "logout" ||
          this.$route?.name === "login"
      );
    },

    user() {
      return this.$auth.user();
    },

    cssVars() {
      return {
        "--main-color": this.isLightTheme ? "#F2F1F7" : "#121212",
        "--menu-color": this.isLightTheme ? "#DFDFDF" : "#404040",
        "--card-color": this.isLightTheme ? "#FFFFFF" : "#1C1C1E",
        "--text-color": this.isLightTheme ? "#080808" : "#FFFFFF",

        "--scrollbar-color": this.isLightTheme ? "#b0b7c3" : "#393939",
        color: this.isLightTheme ? "#080808" : "#FFFFFF",

        "--color-00": this.isLightTheme ? "#080808" : "#FFFFFF",
        "--color-10": "#E6E6E6",
        "--color-20": this.isLightTheme ? "#080808CC" : "#CDCDCD",
        "--color-30": "#B4B4B4",
        "--color-40": this.isLightTheme ? "#080808CC" : "#9B9B9B",
        "--color-50": this.isLightTheme ? "#08080866" : "#828282",
        "--color-60": this.isLightTheme ? "#08080899" : "#696969",
        "--color-70": this.isLightTheme ? "#0808081A" : "#505050",
        "--color-80": this.isLightTheme ? "#0808084D" : "#373737",
        "--color-90": this.isLightTheme ? "#e3e2e8" : "#1E1E1E",
        "--color-95": this.isLightTheme ? "#F2F1F7" : "#121212",
        "--color-100": "#050505",

        "--sep-line-color": this.isLightTheme ? "#F9F9F9" : "#151515"
      };
    }
  },

  watch: {
    user: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue) {
          this.setDefaultToken({
            id: newValue.defaultTokenId || process.env.VUE_APP_TOKEN_DEFAULT_ID,
            mnemonic: newValue.defaultTokenMnemonic || process.env.VUE_APP_TOKEN_DEFAULT
          });

          if (newValue?.id !== oldValue?.id) {
            await this.getAuTokens();
            await this.getCurrencyRate();
            await this.getBalances(this.defaultTokenId);
            await this.getAuOwners();
            await this.getEthAddresses();
            await this.getExchangeBalances(newValue?.id);

            if (process.env.VUE_APP_USE_WEBSOCKET !== "false") {
              // this.sendNotify(this.$auth.token());
            }

            this.loading = false;
          }
        }
      }
    }
  },

  async created() {
    let isLightTheme = localStorageController.getIsLightTheme();
    this.$store.dispatch("setIsLightTheme", !(isLightTheme === "false"));
    this.initWeb3Modal();
  },

  methods: {
    ...mapActions({
      getAuTokens: "getAuTokens",
      getAuOwners: "getAuOwners",
      getCurrencyRate: "getCurrencyRate",
      getBalances: "getBalances",
      getEthAddresses: "getEthAddresses",
      setDefaultToken: "defaultToken/setDefaultToken",
      getExchangeBalances: "getExchangeBalances",
      setChatVisible: "setChatVisible",
      sendNotify: "sendNotify"
    }),

    initWeb3Modal() {
      const projectId = "8a93afa8fa43d9dcf5b589ac25a82eca";

      const ethereum = {
        chainId: 1,
        name: "Ethereum Mainnet",
        currency: "ETH",
        rpcUrl: "https://cloudflare-eth.com",
        explorerUrl: "https://etherscan.io"
      };

      const avax = {
        chainId: 43114,
        name: "Avalanche C-Chain",
        currency: "AVAX",
        rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
        explorerUrl: "https://snowtrace.io"
      };

      const bsc = {
        chainId: 56,
        name: "BNB Smart Chain Mainnet",
        currency: "BNB",
        rpcUrl: "https://bsc-dataseed.binance.org/",
        explorerUrl: "https://bscscan.com/"
      };

      const metadata = {
        name: "Autentic",
        description: "Autentic",
        url: "https://aut.finance/",
        icons: ["https://aut.finance/logo.png"]
      };

      createWeb3Modal({
        ethersConfig: defaultConfig({ metadata }),
        chains: [bsc, avax, ethereum],
        projectId,
        enableAnalytics: true,
        featuredWalletIds: window?.ethereum?.isMetaMask ?
          [] : ["c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96"]
      });
    }
  }
};
</script>

<style lang="scss">
.app {
  display: flex;
  width: 100%;
  overflow: hidden;
  background: var(--main-color);

  &__right {
    width: 100%;
    overflow: hidden;
  }

  &__left {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}
.wrapper {
  width: 100%;
}
</style>
