import axios from "axios";

export default class axiosClient {

  async getUploadedFiles(params) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const resp = await axios.get("api/uploaded_files", {
      headers: headers,
      params: params
    });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async getUploadedFile(id) {
    const resp = await axios.get(`api/uploaded_files/${id}`);
    return resp.data;
  }

  async uploadFile(file) {
    let param = new FormData();
    param.append("uploadedFile", file);
    const headers = {
      accept: "application/json",
      "Content-Type": "multipart/form-data"
    };
    const resp = await axios.post("api/uploaded_files/post_mp", param, {
      headers: headers
    });
    return resp.data;
  }

  async deleteFile(id) {
    const resp = await axios.delete(`api/uploaded_files/${id}`);
    return resp.data;
  }
}