<template>
  <MyPagination
    v-model="page"
    :range-size="1"
    :pages="pages"
    hide-first-button
    hide-last-button
    active-color=""
    class="MyPagination"
    @update:modelValue="updateHandler"
  />
</template>

<script>
// API Pagination
// Props -- Type -- Default -- Description
// pages -- Number -- none -- Total number of pages
// rangeSize -- Number -- 1-- Number of page around the current page
// activeColor -- String -- #DCEDFF -- Background color of the current page
// hideFirstButton -- Boolean -- false -- Hide the button to go to the first page
// hideLastButton -- Boolean -- false -- Hide the button to go to the last page

// itemsPerPage - количество данных на страницу. по умолчанию 30
// itemsPerPage - так же указано в функциях axiosControllers.js
// totalItems - всего страниц. высчитывается математически
// totalItems - зависит от itemsPerPage
// totalItems - если > 1, то пагинация отображается

// Логика замены на сайте есть на TestPage.vue
// 1. Вставляем компонент (он глобальный), передаем и принимаем данные AuPagination
// 2. Добавляем метод pageChanged
// 3. В основной функции запроса данных меняем получение resp.data и resp.totalItems
// 4. идет запрос в store/index.js - ничего не меняем
// 5. идет запрос в api/axiosController.js - добавляем заголовки, ["hydra:member"] и т.д.
// 6. по идее все. Проверяем

import MyPagination from "@/components/pagination/MyPagination.vue";

export default {
  name: "AuPagination",
  components: {
    MyPagination
  },

  props: {
    totalItems: {
      type: Number,
      default: 1
    },
    index: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 30
    };
  },

  computed: {
    pages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    }
  },

  methods: {
    updateHandler(actualPage) {
      if (this.index !== -1) {
        this.$emit("pageChangedIndex", { actualPage: actualPage, index: this.index });
      }
      else {
        this.$emit("pageChanged", actualPage);
      }
    }
  }
};
</script>

