<template>
  <div>
    <AuBreadcrumb
      parent-name="Маркетплейс"
      parent-path="/autentic-market/marketplace"
    />
    <div class="mt-title mb-5">
      {{ nft?.productName }}
    </div>
    <div class="d-flex">
      <div class="col-6">
        <img
          class="mt-image"
          :src=" nft?.image"
          alt=""
        >
      </div>
      <div class="col-4 ms-4">
        <div class="fs15">
          Описание
        </div>
        <div class="mt-info">
          {{ nft?.description }}
        </div>
        <div class="fs15 mt-2">
          Информация
        </div>
        <div>
          <div class="d-flex justify-content-between">
            <span class="mt-info">Компания</span>
            <span class="fs13">{{ nft?.company?.nameEng }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mt-info">Тип товара</span>
            <span class="fs13">{{ nft?.section?.name }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mt-info">Размещен</span>
            <span class="fs13">{{ nft?.displayTime }}</span>
          </div>
        </div>
        <div class="fs15 mt-2">
          Текущая стоимость полного выкупа
        </div>
        <div class="mt-price">
          <AuDecimalStatic
            v-if="nft?.fullPrice"
            :value="nft?.fullPrice"
            :decimal="decimals"
          />{{ nft?.tokenQuote?.mnemonicEng }}
        </div>
        <div class="fs15 mt-2">
          Статус погашения
        </div>
        <div>
          <div class="d-flex justify-content-between">
            <span class="mt-info">Дата погашения</span>
            <span class="fs13">-</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mt-info">Заявка на погашение подана</span>
            <span class="fs13">-</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="mt-info">Заявка может быть подана после</span>
            <span class="fs13">-</span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-4">
      <div class="col-6">
        <div class="fs15 mb-2">
          Файлы и документы
        </div>
        <div class="mt-file-container">
          <a
            v-for="(file, index) in fileList"
            :key="index"
            class="mt-file"
            :href="file.link"
          >
            {{ file.name }}
          </a>
        </div>
      </div>
      <div class="col-4 ms-4">
        <div class="fs15 mb-2">
          Статус дробления актива
        </div>
        <div class="d-flex justify-content-between">
          <span class="mt-info">Всего частей</span>
          <span class="fs13">{{ nft?.amount }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="mt-info">В продаже</span>
          <span class="fs13">{{ nft?.amount }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="mt-info">На сумму</span>
          <span class="fs13">
            <AuDecimalStatic
              v-if="nft?.fullPrice"
              :value="nft?.fullPrice"
              :decimal="decimals"
            /></span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="mt-info">Средневзвешенная цена части</span>
          <span class="fs13">
            <AuDecimalStatic
              v-if="average"
              :value="average"
              :decimal="decimals"
            /></span>
        </div>
      </div>
    </div>
    <div class="d-flex mt-4">
      <div class="col-6" />
      <div class="col-4 ms-4">
        <div class="mt-buy">
          <AuInput
            v-model="count"
            type="Number"
          />
          <AuButton
            bordered
            border-radius="10"
            center
            @click="count=nft?.amount"
          >
            Все
          </AuButton>
          <AuButton
            bordered
            border-radius="10"
            center
            active-bg-color="#279063"
            @click="showDialog=true"
          >
            Купить
          </AuButton>
        </div>
        <div class="mt-2">
          <div
            v-for="(owner,index) in nftOwners"
            :key="index"
            class="mt-owners"
          >
            <div class="mt-info col-3">
              {{ owner?.address.value.slice(0,10) }}...
            </div>
            <div class="mt-info col-3">
              {{ owner?.amount }} шт.
            </div>
            <div class="mt-info col-3">
              <AuDecimalStatic
                v-if="owner.marketPrice"
                :value="owner.marketPrice"
                :decimal="decimals"
              >
                {{ nft?.tokenQuote.mnemonicEng }}
              </AuDecimalStatic>
              <AuDecimalStatic
                v-else
                :value="0"
                :decimal="decimals"
              />{{ nft?.tokenQuote.mnemonicEng }}
            </div>
            <div class="mt-info col-3">
              <AuDecimalStatic
                v-if="owner?.total"
                :value="owner?.total"
                :decimal="decimals"
              />{{ nft?.tokenQuote.mnemonicEng }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <AuModal
      v-model="showDialog"
      width="400px"
    >
      <template #header>
        <div class="modal_header">
          Покупка продукта
        </div>
      </template>
      <template #body>
        <div class="modal_text_small">
          Вы собираетесь приобрести продукт: {{ nft?.productName }}
        </div>
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80"
              @click="buyNft"
            >
              Купить
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80"
              @click="showDialog=false"
            >
              Отмена
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MultitokenCard",
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      nft: null,
      nftOwners: null,
      count: null,
      average: "",
      showDialog: false,
      fileList: []
    };
  },
  computed: {
    ...mapGetters({
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold"
    }),

    decimals() {
      if (_.isNil(this.nftOwners[0]?.marketTokenQuote)) {
        return 0;
      }
      const token = _.find([...this.tokensGold, ...this.tokensFiat], token => this.nftOwners[0].marketTokenQuote == `/api/au_tokens/${token.id}`);
      if (_.isUndefined(token)) {
        return 0;
      }
      return token.decimals;
    }
  },
  async mounted() {
    this.nft = await this.getNftById(this.id);
    if (!this.nft?.isMultiToken) {
      this.$router.replace({ name: "marketplace card", params: { id: this.id } });
    }
    this.nftOwners = await this.getNftOwnersById({ id: this.id, isInMarket: true });
    this.average = BigNumber(0);
    let ownerCount = BigNumber(0);
    _.forEach(this.nftOwners, owner => {
      owner.total = BigNumber(_.get(owner, "amount", 0)).multipliedBy(_.get(owner, "marketPrice", 0)).toString(10);
      this.average = this.average.plus(owner.total);
      ownerCount = ownerCount.plus(_.get(owner, "amount", 0));
    });
    if (!ownerCount.isEqualTo(0)) {
      this.average = this.average.dividedBy(ownerCount).toFixed(2);
    }
    else {
      this.average = this.average.toFixed(2);
    }
    this.nft.displayTime = moment(this.nft.createdAt).format("DD-MM-YYYY HH:mm:ss");
    this.nft.fullPrice = BigNumber(this.nft.amount).multipliedBy(this.nft.price).toFixed(2);
    await this.showFiles();

    this.nftOwners.sort((a, b) => a?.marketPrice - b?.marketPrice);
  },
  methods: {
    ...mapActions({
      getNftById: "marketplace/getNftById",
      sendTokenEvents: "sendTokenEvents",
      getNftOwnersById: "marketplace/getNftOwnersById",
      getUploadedFile: "files/getUploadedFile"
    }),

    showSuccess(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    async buyNft() {
      this.showDialog = false;
      const params = {
        user: `api/users/${this.$auth.user().id}`,
        createdAt: moment().format("DD.MM.YYYY"),
        type: "token_nft_buy",
        description: "buy",
        value: this.count,
        address: "",
        isTask: true,
        nftNumber: this.nft.id,
        tokenQuote: `/api/au_tokens/${this.nft.tokenQuote.id}`
      };

      try {
        await this.sendTokenEvents(params);
        this.showSuccess("Распоряжение на покупку NFT товара отправлено!");
      }
      catch (e) {
        this.showError("Ошибка при совершении покупки!");
      }
    },

    async showFiles() {
      const additional = await this.getFileList(this.nft?.additionalFile);
      const video = await this.getFileList(this.nft?.video);
      this.fileList = [...additional, ...video];
    },

    async getFileList(fileList) {
      let ret = [];
      if (_.isNil(fileList)) {
        return ret;
      }
      for (const file of fileList) {
        const id = file.slice(file.lastIndexOf("/") + 1);
        const resp = await this.getUploadedFile(id);
        ret.push({ name: resp?.nameOriginal, link: resp?.name });
      }
      return ret;
    }
  }
};
</script>

<style scoped lang="scss">
.mt-title{
  font-weight: 600;
  font-size: 24px;
}
.mt-image{
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}
.mt-info{
  font-size: 13px;
  color: var(--color-60)
}
.mt-price{
  font-size: 13px;
  color: #28E593;
}
.mt-file{
  display: flex;
  align-items: center;
  border: 1px solid var(--menu-color);
  border-radius: 10px;
  background-color: var(--card-color);
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  color: var(--color-60);
}
.mt-file-container{
  display: flex;
  flex-wrap: wrap;
  overflow-x: scroll;
}
.mt-buy{
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.mt-owners{
  display: flex;
  border: 1px solid var(--menu-color);
  background-color: var(--card-color);
  border-radius: 10px;
  padding: 10px;
  overflow-y: scroll;
}
.modal_button {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.modal_button_margin {
  margin-right: 10px;
}
.modal_header{
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-70);
}
.modal_text_small {
  font-size: 12px;
}
</style>