<template>
  <div class="emitent">
    <EmitentRates />
    <ExchangeRatesBottom />
    <Heading />
    <TransactionsIssuer />
  </div>
</template>

<script>
import EmitentRates from "@/components/exchangeRates/top/EmitentRates";
import ExchangeRatesBottom from "../components/exchangeRates/bottom/ExchangeRates.vue";
import Heading from "@/components/Heading";
import TransactionsIssuer from "../components/lastTransactions/TransactionIssuer.vue";
import { mapActions } from "vuex";
export default {
  name: "DashboardEmitent",
  components: {
    Heading,
    EmitentRates,
    ExchangeRatesBottom,
    TransactionsIssuer
  },
  async mounted() {
    await this.getNfts({ page: 1 });
  },
  methods: {
    ...mapActions({
      getNfts: "goods/getCurrentUserNfts"
    })
  }
};
</script>

<style scoped>
.emitent{
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between
}
</style>