import StateControllerBase from "@/store/stateControllerBase";
import { setLoadingState } from "@/store/setLoadingState";

import CapitalController from "@/api/capitalController";
const capitalController = new CapitalController();

class Actions extends StateControllerBase {
  setDataLoading({ commit }, dataLoading) {
    commit("setDataLoading", dataLoading);
  }

  @setLoadingState
  async getAllAuCapitals({ commit }, pageId) {
    let data = await capitalController.getAllAuCapitals({ pageId });
    commit("setAuCapitals", data);
  }

  @setLoadingState
  async getAllAuCapitalsPagination(_context, params) {
    return await capitalController.getAllAuCapitals(params);
  }

  @setLoadingState
  async getSearchedAuCapitals({ commit }, search) {
    let data = await capitalController.getSearchedAuCapitals(search);
    commit("setSearchedAuCapitals", data);
  }

  @setLoadingState
  async getAuCapitals(_context, id) {
    return await capitalController.getAuCapitals(id);
  }

  @setLoadingState
  async getAuCapitalsBySectorId(_context, sectionId) {
    return await capitalController.getAuCapitalsBySectorId(sectionId);
  }

  @setLoadingState
  async getCapitalInvestor({ commit }, params) {
    const resp = await capitalController.getCapitalInvestor(params);
    commit("setInvestors", resp.data);
    commit("setInvestorsTotal", resp.totalItems);
  }
}
export default (new Actions).asPlainObject();