<template>
  <button
    type="button"
    class="btn"
    :style="cssVars"
    @click="$emit('click')"
  >
    <div
      v-if="$slots['default']"
      class="btn__text"
    >
      <slot />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: ""
    },
    fontSize: {
      type: Number,
      default: 12
    },
    borderRadius: {
      type: [Number, String],
      default: 40
    },
    activeBgColor: {
      type: String,
      default: ""
    },
    hoveredBgColor: {
      type: String,
      default: "rgba(39, 144, 99, 0.04)"
    }
  },

  emits: ["click"],

  data() {
    return {
      basicWidth: "auto",
      basicHeight: "44px"
    };
  },

  computed: {
    cssVars() {
      return {
        "--width": this.currentWidth,
        "--height": this.currentHeight,
        "--border-radius": this.borderRadius + "px",
        "--border-color": "#CDCDCD",
        "--background-hovered-color": this.hoveredBgColor,
        "--font-size": this.fontSize + "px"
      };
    },

    currentWidth() {
      if (this.width) {
        return this.width;
      }

      return this.basicWidth;
    },

    currentHeight() {
      if (this.height) {
        return this.height;
      }

      return this.basicHeight;
    }
  }
};
</script>

<style scoped lang="scss">
button.btn {
  border-radius: var(--border-radius);
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--color-70);

  width: var(--width);
  height: var(--height);

  .btn__text {
    color: var(--text-color);
    font-size: var(--font-size);
  }

  &[active=true] {
    border-color: var(--border-color);
  }

  &:hover {
    background-color: var(--background-hovered-color);
    transition: background-color 0.5s ease;
  }
}
</style>
