<template>
  <a
    v-if="isLink"
    :href="href"
    :target="target"
    @click.stop="$emit('click')"
  >
    <img
      :src="`/svg/${theme}/${icon}.svg`"
      :alt="icon"
    >
  </a>

  <img
    v-else
    class="icon"
    :clickable="clickable"
    :src="`/svg/${theme}/${icon}.svg`"
    :alt="icon"
    @click.stop="$emit('click')"
  >
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    isLink: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: "#"
    },
    target: {
      type: String,
      default: "_self"
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },

  emits: ["click"],

  computed: {
    theme() {
      return this.$store.getters.isLightTheme ? "light" : "dark";
    }
  }
};
</script>

<style scoped lang="scss">
  a.btn {
    &:active{
      border-color: transparent;
    }
  }
  img {
    &[as-button] {
      padding: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }

.icon[clickable=true] {
  cursor: pointer;
}
</style>
