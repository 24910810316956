<template>
  <div class="exchange-rates">
    <ExchangeRate
      v-for="(rate, index) in rates"
      :id="rate.id"
      :key="index"
      :name="rate.name"
      :values="rate.values"
      :icon="rate.icon"
      :color="rate.color"
      :link="rate.link"
      :decimals="rate.decimals"
      :mnemonic="rate.mnemonic"
      :value-default="rate.valueDefault"
      :decimal-default="rate.decimalDefault"
      :mnemonic-default="rate.mnemonicDefault"
      :info="rate.info"
    />
  </div>
</template>

<script>
import CurrencySupport from "@/help/currencySupport";
import ExchangeRate from "./ExchangeRate";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ExchangeRates",

  components: {
    ExchangeRate
  },

  data() {
    return {
      rates: [],
      currencySup: new CurrencySupport(),
      page: 1,
      totalItems: 0
    };
  },

  computed: {
    ...mapGetters({
      tokensUtility: "tokensUtility",
      tokensDao: "tokensDao",
      tokens: "tokens",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic"
    }),


    defaultDecimal() {
      return this.tokens.find(token => token.mnemonicEng == this.defaultTokenMnemonic)?.decimals;
    }
  },

  async mounted() {
    await this.getNftsPage();
    [this.tokensUtility, this.tokensDao].forEach(tokenArray => {
      tokenArray.forEach(item => {
        const course = this.currencySup.getBalance(item.id);
        this.rates.push({
          id: item.id,
          name: item.nameEng,
          values: [course / (Math.random() * 1 + 1), course / (Math.random() * 1 + 1), course / (Math.random() * 1 + 1), course],
          icon: item.logoMain,
          color: "289566",
          link: item.type === "dao" ? "dao tokens" : "token info",
          decimals: item.decimals,
          mnemonic: item.mnemonicEng,
          valueDefault: this.currencySup.getAmount(item.id),
          decimalDefault: this.defaultDecimal,
          mnemonicDefault: this.defaultTokenMnemonic
        });
      });
      if (tokenArray === this.tokensUtility) {
        this.rates.push({ name: this.$t("dashboard.rates.products.name"), valueDefault: this.$t("dashboard.rates.products.quantity"), info: this.totalItems + " шт.", values: [200405, 300405, 422020, 0], icon: "products_icon", color: "#9C2AF6", link: "invest goods" });
        this.rates.push({ name: this.$t("dashboard.rates.cryptoassets.name"), valueDefault: this.$t("dashboard.rates.cryptoassets.quantity"), values: [792494, 882494, 857194, 0], icon: "tokens_icon", color: "#E2A64D", link: "briefcase" });
      }
    });
  },

  methods: {
    ...mapActions({
      getNfts: "goods/getCurrentUserNfts"
    }),

    async getNftsPage() {
      const resp = await this.getNfts({
        page: this.page
      });
      this.totalItems = resp.totalItems;
    }
  }
};
</script>

<style scoped lang="scss">
.exchange-rates {
  display: flex;
  justify-content: start;
  gap: 20px;

  @media screen and (max-width: 640px)  {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 450px)  {
    flex-direction: column;
  }
}
</style>