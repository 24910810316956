<template>
  <div class="main-panel">
    <hr>
    <div
      class="alert alert-success"
      role="alert"
    >
      {{ $t("validate.verifyAdr") + " " + currentEth }}
    </div>
    <div>
      <div
        v-if="!mmInstalled"
        class="wallet-info"
      >
        <div class="my-3">
          <div
            class="alert alert-warning"
            role="alert"
          >
            {{ $t("validate.cryptoWalletNotInstall") }}
          </div>
        </div>
        <a
          href="https://metamask.io/download"
          target="_blank"
        >
          <span><button
            type="button"
            class="btn btn-warning"
          >
            {{ $t("validate.installCryptoWallet") }}
          </button></span>
        </a>
      </div>

      <div
        v-if="mmInstalled && accountAddress === null"
        class="wallet-info"
      >
        <div class="my-3">
          <div
            class="alert alert-warning"
            role="alert"
          >
            {{ $t("validate.cryptoWalletNotAuth") }}
          </div>
        </div>
        <button
          type="button"
          class="btn btn-warning"
          @click="connectMetamaskAction"
        >
          {{ $t("validate.cryptoWalletConnect") }}
        </button>
      </div>

      <div
        v-if="mmInstalled && accountAddress !== null"
        class="wallet-info"
      >
        <div class="my-3">
          <div
            class="alert alert-primary"
            role="alert"
          >
            {{ $t("validate.metamaskConnect") + " " + accountAddress }}
          </div>
        </div>
      </div>

      <div v-if="accountAddress !== null && accountAddress !== currentEth">
        <div class="my-3">
          <div
            class="alert alert-warning"
            role="alert"
          >
            {{ $t("validate.adrNotVerify") }}
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="
        accountAddress !== null &&
          accountAddress === currentEth &&
          responceValidate === false
      "
      type="button"
      class="btn btn-warning"
      @click="validateAddress"
    >
      {{ $t("validate.adrVerify") }}
    </button>

    <div
      v-if="responceValidate"
      class="my-3"
    >
      <div
        class="alert alert-success"
        role="alert"
      >
        {{ $t("validate.address") + " " + accountAddress + "-" + $t("validate.verify") }}
      </div>
    </div>
  </div>
</template>

<script>
import MetaMask from "@/mixins/MetaMask";

import { mapActions } from "vuex";

export default {
  name: "ValidateAddress",
  mixins: [MetaMask],
  props: {
    currentEth: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialogError: false,
      dialogSucces: false,
      responceValidate: false,
      address: null,
      data: null
    };
  },
  methods: {
    ...mapActions({
      getValidateContent: "getValidateContent",
      signAndVerify: "signAndVerify"
    }),

    async validateAddress() {
      try {
        const currentEth = this.currentEth.toLowerCase();

        this.data = await this.getValidateContent(currentEth);
        const dataToSign = this.data.content;

        const sign = await this.web3.eth.personal.sign(
          dataToSign,
          this.accountAddress,
          ""
        );

        let data = await this.signAndVerify({ accountAddress: this.accountAddress, sign: sign });

        if (data.result) {
          this.responceValidate = true;
          this.$toast.success(this.$t("validate.success"), {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
          this.$emit("success");
        }
        else {
          console.log("Error", data);
          this.$toast.error(this.$t("validate.error"), {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
        }
      }
      catch (error) {
        console.log("Error", error);
        this.$toast.error(this.$t("validate.error"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.main-panel {
  margin-top: 15px;
}
</style>
