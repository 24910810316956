<template>
  <div>
    <div class="fs-stage">
      <div class="fs-block">
        <div class="fs-title">
          {{ $t("buyForm.first.count") }}
        </div>
        <div class="fs-body-left">
          <AuInput
            v-model="count"
            type="Number"
          />
          <span>USD.s</span>
        </div>
      </div>
      <div class="fs-block">
        <div class="fs-title">
          {{ $t("buyForm.first.toPay") }}
        </div>
        <div class="fs-body-right">
          <div class="fs-body-sum">
            {{ sum }} USDT
          </div>
          <div class="fs-body-minmax">
            <span>
              Min: 20 USDT
            </span>
            <span>
              Max: 20000 USDT
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="fs-error">
      {{ error }}
    </div>
    <div class="fs-next">
      <AuButton
        center
        bordered
        border-radius="10"
        width="40%"
        active-bg-color="#279063"
        :disabled="error!==''"
        @click="next"
      >
        {{ $t("buyForm.first.next") }}
      </AuButton>
    </div>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";

export default {
  name: "FirstStage",
  props: {
    price: {
      type: Number,
      default: 0.08
    }
  },
  data() {
    return {
      count: "1000"
    };
  },
  computed: {
    sum() {
      return BigNumber(this.count === "" ? 0 : this.count)
        .multipliedBy(this.price).toFixed(2);
    },

    error() {
      let ret = "";
      ret = this.sum < 20 ? this.$t("buyForm.first.min") : ret;
      ret = this.sum >= 20000 ? this.$t("buyForm.first.max") : ret;
      return ret;
    }
  },
  methods: {
    next() {
      this.$emit("next", this.count);
    }
  }
};
</script>

<style scoped lang="scss">
.fs-stage {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}
.fs-block {
  border: 1px solid var(--scrollbar-color);
  border-radius: 15px;
  width: 48%;
}
.fs-title {
  display: flex;
  justify-content: center;
  padding: 20px;
  border-bottom: 1px solid var(--menu-color);
}
.fs-body-left {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  padding: 0 20px;
}
.fs-body-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  height: 120px;
}
.fs-body-sum {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
}
.fs-body-minmax {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.fs-error {
  text-align: center;
  font-size: 12px;
  height: 30px;
  color: red;
}
.fs-next {
  display: flex;
  justify-content: center;
}
</style>