<template>
  <div>
    <div class="p_card">
      <div :style="style">
        <div
          v-if="project.status"
          class="time"
        >
          <AuIcon :icon="project.statusIcon" /> {{ project.status }}
        </div>
        <div class="tag">
          {{ project.tag }}
        </div>
      </div>

      <img
        class="image"
        :src="project.icon"
        alt="ico"
      >

      <div class="name">
        {{ project.name }}
      </div>

      <div
        class="description"
        v-html="description"
      />

      <div class="price">
        <AuDecimalStatic
          :value="project.priceBase"
          :addition="project.mnemonicEng"
        />
      </div>

      <div class="line-wrap">
        <div class="line">
          <div class="left_text">
            {{ $t("auCapitals.capitalCard.date") }}&nbsp;
            {{ project.dateClose === "~" ?
              $t("auCapitals.capitalCard.completion") : $t("auCapitals.capitalCard.repayment") }}
          </div>
          <div class="right_text">
            {{ project.dateClose === "~" ? project.dateEnd : project.dateClose }}
          </div>
        </div>
        <div class="line">
          <div class="left_text">
            {{ $t("auCapitals.capitalCard.profit") }}
          </div>
          <div class="right_text">
            {{ project.profitability }}
          </div>
        </div>
        <div class="line indent_bottom">
          <div class="left_text">
            {{ $t("auCapitals.capitalCard.payment") }}
          </div>
          <div class="right_text">
            {{ project.payments }}
          </div>
        </div>
      </div>

      <div class="progress_line">
        <div class="progress_text">
          <AuDecimalStatic
            :value="project.currentCap"
            :addition="project.mnemonicEng"
          />
        </div>
        <div class="progress_text">
          {{ project.progressProcent + "%" }}
        </div>
      </div>

      <ProgressBar
        class="indent_progress indent_progress-button"
        :procent="project.progressProcent"
      />

      <div class="date">
        <div class="date__label">
          {{ $t("auCapitals.capitalCard.posting") }}
        </div>

        <div class="date__value">
          {{ project.dateStart }}
        </div>
      </div>

      <div class="indent_progress-button">
        <AuButton
          bordered
          width="100%"
          center
          @click="showCard"
        >
          <div class="indent_progress-button-text">
            {{ $t("auCapitals.capitalCard.more") }}
          </div>
        </AuButton>
      </div>
      <!-- <AuButton
        bordered
        width="100%"
        center
        active-bg-color="#279063"
        :disabled="project.status !== 'Live'"
        @click="showDialog=true"
      >
        Инвестировать
      </AuButton> -->
    </div>
    <AuModal
      v-model="showDialog"
      width="500px"
    >
      <template #header>
        <div class="modal_header">
          {{ $t("auCapitals.capitalCard.invest") }}&nbsp;{{ project.name }}
        </div>
      </template>
      <template #body>
        <div class="modal_text_small">
          {{ $t("auCapitals.capitalCard.count") }}
        </div>
        <AuInput
          v-model="tokenCount"
        />
        <div class="mt-2 d-flex justify-content-between">
          <span class="modal_text_large">{{ $t("auCapitals.capitalCard.price") }}</span>
          <span class="modal_text_large modal_color">
            <AuDecimalStatic
              :value="project.priceBase"
              :addition="project.mnemonicEng"
            />
          </span>
        </div>
        <div class="mt-1 d-flex justify-content-between">
          <span class="modal_text_large">{{ $t("auCapitals.capitalCard.need") }}</span>
          <span class="modal_text_large modal_color">
            <AuDecimalStatic
              :value="project.maxCap"
            />
          </span>
        </div>
        <div class="mt-1 d-flex justify-content-between">
          <span class="modal_text_large">{{ $t("auCapitals.capitalCard.current") }}</span>
          <span class="modal_text_large modal_color">
            <AuDecimalStatic
              :value="project.currentCap"
            />
          </span>
        </div>
        <div class="mt-1 d-flex justify-content-between">
          <span class="modal_text_large">{{ $t("auCapitals.capitalCard.min") }}</span>
          <span class="modal_text_large modal_color">
            <AuDecimalStatic
              :value="project.minCap"
            />
          </span>
        </div>
        <div>
          <span
            v-if="!isCorrectNumber"
            class="modal_text_error"
          >{{ $t("auCapitals.capitalCard.incorrectSum") }}</span>
          <span
            v-else-if="owner === undefined"
            class="modal_text_error"
          >{{ $t("auCapitals.capitalCard.notHaveTokens") }}</span>
          <span
            v-else-if="!isEnough"
            class="modal_text_error"
          >{{ $t("auCapitals.capitalCard.notEnough") }}</span>
        </div>
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80"
              :disabled="!isCorrectNumber || !isEnough || owner === undefined || tokenCount == 0"
              @click="investCapital"
            >
              {{ $t("auCapitals.capitalCard.buttonInvest") }}
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80"
              @click="showDialog=false"
            >
              {{ $t("auCapitals.capitalCard.buttonCancel") }}
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import moment from "moment/moment";
import ProgressBar from "@/components/progressBar/ProgressBar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CapitalCard",

  components: {
    ProgressBar
  },

  props: {
    project: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      showDialog: false,
      tokenCount: "0"
    };
  },

  computed: {
    ...mapGetters({
      auOwners: "auOwners"
    }),

    description() {
      return this.project.descriptionRus ? this.project.descriptionRus.replace("<p>", "").replace("</p>", "") : "";
    },

    style() {
      return {
        "--status-color": this.project.statusColor
      };
    },

    progressStyle() {
      return this.project.id % 2 !== 0;
    },

    owner() {
      return this.auOwners.find(item => item.auToken.id == this.project.tokenQuoteId);
    },

    isEnough() {
      let count = BigNumber(this.tokenCount);
      count = count.multipliedBy(this.project.priceBase);
      if (this.owner === undefined) {
        return false;
      }
      return count.isLessThanOrEqualTo(this.owner.amountFree);
    },

    isCorrectNumber() {
      return this.tokenCount.match(/^\d+$/);
    }
  },

  methods: {
    ...mapActions({
      getAuCapitals: "capitals/getAuCapitals",
      sendTokenEvents: "sendTokenEvents"
    }),

    showCard() {
      this.$router.push({ name: "project card", params: { id: this.project.id } });
    },

    showSuccess() {
      this.$toast.success(this.$t("auCapitals.capitalCard.success"), {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    async investCapital() {
      this.showDialog = false;
      const params = {
        user: `/api/users/${this.$auth.user().id}`,
        createdAt: moment(),
        type: "token_capital_buy",
        description: "buy",
        value: this.tokenCount,
        address: "",
        isTask: true,
        auCapital: `api/au_capitals/${this.project.id}`
      };
      try {
        await this.sendTokenEvents(params);
        this.showSuccess();
      }
      catch (e) {
        this.showError(this.$t("auCapitals.capitalCard.error"));
      }
    }
  }
};
</script>

<style scoped lang="scss">
.p_card{
  border: 1px solid var(--color-90);
  border-radius: 25px;
  width: 375px;
  height: 648px;
  padding: 15px;
  position: relative;
  background-color: var(--card-color);
}

.line{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
}

.progress_line {
  margin-top: 30px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
}

.line-wrap {
  margin-top: 20px;
}

.time
{
  background: var(--status-color);
  border-radius: 10px;
  padding: 10px;
  color: #FFFFFF;
  font-weight: 450;
  font-size: 12px;
  position: absolute;
  top: 25px;
  left: 25px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tag
{
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px;
  color: black;
  font-weight: 450;
  font-size: 12px;
  position: absolute;
  top: 25px;
  right: 23px;
}

.name
{
  color: var(--text-color);
  font-weight: 450;
  font-size: 18px;
  margin-top: 20px;
}

.description {
  color: var(--color-40);
  height: 60px;
  margin-top: 5px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
  font-size: 14px;
}

.date {
  margin-top: 10px;
  margin-bottom: 20px;

  display: flex;
  gap: 10px;

  font-size: 12px;

  &__label {
    color: var(--color-30)
  }
}

.price
{
  font-weight: 500;
  font-size: 20px;
  color: #28E593;
  margin-top: 30px;
}

.left_text
{
  font-weight: 450;
  font-size: 14px;
  color: var(--color-50);
}

.progress_text {
  font-weight: 450;
  font-size: 12px;
  color: var(--color-20);
}

.right_text{
  font-weight: 450;
  font-size: 14px;
  color: var(--text-color);
}

.image
{
  width: 345px;
  height: 180px;
  object-fit: cover;
  border-radius: 16px;
}

.indent_progress-button
{
  margin-bottom: 5px;

  &-text {
    color: var(--color-20)
  }
}
.modal_button
 {
   display: flex;
   justify-content: end;
   margin-top: 15px;
 }
.modal_button_margin
{
  margin-right: 10px;
}
.modal_header{
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-70);
}
.modal_text_small
{
  font-size: 11px;
}
.modal_text_large{
  font-size: 13px;
}
.modal_color{
  color: var(--color-60);
}
.modal_text_error{
  color: red;
  font-size: 10px;
}
</style>
