import actions from "./actions";

export default {
  namespaced: true,
  state: {
    auCapitals: [],
    searchedAuCapitals: [],
    investors: [],
    investorsTotal: null,
    dataLoading: false
  },
  getters: {
    auCapitals: state => {
      return state.auCapitals;
    },

    searchedAuCapitals: state => {
      return state.searchedAuCapitals;
    },

    investors: state => {
      return state.investors;
    },

    investorsTotal: state => {
      return state.investorsTotal;
    },

    dataLoading: state => {
      return state.dataLoading;
    }
  },
  mutations: {
    setAuCapitals(state, capitals) {
      state.auCapitals = capitals;
    },

    setSearchedAuCapitals(state, capitals) {
      state.searchedAuCapitals = capitals;
    },

    setInvestors(state, investors) {
      state.investors = investors;
    },

    setInvestorsTotal(state, investorsTotal) {
      state.investorsTotal = investorsTotal;
    },

    setDataLoading(state, dataLoading) {
      state.dataLoading = dataLoading;
    }
  },
  actions
};