<template>
  <div class="card documentation">
    <div class="header">
      <div class="text">
        {{ $t("documentation.label") }}
      </div>
      <input
        ref="documentation-upload"
        class="invisible"
        type="file"
        @input="uploadDocument"
      >
      <AuButton
        border-radius="10"
        center
        bordered
        @click="$refs['documentation-upload'].click()"
      >
        {{ $t("documentation.button") }}
      </AuButton>
    </div>
    <AuTable
      :columns="columns"
      :items="items"
      :loading="dataLoading"
    >
      <template #action="{value}">
        <div
          v-click-outside="closeAllMenus"
          class="container-menu"
        >
          <AuIcon
            icon="more"
            clickable
            @click="openMenu(value.index)"
          />
          <div
            v-if="showMenu[value.index]"
            class="context-menu"
          >
            <AuButton
              width="100%"
              center
              @click="doAction(value)"
            >
              {{ $t("documentation.actionDelete") }}
            </AuButton>
          </div>
        </div>
      </template>
    </AuTable>
    <AuPagination
      v-if="totalUploadedFiles > itemsPerPage"
      :total-items="totalUploadedFiles"
      @pageChanged="changePage"
    />
    <div class="modal_docs">
      <AuModal
        v-model="actionDialog"
        width="490px"
      >
        <template #header>
          <span class="title">{{ $t("documentation.modalHeader") }}</span>
        </template>
        <template #body>
          <span class="text">{{ deleteName }}</span>
        </template>
        <template #footer>
          <div class="button">
            <AuButton
              bordered
              center
              width="120px"
              @click="deleteDocument"
            >
              {{ $t("documentation.modalButtonYes") }}
            </AuButton>
            <AuButton
              bordered
              center
              width="120px"
              @click="actionDialog=false"
            >
              {{ $t("documentation.modalButtonCancel") }}
            </AuButton>
          </div>
        </template>
      </AuModal>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Documentation",
  data() {
    return {
      showMenu: [],
      columns: [
        { name: this.$t("documentation.firstColumn"), align: "start" },
        { name: this.$t("documentation.secondColumn"), align: "center" },
        { name: this.$t("documentation.thirdColumn"), align: "center" },
        { name: this.$t("documentation.fourthColumn"), align: "start" },
        { name: this.$t("documentation.fifthColumn"), align: "center" },
        { name: this.$t("documentation.sixthColumn"), align: "center", slot: "action" }
      ],
      page: 1,
      itemsPerPage: 30,
      actionDialog: false,
      deleteName: null,
      deleteId: null
    };
  },
  computed: {
    ...mapGetters({
      uploadedFiles: "files/uploadedFiles",
      totalUploadedFiles: "files/totalUploadedFiles",
      dataLoading: "files/dataLoading"
    }),

    items() {
      return _.map(this.uploadedFiles, (item, index) => {
        return {
          name: this.getName(item.nameOriginal),
          type: item.type,
          size: this.getSize(item.size),
          createdAt: moment(item.createdAt).format("HH:mm:ss DD.MM.YYYY"),
          updatedAt: moment(item.updatedAt).format("HH:mm:ss DD.MM.YYYY"),
          action: {
            id: item.id,
            index: index,
            name: item.nameOriginal
          }
        };
      });
    }
  },
  async mounted() {
    await this.getFiles();
  },
  methods: {
    ...mapActions({
      getUploadedFiles: "files/getUploadedFiles",
      uploadFile: "files/uploadFile",
      deleteFile: "files/deleteFile"
    }),

    closeAllMenus() {
      this.showMenu = _.map(this.uploadedFiles, () => false);
    },

    openMenu(index) {
      this.closeAllMenus();
      this.showMenu[index] = true;
    },

    doAction(value) {
      this.deleteName = value.name;
      this.deleteId = value.id;
      this.actionDialog = true;
    },

    async getFiles() {
      await this.getUploadedFiles({ page: this.page, itemsPerPage: this.itemsPerPage });
    },

    getSize(size) {
      if (size > 1048576) {
        return (size / 1048576).toFixed(2) + " Mb";
      }
      if (size > 1024) {
        return (size / 1024).toFixed(2) + " Kb";
      }
      return size + " b";
    },

    getName(name) {
      return name.length > 25 ? name.slice(0, 20) + "..." + name.slice(-4) : name;
    },

    async changePage(page) {
      this.page = page;
      await this.getFiles();
    },

    uploadDocument(event) {
      let input = event.target;
      if (input.files && input.files[0]) {
        let reader = new FileReader();
        reader.onload = () => {
          try {
            this.uploadFile(input.files[0]);
            this.getFiles();
            this.showSuccess(this.$t("documentation.success.upload"));
          }
          catch (e) {
            this.showError(this.$t("documentation.error.upload"));
          }
        };
        reader.readAsArrayBuffer(input.files[0]);
      }
    },

    async deleteDocument() {
      this.actionDialog = false;
      try {
        await this.deleteFile(this.deleteId);
        await this.getFiles();
        this.showSuccess(this.$t("documentation.success.delete"));
      }
      catch (e) {
        this.showError(this.$t("documentation.error.delete"));
      }
    },

    showSuccess(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    }
  }
};
</script>

<style scoped lang="scss">
.documentation {
  padding: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    .text {
      font-weight: 600;
      font-size: 24px;
    }
  }
  .invisible{
    display: none;
  }
}
.container-menu{
  position: relative;
}
.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 128px;
  right: calc(50% - 128px / 2);
  background: var(--main-color);
  z-index: 10;
  top: 30px;
}
.modal_docs{
  .title {
    font-weight: 450;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .text {
    color: var(--color-60);
  }
  .button {
    display: flex;
    justify-content: end;
    gap: 15px;
    margin-top: 15px;
  }
}
</style>