<template>
  <div class="menu__buttons">
    <AuButton
      v-for="page in pages"
      :key="page.name"
      :icon="page.meta.icon"
      :active="($route.name == page.name)"
      width="100%"
      @click="setRoute(page)"
    >
      {{ $t(page.meta.title) }}
    </AuButton>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    swiperMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pages() {
      let parent = _.find(this.$route.matched, match => match.meta.isMain);
      if (parent) {
        return _.filter(parent.children, child => _.some(_.get(child, "meta.access"), acess => acess == this.$auth.user().type));
      }
      else {
        return [];
      }
    }
  },

  methods: {
    setRoute(page) {
      this.$router.push({ name: page.name });
    }
  }
};
</script>

<style scoped lang="scss">
.menu__buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>