<template>
  <div
    :style="inputStyle"
    class="input-wrapper"
  >
    <div
      v-if="label"
      class="input-label"
      :disabled="disabled"
    >
      {{ label }}
    </div>

    <Datepicker
      v-if="type === 'Date'"
      :model-value="modelValue"
      locale="ru"
      auto-apply
      class="date-input"
      :min-date="minDate"
      :format="format"
      :enable-time-picker="false"
      :placeholder="placeholder"
      @update:modelValue="value => input(value)"
    >
      <template #clear-icon="{ clear }">
        <div @click="clear">
          ×
        </div>
      </template>
    </Datepicker>

    <div
      v-else
      class="input_block"
      :style="type === 'TextArea' ? textAreaStyle : ''"
      :class="{'required-error' : reqError}"
    >
      <img
        v-if="icon !== ''"
        :src="`/svg/light/${icon}.svg`"
        alt="no_img"
      >

      <ImaskInput
        v-if="type === 'Mask'"
        :model-value="modelValue"
        class="border_off input"
        :placeholder="placeholder"
        :mask="mask"
        :max="max"
        @update:modelValue="input"
      />

      <AuDecimalInput
        v-if="type === 'Number'"
        class="border_off input"
        :decimal="decimal"
        :value="modelValue"
        :placeholder="placeholder"
        @input="value => input(value)"
      />

      <label
        v-if="type === 'File'"
        class="input-file"
      >
        <input
          type="file"
          accept="image/png, image/jpeg"
          class="border_off input"
          :placeholder="placeholder"
          :value="modelValue"
          :disabled="disabled"
          @input="e => input(e.target.files[0])"
        >
        <span>{{ uploadedFileName ? uploadedFileName : fileLabel }}</span>
      </label>

      <input
        v-if="type === 'Text'"
        type="text"
        :disabled="disabled"
        class="border_off input"
        :placeholder="placeholder"
        :value="modelValue"
        :maxlength="maxlength"
        @input="e => input(e.target.value)"
      >

      <input
        v-if="type === 'Date'"
        type="date"
        class="border_off input"
        :placeholder="placeholder"
        :value="modelValue"
        :disabled="disabled"
        @input="e => input(e.target.value)"
      >

      <textarea
        v-if="type === 'TextArea'"
        class="is-text-area"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="e => input(e.target.value)"
      />
      <div
        v-if="clearable && showFlush"
        class="reset-field"
        @click.native="flushField"
      >
        ×
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { IMaskComponent } from "vue-imask";
import { mapGetters } from "vuex";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "AuInput",

  components: {
    "ImaskInput": IMaskComponent,
    "Datepicker": Datepicker
  },

  props: {
    icon: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    modelValue: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "Text",
      validator: function (value) {
        let validationResult =
          [
            "Text",
            "Number",
            "Mask",
            "TextArea",
            "MarkDown",
            "Date",
            "File"
          ].indexOf(value) !== -1;

        if (!validationResult) {
          console.error(value);
        }

        return validationResult;
      }
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    clearable: {
      type: Boolean,
      default: false
    },
    textAreaHeigth: {
      type: Number,
      default: 150
    },
    mask: {
      type: [RegExp, Function],
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    minDate: {
      type: String,
      default: ""
    },
    format: {
      type: Function,
      default: v => v
    },
    uploadedFileName: {
      type: String,
      default: ""
    },
    borderRadius: {
      type: String,
      default: "12"
    },
    decimal: {
      type: Number,
      default: 0
    },
    maxlength: {
      type: Number,
      default: 255
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      fileLabel: this.$t("news.form.download")
    };
  },

  computed: {
    ...mapGetters({
      language: "language"
    }),

    textAreaStyle() {
      return `height: ${this.textAreaHeigth}px`;
    },

    inputStyle() {
      return {
        "--border-radius": this.borderRadius + "px"
      };
    },

    reqError() {
      return this.hasError
      || (this.required && (this.type == "File" ? this.fileLabel == this.$t("news.form.download") && !this.uploadedFileName : _.isEmpty(this.modelValue)));
    },

    showFlush() {
      return !_.isEmpty(this.modelValue);
    },

    isLightTheme() {
      return this.$store.getters.isLightTheme;
    }
  },

  watch: {
    language() {
      this.fileLabel = this.$t("news.form.download");
    }
  },

  methods: {
    input(value) {
      this.$emit("update:modelValue", value);
      if (this.type == "File") {
        this.fileLabel = value.name;
      }
    },

    flushField() {
      this.$emit("update:modelValue", "");
    }
  }
};
</script>

<style lang="scss">
.date-input {
  input {
    border: 1px solid var(--button-border-color);
    border-radius: var(--border-radius);
    padding: 13px 15px 13px;
    height: 37px;
    color: var(--color-50);
    background-color: var(--main-color);
  }
  .dp__input_icon {
    right: 0px;
    left: auto;
  }
  .dp__clear_icon {
    right: 35px;
  }
}
.input-wrapper {
  width: 100%;
}
.input {
  color: var(--color-50);
  &[disabled] {
    &::placeholder {
      color: var(--color-40);
    }
  }
  &[type="file"] {
    background: red;
  }
}
.input-label {
  font-size: 14px;
  margin-bottom: 15px;
  color: var(--color-50);
  &[disabled="true"] {
    color: var(--color-40);
  }
}
.input_block {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  border: 1px solid var(--color-70);
  border-radius: var(--border-radius);
  font-weight: 450;
  font-size: 13px;
  height: 44px;
  padding: 7px 10px;
  width: auto;
  position: relative;
  &:has(>input[disabled]),
  &:has(>.input-file > input[disabled]) {
    border: 1px solid var(--color-40) !important;
  }
  &:has(>.input-file) {
    justify-content: center;
  }
}

.border_off{
  border: none;
  outline: none;
  margin-left: 5px;
}
.required-error {
  border-color: #ff000069;
}
.reset-field {
  position: absolute;
  right: 15px;
  top: 8px;
  user-select: none;
  color: var(--color-50);
  &:hover {
    cursor: pointer;
  }
}
.is-text-area{
  width: 100%;
  border-radius: 12px;
  padding-top: 6px;
  resize: none;
  border: 0;
  height: 100%;
  outline: none;
  :focus {
    outline: none;
  }
}

.input-file {
	position: relative;
	display: inline-block;
  overflow: hidden;
  width: 100%;
  span {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    font-size: 14px;
    vertical-align: middle;
    color: var(--text-color);
    text-align: center;
    border-radius: 4px;
    line-height: 22px;
    height: 40px;
    padding: 10px 20px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    transition: background-color 0.2s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  input[type=file] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    display: block;
    width: 0;
    height: 0;
    &:disabled + span {
      color: var(--color-40);
      user-select: none;
      cursor: auto;
    }
  }
}
</style>

