<template>
  <div>
    <div class="d-xl-flex filter-line">
      <div
        class="col-xl d-flex order-xl-1 justify-content-between justify-content-xl-end my-2 my-xl-0 me-3"
      >
        <div class="me-2">
          <AuInput
            v-model="search"
            icon="searchc"
            border-radius="40"
            :placeholder="$t('auCapitals.projects.placeholderSearch')"
          />
        </div>
      </div>
      <div class="col-xl d-flex switches">
        <AuSelect
          :value="type"
          :options="typeOptions"
          border-radius="40"
          @input="value => type = value"
        />

        <AuSelect
          class="selector"
          :value="selectedCategoryId"
          :placeholder="$t('auCapitals.projects.placeholderCategory')"
          :options="sectionCapitalOptions"
          border-radius="40"
          @input="(value) => (selectedCategoryId = value)"
        />
      </div>
    </div>

    <ProgressLoader
      v-if="loading"
      mode="indeterminate"
      color="#5AC396"
      size="150px"
    />

    <div class="projects">
      <div
        v-for="proj in typeFilters"
        :key="proj.id"
      >
        <CapitalCard :project="proj" />
      </div>
    </div>
    <AuPagination
      v-if="totalItems > 30"
      :total-items="totalItems"
      @pageChanged="pageChanged"
    />
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import CapitalCard from "@/components/CapitalCard";
import moment from "moment";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Projects",
  components: { CapitalCard,
    ProgressLoader
  },
  data() {
    return {
      search: null,
      projects: [],
      filtersFlag: {},
      selectedCategoryId: null,
      auCapitals: [],
      page: 1,
      totalItems: 1,
      loading: true,
      type: "All"
    };
  },

  computed: {
    ...mapGetters({
      sectionCapital: "sectionCapital",
      // auCapitals: "auCapitals",
      uploadFile: "files/uploadedFile",
      searchedAuCapitals: "capitals/searchedAuCapitals"
    }),

    typeOptions() {
      return [
        { display: this.$t("auCapitals.projects.typeAll"), value: "All" },
        { display: this.$t("auCapitals.projects.typeLive"), value: "Live" },
        { display: this.$t("auCapitals.projects.typeSoon"), value: "Soon" },
        { display: this.$t("auCapitals.projects.typeEnded"), value: "Ended" }
      ];
    },

    sectionCapitalOptions() {
      return _.map(this.sectionCapital, (capital) => ({
        display: capital.name,
        value: capital.id
      }));
    },

    typeFilters() {
      switch (this.type) {
      case "Live": return _.filter(this.filtersProjects, item => item.status == "Live");
      case "Ended": return _.filter(this.filtersProjects, item => item.status == "Closed" || item.status == "Finish");
      case "Soon": return _.filter(this.filtersProjects, item => {
        const delta = moment(item.end).diff(moment(), "days");
        return delta > 0 && delta < 8;
      });
      }
      return this.filtersProjects;
    },

    filtersProjects() {
      let projects = _.cloneDeep(this.projects);

      if (this.search) {
        projects = this.selectedSearched();
      }

      if (this.selectedCategoryId) {
        return _.filter(projects, (proj) => {
          return proj.sectionId == this.selectedCategoryId;
        });
      }
      else {
        return projects;
      }
    }
  },

  watch: {
    async search(value) {
      await this.getSearchedAuCapitals(value);
    }
  },

  async mounted() {
    this.loading = true;
    await this.getSectionCapital();
    await this.getCapital();
    if (process.env.VUE_APP_USE_WEBSOCKET !== "false") {
      await this.sendCapital(_.map(this.auCapitals, item => item.id));
    }
    this.loading = false;
    for (let i = 0; i < this.sectionCapital; ++i) {
      let item = {};
      item[this.sectionCapital[i].id] = false;
      this.filtersFlag.push(item);
    }
  },

  methods: {
    ...mapActions({
      getSectionCapital: "getSectionCapital",
      getSearchedAuCapitals: "capitals/getSearchedAuCapitals",
      getUploadedFile: "files/getUploadedFile",
      getAllAuCapitalsPagination: "capitals/getAllAuCapitalsPagination",
      sendCapital: "sendCapital"
    }),

    async pageChanged(actualPage) {
      this.page = actualPage;
      await this.getCapital();
    },

    async getCapital() {
      try {
        let resp = await this.getAllAuCapitalsPagination({
          pageId: this.page
        });
        this.auCapitals = resp.data;
        this.totalItems = resp.totalItems;

        const capitalData = this.auCapitals;
        this.projects = await this.convertAuCapitals(capitalData);
      }
      catch (e) {
        console.log("error", e);
      }
    },

    selectedSearched() {
      const set = new Set();
      for (const { id } of this.searchedAuCapitals) {
        set.add(id);
      }

      const intersection = this.projects.filter(({ id }) => set.has(id));
      return intersection;
    },

    async convertAuCapitals(auCapitals) {
      let projects = [];
      for (const item of auCapitals) {
        let project = {};
        project.id = item.id;
        project.tag = this.getSectionName(item.section);
        project.sectionId = this.getId(item.section);
        project.name = item.tokenBase.nameEng;
        project.icon = item?.thumb;
        project.dateStart = moment(item.dateStart).format("DD.MM.YYYY");
        project.dateEnd = moment(item.dateEnd).format("DD.MM.YYYY");
        project.dateClose =
            "dateClose" in item
              ? moment(item.dateClose).format("DD.MM.YYYY")
              : "~";
        project.profitability = _.get(item, "expectedProfit", "-");
        project.payments = item.id % 2 !== 0 ?
          this.$t("auCapitals.projects.quarterly") : this.$t("auCapitals.projects.monthly");
        project.maxCap = item.maxCap;
        let percent = BigNumber(item.currentCap);
        percent = percent.multipliedBy(100);
        percent = percent.dividedBy(item.maxCap);
        project.progressProcent = percent.toFixed(1);
        project.decimals = item.tokenQuote.decimals;
        project.mnemonicEng = item.tokenQuote.mnemonicEng;
        project.tokenQuoteId = item.tokenQuote.id;
        project.priceBase = item.priceBase;
        project.minCap = item.minCap;
        project.descriptionEng = item.descriptionEng;
        project.descriptionRus = item.descriptionRus;
        project.currentCap = item.currentCap;
        project.end = item.dateEnd;
        if (moment(item.dateStart).isAfter(new Date)) {
          project.status = "Waiting";
          project.statusColor = "rgba(25, 37, 54, 1)";
          project.statusIcon = "clock";
        }
        else if ("isClosed" in item) {
          project.statusColor = "rgba(25, 54, 42, 1)";
          project.statusIcon = "rocketc";
          project.status = item.isClosed ? "Closed" : "Live";
          if (!item.isClosed && (percent >= 100 || moment(item.dateEnd).isBefore(new Date))) {
            project.status = "Finish";
          }
        }
        projects.push(project);
      }

      return projects;
    },

    getId(value) {
      if (value === null || value === undefined) {
        return null;
      }
      const slashPos = value.lastIndexOf("/") + 1;
      if (slashPos === 0) {
        return null;
      }
      return value.slice(slashPos);
    },

    getSectionName(value) {
      const id = this.getId(value);
      const res = this.sectionCapital.find((item) => item.id == id);
      if (res === undefined) {
        return null;
      }
      return res.name;
    },

    async getImgByIri(iri) {
      if (_.isNil(iri)) {
        return "";
      }
      const fileId = iri.slice(iri.lastIndexOf("/") + 1);
      await this.getUploadedFile(fileId);
      return this.uploadFile.name;
    }
  }
};
</script>

<style scoped>
.switches {
  gap: 10px;
}

.filter-line {
  margin-bottom: 35px;
}

.projects {
  display: flex;
  gap: 25px;
  margin-top: 45px;
  flex-wrap: wrap;
}

.selector {
  min-width: 220px;
}
</style>
