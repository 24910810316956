<template>
  <div class="main-panel">
    <RouterView />
    <Footer />
  </div>
</template>

<script>
import Footer from "@/panels/Footer.vue";

export default {
  components: {
    Footer
  }
};
</script>

<style lang="scss" scoped>
.main-panel {
  padding: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: auto;
}
</style>