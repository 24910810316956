<template>
  <div class="card project-investors">
    <div class="title">
      Инвесторы проекта
    </div>
    <AuTable
      :columns="columns"
      :loading="dataLoading"
      :items="items"
    />
    <AuPagination
      v-if="investorsTotal > itemsPerPage"
      :total-items="investorsTotal"
      @pageChanged="changePage"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProjectInvestors",
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      columns: [
        { name: "Имя", align: "start" },
        { name: "Фамилия", align: "start" },
        { name: "E-mail", align: "start" },
        { name: "Количество", align: "start" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      investors: "capitals/investors",
      investorsTotal: "capitals/investorsTotal",
      dataLoading: "capitals/dataLoading"
    }),

    items() {
      return _.map(this.investors, item => ({
        iName: item.user.iName,
        fName: item.user.fName,
        email: item.user.email,
        value: item.value
      }));
    }
  },
  async mounted() {
    await this.getItems();
  },
  methods: {
    ...mapActions({
      getCapitalInvestor: "capitals/getCapitalInvestor"
    }),

    async getItems() {
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        auCapital: this.id
      };
      await this.getCapitalInvestor(params);
    },

    async changePage() {
      this.page++;
      await this.getItems();
    }
  }
};
</script>

<style scoped lang="scss">
.project-investors {
  padding: 20px;
  .title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 35px;
  }
}
</style>