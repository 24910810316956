<template>
  <LastTransactionsTable />
</template>

<script>
import LastTransactionsTable from "@/components/lastTransactions/LastTransactionsTable.vue";

export default {
  components: {
    LastTransactionsTable
  }
};
</script>