import axios from "axios";
import driverAuthBearer from "@websanova/vue-auth/dist/drivers/auth/bearer.esm.js";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js";
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js";
import router from "@/router";
import { createAuth } from "@websanova/vue-auth";

const auth = createAuth({
  plugins: {
    http: axios,
    router: router
  },
  drivers: {
    http: driverHttpAxios,
    auth: driverAuthBearer,
    router: driverRouterVueRouter
  },
  options: {
    rolesKey: "type",
    // notFoundRedirect: {name: 'user-account'},
    tokenDefaultKey: "access_token",
    stores: ["storage", "cookie"]
  }
});

export default auth;