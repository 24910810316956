import axios from "axios";

export default class axiosClient {
  async getDividends(page, itemsPerPage, auTokenId, status) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };

    const params = {
      page: page,
      itemsPerPage: itemsPerPage
    };
    if (auTokenId !== undefined) {
      params.auTokenId = auTokenId;
    }
    if (status !== undefined) {
      params.status = status;
    }

    const resp = await axios.get("/api/dividends", {
      headers: headers,
      params: params
    });

    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }

  async addDividends(params) {
    return await axios.post("/api/dividends", params);
  }

  async editDividends(id, body) {
    const headers = {
      "Content-Type": "application/merge-patch+json"
    };
    return await axios.patch(`/api/dividends/${id}`, body, { headers });
  }

  async deleteDividends(id) {
    return await axios.delete(`/api/dividends/${id}`);
  }

  async getDividendsHistory(page, itemsPerPage, dividendsId) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };

    const params = {
      page: page,
      itemsPerPage: itemsPerPage
    };

    if (dividendsId) {
      params.dividendsId = dividendsId;
    }

    const resp = await axios.get("/api/dividends_histories", {
      headers: headers,
      params: params
    });

    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }
}