<template>
  <div>
    <AuButton
      bordered
      @click="showAddDialog = true"
    >
      {{ $t("paymentMethod.addCard") }}
    </AuButton>

    <span v-if="elements.length == 0 && !dataLoading">
      {{ $t("paymentMethod.noCards") }}
    </span>

    <AuTable
      v-else
      class="payment-table"
      :loading="dataLoading"
      :columns="columns"
      :items="elements"
    >
      <template #action="{value}">
        <div
          v-click-outside="() => menuIndex = null"
          class="container-menu"
        >
          <AuIcon
            icon="more"
            clickable
            @click="menuIndex = value"
          />
          <div
            v-if="menuIndex === value"
            class="context-menu"
          >
            <AuButton
              width="100%"
              center
              @click="editCard(value)"
            >
              {{ $t("paymentMethod.modal.edit") }}
            </AuButton>
            <AuButton
              width="100%"
              center
              @click="tryDeleteCard(value)"
            >
              {{ $t("paymentMethod.modal.delete") }}
            </AuButton>
          </div>
        </div>
      </template>
    </AuTable>

    <AuModal
      v-model="showAddDialog"
      width="500px"
      @closeModal="card = {}"
    >
      <template #header>
        <span class="modal_head_text">
          {{ card.id ? $t("paymentMethod.modal.editHeader") : $t("paymentMethod.modal.addHeader") }}
        </span>
      </template>
      <template #body>
        <div class="modal-body">
          <AuInput
            v-model="card.name"
            :label="$t('paymentMethod.table.name')"
            required
          />
          <AuInput
            :label="$t('paymentMethod.table.number')"
            required
            :has-error="!isCardValid"
            :model-value="formatCardNumber(card.cardNumber)"
            @update:modelValue="updateCardNumber"
          />
          <AuInput
            :model-value="card.cardHolder"
            :label="$t('paymentMethod.table.holder')"
            required
            type="Mask"
            :mask="/^[A-Za-z ]+$/"
            @update:modelValue="value => card.cardHolder = value.toUpperCase()"
          />
          <AuInput
            v-model="card.expiresAt"
            :label="$t('paymentMethod.table.expiresAt')"
            required
            :has-error="!isDateValid"
            type="Mask"
            mask="00/00"
          />
        </div>
      </template>
      <template #footer>
        <div class="modal-buttons">
          <AuButton
            bordered
            center
            width="80"
            :disabled="!isModalValid"
            @click="tryAddCard"
          >
            {{ $t("paymentMethod.modal.save") }}
          </AuButton>
          <AuButton
            bordered
            center
            width="80"
            @click="showAddDialog=false"
          >
            {{ $t("paymentMethod.modal.cancel") }}
          </AuButton>
        </div>
      </template>
    </AuModal>

    <AuModal
      v-model="showDeleteDialog"
      width="500px"
    >
      <template #header>
        <span class="modal_head_text">
          {{ $t("paymentMethod.modal.deleteHeader") }}
        </span>
      </template>
      <template #body>
        <span>
          {{ $t("paymentMethod.modal.deleteMessage") }}
        </span>
      </template>
      <template #footer>
        <div class="modal-buttons">
          <AuButton
            bordered
            center
            width="80px"
            @click="deleteCard"
          >
            {{ $t("paymentMethod.modal.yes") }}
          </AuButton>
          <AuButton
            bordered
            center
            width="80px"
            @click="showDeleteDialog=false"
          >
            {{ $t("paymentMethod.modal.no") }}
          </AuButton>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import valid from "card-validator";

export default {
  name: "PaymentMethod",

  data() {
    return {
      showAddDialog: false,
      cardTemplate: {
        name: "",
        cardHolder: "",
        expiresAt: "",
        cardNumber: "",
        user: ""
      },
      columns: [{ name: this.$t("paymentMethod.table.name"), align: "start" },
        { name: this.$t("paymentMethod.table.number"), align: "start" },
        { name: this.$t("paymentMethod.table.holder"), align: "start" },
        { name: this.$t("paymentMethod.table.expiresAt"), align: "start" },
        { name: this.$t("paymentMethod.table.actions"), align: "center", slot: "action" }],
      card: {},
      cardToDelete: {},
      menuIndex: null,
      showDeleteDialog: false
    };
  },

  computed: {
    ...mapGetters({
      cards: "payment/cards",
      dataLoading: "payment/dataLoading"
    }),

    elements() {
      return _.map(this.cards, card => {
        return {
          name: card.name,
          cardNumber: this.formatCardNumber(card.cardNumber),
          cardHolder: card.cardHolder,
          expiresAt: moment(card.expiresAt).format("MM/YY"),
          actions: card.id
        };
      });
    },

    isCardValid() {
      let numberValidation = valid.number(this.card.cardNumber);

      if (numberValidation.isValid) {
        return true;
      }

      return false;
    },

    isDateValid() {
      return moment(this.card.expiresAt, "MM/YY").isValid();
    },

    isModalValid() {
      return this.isCardValid && this.isDateValid && this.card.cardHolder && this.card.name;
    }
  },

  async created() {
    await this.getCards(this.$auth.user().id);
  },

  methods: {
    ...mapActions({
      getCards: "payment/getCards",
      deleteCardAction: "payment/deleteCard",
      addCard: "payment/addCard",
      editCardAction: "payment/editCard"
    }),

    formatCardNumber(value) {
      return value ? value.match(/.{1,4}/g).join(" ") : "";
    },

    updateCardNumber(value) {
      this.card.cardNumber = value.replace(/ /g, "");
    },

    editCard(value) {
      this.card = _.cloneDeep(_.find(this.cards, { id: value })) || {};
      this.card.expiresAt = moment(this.card.expiresAt).format("MM/YY");
      this.showAddDialog = true;
    },

    async deleteCard() {
      try {
        await this.deleteCardAction(this.cardToDelete.id);
        await this.getCards(this.$auth.user().id);

        this.$toast.success(this.$t("paymentMethod.toast.deleteSuccess"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
      catch (error) {
        console.error(error);

        this.$toast.error(this.$t("paymentMethod.toast.deleteError"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
      finally {
        this.showDeleteDialog = false;
      }
    },

    tryDeleteCard(value) {
      this.cardToDelete = _.cloneDeep(_.find(this.cards, { id: value }));
      this.showDeleteDialog = true;
    },

    openModal() {
      this.card = _.cloneDeep(this.cardTemplate) || {};
      this.showAddDialog = true;
    },

    async tryAddCard() {
      if (!this.isModalValid) {
        return;
      }

      if (this.card.id) {
        try {
          this.showAddDialog = false;
          await this.editCardAction(this.card);

          this.$toast.success(this.$t("paymentMethod.toast.editSuccess"), {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
        }
        catch (error) {
          console.error(error);

          this.$toast.error(this.$t("paymentMethod.toast.editError"), {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
        }
      }
      else {
        try {
          this.showAddDialog = false;
          this.card.user = "/api/users/" + this.$auth.user().id;
          await this.addCard(this.card);

          this.$toast.success(this.$t("paymentMethod.toast.addSuccess"), {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
        }
        catch (error) {
          console.error(error);

          this.$toast.error(this.$t("paymentMethod.toast.addError"), {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
        }
      }

      await this.getCards(this.$auth.user().id);
    }
  }
};
</script>

<style scoped>
.payment-table {
  margin-top: 15px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.container-menu{
  position: relative;
}

.context-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 128px;
  right: calc(50% - 128px / 2);
  background: var(--main-color);
  z-index: 10;
  top: 30px;
}
</style>