import StateControllerBase from "@/store/stateControllerBase";
import { setLoadingState } from "@/store/setLoadingState";

import DividendsController from "@/api/dividendsController";

const dividendsController = new DividendsController();

class Actions extends StateControllerBase {
  setDataLoading({ commit }, dataLoading) {
    commit("setDataLoading", dataLoading);
  }

  @setLoadingState
  async getDividends({ commit }, { page, itemsPerPage, auTokenId, status }) {
    const resp = await dividendsController.getDividends(page, itemsPerPage, auTokenId, status);
    commit("setDividends", resp.data);
    commit("setDividendsTotal", resp.totalItems);
  }

  @setLoadingState
  async addDividends(_context, params) {
    await dividendsController.addDividends(params);
  }

  @setLoadingState
  async editDividends(_context, { id, body }) {
    await dividendsController.editDividends(id, body);
  }

  @setLoadingState
  async deleteDividends(_context, id) {
    await dividendsController.deleteDividends(id);
  }

  @setLoadingState
  async getDividendsHistory({ commit }, { page, itemsPerPage, dividendsId }) {
    const resp = await dividendsController.getDividendsHistory(page, itemsPerPage, dividendsId);
    commit("setDividendsHistory", resp.data);
    commit("setDividendsHistoryTotal", resp.totalItems);
  }
}


export default (new Actions).asPlainObject();