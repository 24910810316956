<template>
  <div class="settings-wrapper">
    <AuSelect
      :value="language"
      :allow-empty="false"
      :select-label="$t('settings.language.label')"
      :options="languages"
      @input="value => setLocale(value)"
    />
    <AuSelect
      :value="defaultTokenId"
      :allow-empty="false"
      :select-label="$t('settings.defaultToken.label')"
      :options="tokensOptions"
      @input="value => setDefaultToken(value)"
    />
  </div>
</template>

<script>
import _ from "lodash";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Setting",

  computed: {
    ...mapGetters({
      language: "language",
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold",
      defaultTokenId: "defaultToken/defaultTokenId"
    }),

    tokensOptions() {
      return _.map([...this.tokensFiat, ...this.tokensGold], (token) => ({
        display: token.nameEng,
        value: token.id
      }));
    },

    languages() {
      return [
        { display: this.$t("settings.language.ru"), value: "ru" },
        { display: this.$t("settings.language.en"), value: "en" }
        // { display: this.$t("settings.language.uz"), value: "uz" }
      ];
    }
  },

  methods: {
    ...mapActions({
      setLanguage: "setLanguage",
      patchCurrentUser: "patchCurrentUser"
    }),

    setLocale(language) {
      // document.cookie = `lang=${language}; path=/`;
      // document.querySelector("html").setAttribute("lang", language);
      // localStorage.setItem("user-locale", language);
      this.setLanguage(language);
    },

    async setDefaultToken(tokenId) {
      await this.patchCurrentUser({
        userId: this.$auth.user().id,
        body: { defaultToken: `/api/au_tokens/${tokenId}` }
      });
      this.$auth.fetch();
    }
  }
};
</script>

<style scoped>
.settings-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>