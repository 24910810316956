<template>
  <div>
    <div class="cm-header">
      <span class="title">{{ header }}</span>
      <AuButton
        bordered
        center
        @click="goBack"
      >
        {{ $t('companies.form.cancel') }}
      </AuButton>
    </div>
    <div>
      <div class="cm-section">
        {{ $t('companies.form.nameLabel') }}
      </div>
      <div class="d-flex cm-paragraph">
        <div class="cm-input cm-indent">
          <AuInput
            v-model="nameRus"
            :placeholder="$t('companies.form.nameRusPlaceholder')"
            required
          />
        </div>
        <div class="cm-input">
          <AuInput
            v-model="nameEng"
            :placeholder="$t('companies.form.nameEngPlaceholder')"
          />
        </div>
      </div>
      <div class="cm-section">
        {{ $t('companies.form.descriptionLabel') }}
      </div>
      <div class="d-flex cm-paragraph">
        <div class="cm-input cm-indent">
          <AuInput
            v-model="descriptionRus"
            :placeholder="$t('companies.form.descriptionRusPlaceholder')"
            is-text-area
          />
        </div>
        <div class="cm-input">
          <AuInput
            v-model="descriptionEng"
            :placeholder="$t('companies.form.descriptionEngPlaceholder')"
            is-text-area
          />
        </div>
      </div>
      <div class="cm-section">
        {{ $t('companies.form.addressLabel') }}
      </div>
      <div class="d-flex cm-paragraph">
        <div class="cm-input cm-indent">
          <AuInput
            v-model="addressRus"
            :placeholder="$t('companies.form.addressRusPlaceholder') + '(*)'"
            required
          />
        </div>
        <div class="cm-input">
          <AuInput
            v-model="addressEng"
            :placeholder="$t('companies.form.addressEngPlaceholder')"
          />
        </div>
      </div>
      <div class="cm-section">
        {{ $t('companies.form.companyInfoLabel') }}
      </div>
      <div class="d-flex cm-paragraph">
        <div class="cm-input cm-indent">
          <AuSelect
            :value="countrySelected"
            :options="countryOptions"
            :placeholder="$t('companies.form.countryPlaceholder') + '(*)'"
            required
            @input="value => countrySelected = value"
          />
        </div>
        <div class="cm-input">
          <AuInput
            v-model="url"
            :placeholder="$t('companies.form.URLPlaceholder')"
          />
        </div>
      </div>
      <div class="cm-section">
        {{ $t('companies.form.companyReqLabel') }}
      </div>
      <div class="d-flex cm-paragraph">
        <div class="cm-input cm-indent">
          <div class="cm-newline">
            <AuInput
              v-model="innRus"
              :placeholder="$t('companies.form.INNPlaceholder')"
            />
          </div>
          <div>
            <AuInput
              v-model="kppRus"
              :placeholder="$t('companies.form.KPPPlaceholder')"
            />
          </div>
        </div>
        <div class="cm-input">
          <div class="cm-newline">
            <AuInput
              v-model="ogrnRus"
              :placeholder="$t('companies.form.OGRNPlaceholder')"
            />
          </div>
          <div>
            <AuInput
              v-model="bankRus"
              :placeholder="$t('companies.form.bankPlaceholder')"
            />
          </div>
        </div>
      </div>
      <div class="cm-section">
        {{ $t('companies.form.reqLabel') }}
      </div>
      <div class="cm-input cm-paragraph">
        <AuSwitch
          v-model="isVerifyRequest"
          :label="$t('companies.form.reqPlaceholder')"
        />
      </div>
    </div>
    <div>
      <AuButton
        bordered
        center
        active-bg-color="#279063"
        width="100%"
        @click="addOrEdit"
      >
        {{ button }}
      </AuButton>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CompanyManager",
  props: {
    id: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      nameRus: null,
      nameEng: null,
      descriptionRus: null,
      descriptionEng: null,
      addressRus: null,
      addressEng: null,
      countrySelected: null,
      url: null,
      isVerifyRequest: false,
      isVerified: false,
      innRus: null,
      ogrnRus: null,
      kppRus: null,
      bankRus: null,
      index: null
    };
  },

  async mounted() {
    if (_.isEmpty(this.country)) {
      await this.getCountry();
    }
    if (_.isEmpty(this.companies)) {
      await this.getCompanies();
    }
    if (!_.isEmpty(this.id)) {
      this.setFields(this.companies.find((item, index) => {
        if (item.id == this.id) {
          this.index = index;
          return true;
        }
      }));
    }
  },

  computed: {
    ...mapGetters({
      country: "country",
      companies: "companies"
    }),

    header() {
      return _.isEmpty(this.id) ?
        this.$t("companies.form.addCompanyLabel") :
        this.$t("companies.form.editCompanyLabel");
    },

    button() {
      return !this.isVerifyRequest ? this.$t("companies.form.saveAsDraft") : this.header;
    },

    countryOptions() {
      return _.map(this.country, c => ({ display: c.name, value: `/api/countries/${c.id}` }));
    }
  },

  methods: {
    ...mapActions({
      getCountry: "getCountry",
      getCompanies: "getCompanies",
      addCompany: "addCompany",
      editCompany: "editCompany"
    }),

    goBack() {
      this.$router.push({ name: "companies" });
    },

    addOrEdit() {
      if (_.isEmpty(this.id)) {
        this.add();
      }
      else {
        this.edit();
      }
    },

    async add() {
      const params = {
        nameRus: this.nameRus,
        nameEng: this.nameEng,
        descriptionRus: this.descriptionRus,
        descriptionEng: this.descriptionEng,
        addressRus: this.addressRus,
        addressEng: this.addressEng,
        country: this.countrySelected,
        url: this.url,
        isVerifyRequest: this.isVerifyRequest,
        innRus: this.innRus,
        ogrnRus: this.ogrnRus,
        kppRus: this.kppRus,
        bankRus: this.bankRus,
        index: null
      };
      try {
        const data = await this.addCompany(params);
        this.companies.push(data);
        this.showSuccess(this.$t("toasts.success.companyAdd"));
        setTimeout(this.goBack, 1500);
      }
      catch (e) {
        this.showError(this.$t("toasts.error.companyAdd"));
      }
    },

    setFields(data) {
      this.nameRus = data.nameRus;
      this.nameEng = data.nameEng;
      this.descriptionRus = data.descriptionRus;
      this.descriptionEng = data.descriptionEng;
      this.addressRus = data.addressRus;
      this.addressEng = data.addressEng;
      this.countrySelected = `/api/countries/${this.country.find(item => item.id == data.country.id).id}`;
      this.url = data.url;
      this.isVerifyRequest = data.isVerifyRequest;
      this.isVerified = data.isVerified;
      this.innRus = data.innRus;
      this.ogrnRus = data.ogrnRus;
      this.kppRus = data.kppRus;
      this.bankRus = data.bankRus;
    },

    async edit() {
      const params = {
        nameRus: this.nameRus,
        nameEng: this.nameEng,
        descriptionRus: this.descriptionRus,
        descriptionEng: this.descriptionEng,
        addressRus: this.addressRus,
        addressEng: this.addressEng,
        country: this.countrySelected,
        url: this.url,
        isVerifyRequest: this.isVerifyRequest,
        innRus: this.innRus,
        ogrnRus: this.ogrnRus,
        kppRus: this.kppRus,
        bankRus: this.bankRus
      };
      try {
        const data = await this.editCompany([this.id, params]);
        this.companies[this.index] = data;
        this.showSuccess(this.$t("toasts.success.companyEdit"));
        setTimeout(this.goBack, 1500);
      }
      catch (e) {
        this.showError(this.$t("toasts.error.companyAdd"));
      }
    },

    showSuccess(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    }
  }
};
</script>

<style scoped lang="scss">
.cm-header{
  display: flex;
  justify-content: space-between;
  .title{
    font-weight: 500;
    font-size: 20px;
  }
}
.cm-section{
  font-size: 14px;
  margin-bottom: 15px;
}
.cm-input{
  width: 50%;
}
.cm-indent{
  margin-right: 15px;
}
.cm-newline{
  margin-bottom: 10px;
}
.cm-paragraph{
  margin-bottom: 30px;
}
</style>