<template>
  <div class="profile__person-name fs13">
    <img
      src="/img/Profile.png"
      class="profile__person-photo"
      alt="User's photo"
    >
    {{ $route.query["username"] }}
  </div>
</template>

<script>
export default {
  name: "User"
};
</script>

<style scoped>

</style>