<template>
  <div
    v-if="!isIssuer"
    class="dashboard"
  >
    <ExchangeRates />
    <ExchangeRatesBottom />
    <div class="cards">
      <LastTransactions />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ExchangeRates from "../components/exchangeRates/top/ExchangeRates.vue";
import ExchangeRatesBottom from "../components/exchangeRates/bottom/ExchangeRates.vue";
import LastTransactions from "@/components/lastTransactions/LastTransactions";

export default {
  components: {
    LastTransactions,
    ExchangeRates,
    ExchangeRatesBottom
  },

  computed: {
    pages() {
      let parent = _.find(this.$route.matched, match => match.meta.isMain);
      if (parent) {
        return _.filter(parent.children, child => _.get(child, "meta.isHelp") == true);
      }
      else {
        return [];
      }
    },

    isIssuer() {
      return this.$auth.user().type == "issuer";
    }
  },

  mounted() {
    if (this.isIssuer) {
      this.$router.push({ name: "issuer" });
    }
  },

  methods: {
    setRoute(page) {
      this.$router.push({ name: page.name });
    }
  }
};
</script>

<style scoped lang="scss">
.manager-panel {
  margin-bottom: 30px;
}

.cards {
  display: flex;
  gap: 20px;

  @media screen and (max-width: 576px)  {
    flex-direction: column;
  }
}

.dashboard {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 768px)  {
    gap: 40px;
  }
}
</style>
