<template>
  <div>
    <AuTabView>
      <AuTabPanel :title="$t(`ethAddresses.titleAddress`)">
        <div
          v-if="!arrEth.length"
          class="create_block card"
        >
          <div class="title">
            {{ $t("ethAddresses.addAddressTitle") }}
          </div>
          <div class="subtitle">
            {{ $t("ethAddresses.ethAddress") }}
          </div>
          <div class="create_eth">
            <div class="inp_eth__wrap">
              <AuInput
                v-model="newEth"
                border-radius="8"
                class="inp_eth"
                :placeholder="$t('ethAddresses.ethAddress')"
              />

              <div
                v-if="!$filters.correctETH(newEth)"
                class="text-danger fs-12"
              >
                {{ $t("ethAddresses.ethAddressError") }}
              </div>
            </div>
            <AuButton
              v-if="!mmInstalled"
              width="175"
              height="42px"
              active-bg-color="#279063"
              hovered-bg-color="#279063aa"
            >
              <a
                class="pucket-button"
                href="https://metamask.io/download"
                target="_blank"
              >
                {{ $t("profile.connectWallet") }}
              </a>
            </AuButton>
            <AuButton
              v-if="mmInstalled && !accountAddress"
              width="175"
              height="42px"
              active-bg-color="#279063"
              @click="connectMetamaskAction"
            >
              <span
                class="pucket-button"
              >
                {{ $t("profile.connectWithWallet") }}
              </span>
            </AuButton>

            <AuButton
              v-if="mmInstalled && accountAddress"
              class="adaptive-btn"
              center
              bordered
              width="263px"
              height="44px"
              border-radius="8"
              icon="metamask"
              @click="pasteFromWallet"
            >
              {{ $t("ethAddresses.pasteFromWalletButton") }}
            </AuButton>
          </div>

          <AuButton
            class="add-button adaptive-btn"
            :disabled="!$filters.correctETH(newEth)"
            center
            bordered
            width="100%"
            height="44px"
            border-radius="8"
            @click="addEth"
          >
            {{ $t("ethAddresses.addEthButton") }}
          </AuButton>
        </div>

        <div
          v-if="arrEth.length"
          class="card"
        >
          <div class="title">
            {{ $t("ethAddresses.myAddressesTitle") }}
          </div>
          <div class="eth__list">
            <div
              v-for="item in arrEth"
              :key="item.id"
              class="eth__item"
            >
              <div class="eth__value">
                {{ item?.value }}
              </div>

              <div class="btn_block">
                <AuButton
                  v-if="!item?.verifiedAt"
                  class="adaptive-btn"
                  center
                  bordered
                  height="44px"
                  border-radius="8"
                  icon="close"
                  @click="removeEthClick(item)"
                />

                <AuButton
                  v-if="!item?.verifiedAt"
                  class="adaptive-btn"
                  :disabled="item?.verifiedAt"
                  width="177px"
                  height="44px"
                  border-radius="8"
                  icon="metamask"
                  border-color="#D17440"
                  @click="(validate = true), (currentEth = item.value)"
                >
                  {{ $t("ethAddresses.validateButton") }}
                </AuButton>

                <AuButton
                  v-else
                  class="adaptive-btn"
                  disabled
                  width="177px"
                  height="44px"
                  border-radius="8"
                  icon="metamask"
                  border-color="#2ABA7C"
                >
                  {{ $t("ethAddresses.validatedButton") }}
                </AuButton>

                <AuButton
                  v-if="!item.status"
                  class="adaptive-btn"
                  bordered
                  width="177px"
                  height="44px"
                  border-radius="8"
                  @click="addToRegistry(item)"
                >
                  {{ $t("ethAddresses.addToRegistryButton") }}
                </AuButton>

                <AuButton
                  v-else
                  class="adaptive-btn"
                  width="177px"
                  height="44px"
                  border-radius="8"
                  border-color="#2ABA7C"
                  disabled
                >
                  {{ $t("ethAddresses.addedToRegistryButton") }}
                </AuButton>
              </div>
            </div>
          </div>

          <Validate
            v-if="validate"
            :current-eth="currentEth"
            @success="getEthAddresses"
          />
        </div>
      </AuTabPanel>
      <AuTabPanel :title="`2FA`">
        <div class="card">
          <Au2FA />
        </div>
      </AuTabPanel>
      <AuTabPanel :title="$t(`ethAddresses.titlePassword`)">
        <div class="card">
          <ChangePassword />
        </div>
      </AuTabPanel>
    </AuTabView>

    <AuModal
      v-model="showModal"
      width="450px"
    >
      <template #header>
        <div class="modal_header">
          {{ $t("ethAddresses.modal.title") }}
        </div>
      </template>
      <template #body>
        <div class="modal_text_small">
          {{ `${$t("ethAddresses.modal.text")} ${currentEth}` }}?
        </div>
      </template>
      <template #footer>
        <div class="modal_button">
          <AuButton
            bordered
            center
            width="80"
            @click="removeEth"
          >
            {{ $t("ethAddresses.modal.removeEthButton") }}
          </AuButton>
          <AuButton
            bordered
            center
            width="80"
            @click="showModal = false"
          >
            {{ $t("ethAddresses.modal.resetButton") }}
          </AuButton>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import Au2FA from "@/components/extended/Au2FA.vue";
import AuButton from "@/components/extended/AuButton.vue";
import AuTabPanel from "@/components/extended/tabs/AuTabPanel.vue";
import AuTabView from "@/components/extended/tabs/AuTabView.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import MetaMask from "@/mixins/MetaMask";
import Validate from "@/panels/components/Validate.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Au2FA,
    AuButton,
    AuTabPanel,
    AuTabView,
    ChangePassword,
    Validate
  },

  mixins: [MetaMask],

  data() {
    return {
      newEth: "",
      currentEth: "",
      currentEth_id: "",
      validate: false,
      showModal: false
    };
  },

  computed: {
    ...mapGetters({
      arrEth: "ethAddresses",
      accountAddress: "accountAddress"
    }),

    isLight() {
      return this.$store.getters.isLightTheme;
    }
  },

  methods: {
    ...mapActions({
      postEthAddresses: "postEthAddresses",
      deleteEthAddresses: "deleteEthAddresses",
      getEthAddresses: "getEthAddresses",
      patchEthAddresses: "patchEthAddresses"
    }),

    async addToRegistry(item) {
      let body = {
        status: "add"
      };

      try {
        await this.patchEthAddresses({ id: item.id, body: body });
        await this.getEthAddresses();
      }
      catch (error) {
        console.error(error);
      }
    },

    pasteFromWallet() {
      this.newEth = this.accountAddress;
    },

    async addEth() {
      const id = this.$auth.user().id;
      try {
        await this.postEthAddresses({
          value: this.newEth,
          user: `/api/users/${id}`
        });
        this.$toast.success(this.$t("toasts.success.ethAdd"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
        await this.getEthAddresses();
        this.newEth = "";
      }
      catch (error) {
        console.log(error);
        this.$toast.error(this.$t("toasts.error.ethAdd"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    },

    removeEthClick(item) {
      this.currentEth = item.value;
      this.currentEth_id = item.id;
      this.showModal = true;
    },

    async removeEth() {
      this.showBuyDialog = false;
      try {
        await this.deleteEthAddresses(this.currentEth_id);
        this.$toast.success(this.$t("toasts.success.ethDelete"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
        await this.getEthAddresses();
      }
      catch (error) {
        console.log(error);
        this.$toast.error(this.$t("toasts.error.ethDelete"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
      finally {
        this.showModal = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  padding: 20px;
  border-radius: 12px;
}

.title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
}

.subtitle {
  font-size: 14px;
  margin-bottom: 15px;
}

.create_eth {
  display: flex;
  justify-content: space-between;
  gap: 15px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
}

.inp_eth {
  width: 100%;

  &__wrap {
    flex: 1 1 auto;
  }
}

.eth {
  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    @media screen and (max-width: 640px) {
      flex-direction: column;
      gap: 25px;
      margin-bottom: 50px;
    }
  }

  &__value {
    border: 1px solid #373737;
    border-radius: 8px;
    height: 44px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.btn_block {
  display: flex;
  align-items: center;
  gap: 15px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    width: 100%;
  }
}

.modal_button {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.add-button {
  margin-top: 30px;
}

.adaptive-btn {
  @media screen and (max-width: 640px) {
    width: 100% !important;
  }
}

.pucket-button {
  white-space: nowrap;
  color: #ffffff;
}
</style>
