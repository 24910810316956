<template>
  <div>
    <AuBreadcrumb />
    <div class="card pays">
      <div class="title">
        Данные о платеже
      </div>
      <div class="status">
        <span class="me-1 info">Номер платежа:</span>
        <span>{{ hash }}</span>
      </div>
      <div class="status">
        <span class="me-1 info">Сумма платежа:</span>
        <span>
          {{ sum }} USDt
        </span>
      </div>
      <div class="status">
        <span class="me-1 info">Статус платежа:</span>
        <span>ожидание оплаты</span>
      </div>
      <div class="status">
        <span class="me-1 info">Для оплаты перейдите по ссылке:</span>
        <span><a :href="log?.data?.universalUrl">перейти</a></span>
      </div>
      <div class="status">
        <span class="info">Или отсканируйте штрихкод</span>
      </div>
      <div>
        <img
          :src="log?.data?.qrcodeLink"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Pays",
  props: {
    hash: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      log: null,
      course: 0.2,
      decimals: 2
    };
  },
  computed: {
    ...mapGetters({
      pay: "pay"
    }),
    sum() {
      const value = this.pay?.value ? this.pay.value : 0;
      return (value / Math.pow(10, this.decimals)) * this.course;
    }
  },
  async mounted() {
    await this.getPay(this.hash);
    this.log = JSON.parse(this.pay?.log);
  },
  methods: {
    ...mapActions({
      getPay: "getPay"
    })
  }
};
</script>

<style scoped lang="scss">
.pays {
  padding: 20px;
  .title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .status{
     font-weight: 450;
     font-size: 16px;
   }
  .info{
    color: var(--color-60);
  }
}

</style>