<template>
  <div class="user-view">
    <div>
      <img
        src="https://top-fwz1.mail.ru/counter?id=3336008;js=na"
        style="position:absolute;left:-9999px;"
        alt="Top.Mail.Ru"
      >
    </div>
    <div class="form-and-image">
      <AuIcon
        class="img-scale"
        icon="view"
      />
      <div class="panel">
        <AuIcon
          class="logo-scale"
          icon="logo"
        />
        <div class="select">
          <div
            class="tab-select"
            :class="{ checked: isLogin }"
            @click="changeTab"
          >
            {{ $t("login.enter") }}
          </div>
          <div
            class="tab-select"
            :class="{ checked: !isLogin }"
            @click="changeTab"
          >
            {{ $t("login.register") }}
          </div>
        </div>
        <div>
          <!-- <span class="radio">
            <input
              type="radio"
              name="login"
              value="email"
              checked
              @change="isEmail=!isEmail"
            >&nbsp;E-mail
          </span>
          <span class="radio">
            <input
              type="radio"
              name="login"
              value="phone"
              @change="isEmail=!isEmail"
            >&nbsp;Телефон
          </span> -->
        </div>
        <span
          v-if="!isLogin && !allField"
          class="text-explain"
        >{{ $t("login.requiredFields") }}</span>
        <span
          v-else
          class="text-explain"
        >&nbsp;</span>
        <input
          v-if="isEmail"
          v-model="email"
          class="data-in"
          type="text"
          placeholder="E-mail *"
        >
        <div
          v-if="!isLogin"
          class="error-block-wrapper"
        >
          <div
            v-for="error of emailErrors"
            :key="error.$uid"
          >
            <p class="error-block">
              {{ error.$message }}
            </p>
            <p v-if="!v$.$errors.length" />
          </div>
        </div>
        <!-- <input
          v-else
          v-model="phone"
          class="data-in"
          type="text"
          placeholder="Телефон"
        /> -->
        <input
          id="password"
          v-model="pass"
          class="data-in"
          type="password"
          :placeholder="$t(`login.password`)"
        >
        <div
          v-if="!isLogin"
          class="error-block-wrapper"
        >
          <div
            v-for="error of passErrors"
            :key="error.$uid"
          >
            <p class="error-block">
              {{ error.$message }}
            </p>
            <p v-if="!v$.$errors.length" />
          </div>
        </div>
        <label
          v-if="is2FaEnable"
          for="code"
          class="minimal"
        >{{ $t("login.googleCode") }}</label>
        <input
          v-if="is2FaEnable"
          id="code"
          v-model="token"
          class="data-in"
          type="text"
          :placeholder="$t(`login.googleCode`)"
        >
        <input
          v-if="!isLogin"
          v-model="pass_confirm"
          class="data-in pass-confirm"
          type="password"
          :placeholder="$t(`login.passwordRepeat`)"
        >
        <div v-if="!isLogin && !isConfirm && pass.length && pass_confirm.length">
          <div>
            <p class="error-block">
              {{ $t("login.passwordDontMatch") }}
            </p>
          </div>
        </div>
        <input
          v-if="!isLogin"
          v-model="iName"
          class="data-in mt-3"
          type="text"
          :placeholder="$t(`login.fName`)"
        >
        <input
          v-if="!isLogin"
          v-model="fName"
          class="data-in"
          type="text"
          :placeholder="$t(`login.sName`)"
        >
        <!--span
          v-if="isLogin"
          class="text-remember"
        >{{ $t("login.passwordNotRemember") }}</span-->
        <button
          :disabled="progress"
          class="enter-button"
          @click="loginOrRegister"
        >
          <img
            v-if="progress"
            src="/svg/dark/progress_rolling_green.svg"
            alt="progress"
          >
          {{ buttonText }}
        </button>
      </div>
    </div>
    <div class="footer-text">
      <span>{{ $t("login.incognito") }}</span>
      <div>
        <AuIcon
          icon="vk"
          class="btn"
          is-link
          href="https://vk.com/public217319173"
          target="_blank"
        />
        <AuIcon
          icon="youtube"
          class="btn"
          is-link
          href="https://www.youtube.com/channel/UCDZECH3uoZbET29NBmG7bwg"
          target="_blank"
        />
        <AuIcon
          icon="telegram"
          class="btn"
          is-link
          href="https://t.me/AUT_Community_EN"
          target="_blank"
        />
        <AuIcon
          icon="twitter"
          class="btn"
          is-link
          href="https://twitter.com/AutCapital"
          target="_blank"
        />
        <AuIcon
          icon="dzen"
          class="btn"
          is-link
          href="https://dzen.ru/autenticapital"
          target="_blank"
        />
      </div>
      <span>Ru © {{ currentYear }}, «Autentic Universe LTD»</span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import localStorageController from "@/help/localStorageController";

import { containsLowercase, containsNumber, containsUppercase, email, minLength, requiredEmail, requiredPassword } from "@/localization/localized-validators";
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "Login",
  data() {
    return {
      v$: useVuelidate(),
      progress: false,
      passwordMismatch: "",
      isLogin: true,
      isEmail: true,
      email: "",
      phone: "",
      pass: "",
      pass_confirm: "",
      fName: "",
      iName: "",
      oName: "",
      userRef: null,
      refSource: null,
      token: "",
      is2FaEnable: false
    };
  },
  validations() {
    return {
      email: { requiredEmail, email },
      pass: {
        requiredPassword,
        containsUppercase,
        containsLowercase,
        containsNumber,
        minLength: minLength(8)
      }
    };
  },
  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),

    passErrors() {
      return _.filter(this.v$.$errors, error => error.$propertyPath == "pass");
    },

    emailErrors() {
      return _.filter(this.v$.$errors, error => error.$propertyPath == "email");
    },

    allField() {
      return (
        this.pass !== null &&
        this.pass.length >= 8 &&
        this.email !== null &&
        this.email.length >= 1 &&
        this.pass_confirm !== null &&
        this.pass_confirm.length >= 1 &&
        this.fName.length >= 1 &&
        this.iName.length >= 1
      );
    },
    currentYear() {
      return new Date().getFullYear();
    },
    isConfirm() {
      return this.pass === this.pass_confirm;
    },
    buttonText() {
      if (this.progress) {
        return "";
      }
      else {
        return this.isLogin ? `${this.$t("login.enter")}` : `${this.$t("login.register")}`;
      }
    }
  },
  watch: {
    pass() {
      this.v$.pass.$touch();
    },
    password_confirm() {
      if (this.pass === this.pass_confirm) {
        this.passwordMismatch = false;
      }
    },
    email() {
      this.v$.email.$touch();
    }
  },
  mounted() {
    this.startPixelStatistics();

    this.userRef = this.$route.query.userRef || localStorageController.getUserRef() || null;
    this.refSource = this.$route.query.refSource || localStorageController.getRefSource() || null;

    if (this.userRef) {
      localStorageController.setUserRef(this.userRef);
    }
    if (this.refSource) {
      localStorageController.setRefSource(this.refSource);
    }

    if (this.$auth.user()) {
      this.$router.push("/");
    }
    switch (this.$route.name) {
    case "register1":
      if (this.$route.params.reg === "reg") {
        this.isLogin = false;
      }
      break;
    case "register2":
      this.isLogin = false;
      if (this.$route.params.reg === undefined) {
        return;
      }
      this.email = this.getName(this.$route.params.reg, "name=");
      this.phone = this.getName(this.$route.params.reg, "tel=");
      break;
    }
  },
  methods: {
    ...mapActions({
      register: "register"
    }),

    changeTab() {
      this.isLogin = !this.isLogin;
    },
    loginOrRegister() {
      this.progress = true;
      if (this.isLogin) {
        this.logIn();
        return;
      }
      this.regIn();
    },
    logIn() {
      const data = {
        email: this.isEmail ? this.email : this.phone,
        password: this.pass
      };
      if (this.token !== "") {
        data.authCode = this.token;
      }

      this.$auth
        .login({
          data,
          staySignedIn: true,
          fetchUser: false,
          url: "/api/users/login"
        })
        .then(null, (res) => {
          this.progress = false;
          if (res?.response?.data?.errors == "2fa is enabled! Need authcode!") {
            this.is2FaEnable = true;
          }
          else {
            this.$toast.error(
              this.$t("login.message.passError"),
              {
                position: "top",
                duration: 8000,
                dismissible: true
              }
            );
          }
          console.log("ERROR", res.response);
        });

      this.$auth.remember(data.email);
    },
    async regIn() {
      if (!this.allField || !this.isConfirm) {
        this.$toast.error(
          this.$t("login.message.inputError"),
          {
            position: "top",
            duration: 8000,
            dismissible: true
          }
        );
        this.progress = false;
        return;
      }
      const form_data = {
        email: this.isEmail ? this.email : this.phone,
        password: this.pass,
        fName: this.fName,
        iName: this.iName,
        oName: this.oName,
        userRef: this.userRef,
        refSource: this.refSource
      };

      try {
        await this.register(form_data);
        this.progress = false;
        this.showSuccess();
        setTimeout(() => (this.isLogin = true), 5000);
      }
      catch (error) {
        this.progress = false;
        if (error.response.status === 400) {
          this.showError(this.$t("login.message.mailExist"));
        }
        else if (error.response.status === 422) {
          this.showError(this.$t("login.message.errorContent"));
        }
      }
    },
    showSuccess() {
      this.$toast.success(
        this.$t("login.message.registerSuccess"),
        {
          position: "top",
          duration: 8000,
          dismissible: true
        }
      );
    },
    showError(message) {
      this.$toast.error(`${message}`, {
        position: "top",
        duration: 8000,
        dismissible: true
      });
    },
    getName(value, search) {
      const nameStart = value.indexOf(search);
      const ampersandPos = value.indexOf("&");
      if (nameStart === -1) {
        return null;
      }
      const nameEnd =
        ampersandPos === -1
          ? value.length
          : ampersandPos < nameStart
            ? value.length
            : ampersandPos;
      return value.slice(nameStart + search.length, nameEnd);
    },

    startPixelStatistics() {
      let _tmr = window._tmr || (window._tmr = []);
      _tmr.push({ id: "3336008", type: "pageView", start: (new Date()).getTime() });
      (function (d, w, id) {
        if (d.getElementById(id)) {
          return;
        }
        let ts = d.createElement("script");
        ts.type = "text/javascript";
        ts.async = true;
        ts.id = id;
        ts.src = "https://top-fwz1.mail.ru/js/code.js";
        let f = function () {
          let s = d.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(ts, s);
        };
        if (w.opera == "[object Opera]") {
          d.addEventListener("DOMContentLoaded", f, false);
        }
        else {
          f();
        }
      })(document, window, "tmr-code");
    }
  }
};
</script>

<style scoped lang="scss">
.user-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--main-color);
  height: 100vh;
  width: 100vw;
}

.form-and-image {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20%;

  @media screen and (max-width: 990px) {
    margin-left: 10%;
  }

  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.footer-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  font-weight: 450;
  font-size: 12px;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  width: 350px;
  border: 1px solid var(--color-90);
  border-radius: 20px;
  padding: 20px;
  background: var(--card-color);
}

.select {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: 1px solid lightgray;
  height: 55px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.tab-select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
}

.checked {
  border-radius: 10px;
  background: var(--color-80);
}

.data-in {
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 15px;
  margin-bottom: 10px;
}

.error-block {
  display: flex;
  font-weight: 400;
  font-size: 10px;
  margin: 0 auto;
  color: red;
  justify-content: center;

  &-wrapper {
    margin-bottom: 10px;
  }
}

.text-explain {
  font-weight: 400;
  font-size: 14px;
  margin: 0 auto 10px auto;
  color: grey;
}

.text-remember {
  color: #1a43b4;
  font-weight: 450;
  font-size: 12px;
  cursor: pointer;
}

.enter-button {
  background: #2aba7c;
  border-radius: 10px;
  color: white;
  height: 55px;
  margin-top: 20px;
}

.logo-scale {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.img-scale {
  width: 400px;

  @media screen and (max-width: 990px) {
    width: 300px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.radio {
  font-size: 13px;
  margin-right: 20px;
}

button:disabled {
  background: #FFFFFF33;
}

.minimal {
  color: grey;
  font-size: 12px;
  margin-bottom: 5px;
}
</style>
