<template>
  <div
    class="indent_progress"
    :isLightTheme="isLightTheme"
  >
    <div
      class="progress-bar progress-bar_first"
      :style="`width: ${procent}%`"
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
    />
    <div
      v-if="secondProcent"
      class="progress-bar progress-bar_second"
      :style="`width: ${secondProcent}%`"
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    procent: {
      type: Number,
      default: 0
    },
    secondProcent: {
      type: Number,
      default: 0
    }
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    })
  }
};
</script>

<style lang="scss" scoped>
.progress-bar {
  height: 10px;
  background-color: #28E593;
  border-radius: 2px;

  position: absolute;

  &_second {
    background-color: #02C584;
  }

  &_first {
    z-index: 50;
  }
}

.indent_progress {
  height: 10px;
  border-radius: 2px;

  position: relative;

  &[isLightTheme=true] {
    background-color: #1B4734;
  }

  &[isLightTheme=false] {
    background-color: var(--text-color);
  }
}
</style>