<template>
  <div>
    <div class="ts-header">
      {{ $t("buyForm.third.title") }}
    </div>
    <div class="ts-divider" />
    <div class="ts-info">
      <div class="ts-info-element">
        <span class="ts-text">{{ $t("buyForm.third.type") }}</span>
        <span class="ts-bold">USD.s</span>
      </div>
      <div class="ts-info-element">
        <span class="ts-text">{{ $t("buyForm.third.count") }}</span>
        <span class="ts-bold">{{ count }}</span>
      </div>
      <div class="ts-info-element">
        <span class="ts-text">{{ $t("buyForm.third.price") }}</span>
        <span class="ts-bold">{{ sum }} USDT</span>
      </div>
    </div>
    <div class="ts-referral">
      <span class="ts-text">
        {{ $t("buyForm.third.referral") }}
      </span>
      <span class="ts-text ts-green">Zealy</span>
    </div>
    <div class="ts-more-info ts-bold">
      {{ $t("buyForm.third.withUs") }}
    </div>
    <div class="ts-next">
      <AuButton
        center
        bordered
        border-radius="10"
        width="40%"
        active-bg-color="#279063"
        @click="$emit('next')"
      >
        {{ $t("buyForm.third.next") }}
      </AuButton>
    </div>
  </div>
</template>

<script>

import BigNumber from "bignumber.js";

export default {
  name: "ThirdStage",
  props: {
    count: {
      type: Number,
      default: 0
    },
    price: {
      type: Number,
      default: 0.08
    }
  },
  computed: {
    sum() {
      return BigNumber(this.count).multipliedBy(this.price).toFixed(2);
    }
  }
};
</script>

<style scoped>
.ts-header {
  font-size: 28px;
}
.ts-divider {
  border-bottom: 3px solid green;
  width: 200px;
  margin: 20px 0;
}
.ts-info {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}
.ts-info-element {
  display: flex;
  gap: 10px;
}
.ts-referral {
  margin-bottom: 15px;
}
.ts-more-info {
  margin-bottom: 40px;
}
.ts-next {
  display: flex;
  justify-content: center;
}
.ts-text {
  font-size: 18px;
}
.ts-bold {
  font-size: 18px;
  font-weight: 600;
}
.ts-green {
  color: #279063;
}
</style>