<template>
  <div>
    <AuBreadcrumb />
    <div>
      <div class="title">
        Запуск закрытой продажи токенов AUt
      </div>
      <div class="d-flex">
        <div class="counter me-2">
          Закрытая продажа стартует через:
        </div>
        <AuCountdown
          :finish-date="start"
          :legend="legend"
        />
      </div>
    </div>
    <div class="card stage-panel mt-3">
      <div class="header">
        Получить 1 AUt за 0.2 USD
      </div>
      <div class="sub-header">
        Прогресс ICO
      </div>
      <div>
        <div class="d-flex justify-content-between">
          <div class="small-text wan">
            0
          </div>
          <div class="small-text wan">
            10 300 000 USD
          </div>
        </div>
        <ProgressBar
          procent="0"
        />
      </div>
      <div class="container">
        <div
          v-for="(stage, index) of stages"
          :key="index"
          class="brick"
        >
          <div class="header mt-5">
            {{ stage.name }}
          </div>
          <div class="text">
            <span class="wan">Цена: </span>
            <span>{{ stage.price }}</span>
          </div>
          <div class="text">
            <span class="wan">Доля от распределения: </span>
            <span>{{ stage.share }}</span>
          </div>
          <div class="text">
            <span class="wan">Максимальный обьем: </span>
            <span>{{ stage.max }}</span>
          </div>
          <div class="text">
            <span class="wan">Обьем: </span>
            <span>{{ stage.volume }}</span>
          </div>
          <div class="text">
            <span class="wan">Период вестинга: </span>
            <span>{{ stage.period }}</span>
          </div>
          <div class="mt-3 mb-4">
            <div class="d-flex justify-content-between">
              <div class="small-text wan">
                0
              </div>
              <div class="small-text wan">
                {{ stage.plan }}
              </div>
            </div>
            <ProgressBar
              procent="0"
            />
          </div>
        </div>
      </div>
      <AuButton
        border-radius="10"
        bordered
        center
        width="200px"
        active-bg-color="#279063"
        @click="sellDialog=true"
      >
        Купить AUt
      </AuButton>
    </div>
    <div class="card stage-panel mt-3">
      <div class="header">
        Цена продажи ICO
      </div>
      <div class="text">
        <span class="wan">Start time: </span>
        <span>{{ begin }}</span>
      </div>
      <div class="text">
        <span class="wan">End time: </span>
        <span>{{ end }}</span>
      </div>
      <div class="text">
        <span class="wan">Token hard cap: </span>
        <span>5 000 000 USD</span>
      </div>
      <div class="text">
        <span class="wan">Token soft cap: </span>
        <span>9 000 000 USD</span>
      </div>
      <div class="text">
        <span class="wan">Token exchange rate: </span>
        <span>0.2 USD</span>
      </div>
      <div class="text">
        <span class="wan">Accepted: </span>
        <span>USDT, AVAX, BNB, USDC, BUSD</span>
      </div>
      <div class="text">
        <span class="wan">Min. transaction amount: </span>
        <span>500 USDt</span>
      </div>
    </div>
    <AuModal
      v-model="sellDialog"
      width="700px"
    >
      <template #header>
        <span class="modal_header">
          Продажа
        </span>
      </template>
      <template #body>
        <div class="d-flex justify-content-between">
          <div class="modal_panel">
            <div>
              <span class="wan me-1">Цена:</span>
              <span>{{ course }} USDt за 1 AUt</span>
            </div>
            <div class="d-flex mt-3">
              <span class="wan me-1">Статус аккаунта:</span>
              <span class="me-1">проверено</span>
              <img
                class="check"
                src="/svg/autsales/check_mark_green.svg"
                alt=""
              >
            </div>
            <div>
              <span class="wan me-1">Ваш баланс:</span>
              <span>0 AUt</span>
            </div>
            <div>
              <span class="wan me-1">Вы можете купить:</span>
              <span>до 3000 AUt</span>
            </div>
            <div class="mt-3">
              <div class="wan">
                Введите количество
              </div>
              <AuInput v-model="autCount" />
            </div>
            <div>
              <span class="wan me-1">К оплате:</span>
              <span>{{ sum }} USDt</span>
            </div>
            <div class="wan mt-3">
              Купленные AUt будут отправлены на Ваш адрес в сети AVAXC
            </div>
            <div class="address">
              {{ address }}
            </div>
          </div>
          <div class="modal_sell">
            <div class="mb-3">
              Выберите способ оплаты:
            </div>
            <div class="mb-3">
              <input
                type="radio"
                checked
              > Binance Pay
            </div>
            <AuButton
              bordered
              border-radius="10"
              center
              active-bg-color="#279063"
              width="150px"
              @click="create"
            >
              Купить
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import moment from "moment";
import ProgressBar from "@/components/progressBar/ProgressBar";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AUtSales",
  components: { ProgressBar },
  data() {
    return {
      legend: {
        day: "Дней",
        hour: "Часов",
        minute: "Минут",
        second: "Секунд"
      },
      stages: [
        { name: "Распределение", price: "0.2 USDt", share: "5%", volume: "5000000", plan: "1000000$", max: "3000 USDt", period: "9 месяцев" },
        { name: "Приватная продажа (раунд 1)", price: "0.4 USDt", share: "9%", volume: "9000000", plan: "3600000$", max: "20000 USDt", period: "12 месяцев" },
        { name: "Приватная продажа (раунд 2)", price: "0.5 USDt", share: "10%", volume: "10000000", plan: "5000000$", max: "500000 USDt", period: "12 месяцев" },
        { name: "Открытая продажа", price: "0.7 USDt", share: "1%", volume: "100000", plan: "700000$", max: "1500 USDt", period: "без вестинга" }
      ],
      currentStage: 0,
      start: "12.31.2023",
      begin: null,
      end: null,
      sellDialog: false,
      course: 0.2,
      autCount: null
    };
  },
  computed: {
    ...mapGetters({
      ethAddresses: "ethAddresses"
    }),

    sum() {
      return BigNumber(this.autCount ? this.autCount : 0).multipliedBy(this.course).toString(10);
    },

    address() {
      return _.get(this.ethAddresses, "[0].value", "-");
    }
  },
  mounted() {
    this.begin = moment(this.start).format("DD.MM.YYYY");
    this.end = moment(this.start).add(9, "M").format("DD.MM.YYYY");
  },
  methods: {
    ...mapActions({
      payCreate: "payCreate"
    }),

    async create() {
      try {
        const params = {
          value: this.autCount,
          auToken: "/api/au_tokens/5"
        };
        const resp = await this.payCreate(params);
        this.$router.push({ name: "pays", params: { hash: resp.id } });
      }
      catch (e) {
        this.$toast.error("Ошибка! Что-то пошло не так...", {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
}
.counter {
  font-size: 18px;
  color: var(--color-50);
}
.stage-panel {
  padding: 20px;

  .header {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--scrollbar-color);
    white-space: nowrap;
    overflow: hidden;
  }

  .sub-header {
    font-weight: 400;
    font-size: 16px;
  }

  .text {
    font-size: 14px;
  }

  .small-text {
    font-size: 12px;
  }

  .wan {
    color: var(--color-50);
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .brick {
    width: 45%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
.modal_header {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 10px;
}
.modal_panel{
  padding: 10px;
  width: 49%;
  border: 1px solid var(--scrollbar-color);
  border-radius: 10px;
  .wan {
    color: var(--color-60);
  }
  .check {
    width: 20px;
    height: 20px;
  }
  .address {
    font-size: 11px;
    word-wrap: anywhere;
  }
}
.modal_sell{
  width: 49%;
  border: 1px solid var(--scrollbar-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>