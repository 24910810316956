<template>
  <div class="tabs">
    <ul
      v-if="!loading"
      class="tabs_header"
    >
      <Swiper
        class="swiper"
        :navigation="true"
        :slides-per-view="slidesPerView"
        :hash-navigation="{
          watchState: true,
        }"
        :modules="modules"
      >
        <SwiperSlide
          v-for="(title, index) in tabTitles"
          :key="index"
        >
          <li
            v-if="!hiddens[index].status"
            :class="{ selected: title == selectedTitle, tabs_error: tabErrors[index].status }"
            @click="changeTab(title, index)"
          >
            {{ title }}
          </li>
        </SwiperSlide>
      </Swiper>
    </ul>
    <div class="tabs_info">
      <slot />
    </div>
  </div>
</template>

<script>
import { Navigation } from "swiper";
import { provide, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
import "swiper/css/navigation";

export default {
  name: "AuTabView",
  components: {
    Swiper,
    SwiperSlide
  },

  setup(props, { slots }) {
    const tabTitles = ref(slots.default().map((tab) => tab.props.title));
    const tabErrors = ref(slots.default().map(tab => ({
      title: tab.props.title,
      status: tab.props.showError === undefined ? false : tab.props.showError
    })
    )).value;
    const hiddens = ref(slots.default().map(tab => ({
      title: tab.props.title,
      status: tab.props.hidden === undefined ? false : tab.props.hidden
    })
    )).value;
    const selectedTitle = ref(tabTitles.value[0]);

    provide("selectedTitle", selectedTitle);
    provide("tabErrors", tabErrors);
    provide("hiddens", hiddens);
    return {
      selectedTitle,
      tabTitles,
      tabErrors,
      hiddens,
      modules: [Navigation]
    };
  },

  data() {
    return {
      slidesPerView: 0,
      loading: true
    };
  },

  mounted() {
    let doc = document.querySelector(".tabs");
    this.slidesPerView = Math.floor((doc.offsetWidth / 200));
    this.loading = false;
  },

  methods: {
    changeTab(t, i) {
      this.selectedTitle = t;
      this.$emit("change", t);
      this.$emit("changeIndex", i);
    }
  }
};
</script>

<style scoped lang="scss">
div.swiper {
  margin-left: 0px;
  width: 100%;

  &-slide {
    padding-top: 20px;
    cursor: pointer;
    width: auto !important;
  }
}

.tabs_header {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    cursor: pointer;
    background: var(--card-color);
    color: var(--color-50);
    text-align: center;
    margin-right: 2px;
    padding: 0.625rem 1.25rem;
    border-radius: 8px 8px 0px 0px;
    transition: 0.2s ease-in-out;
    &:hover,
    &:focus {
      color: var(--text-color);
      &.tabs_error{
        color: red;
      }
    }
    &.selected {
      color: var(--text-color);
      &.tabs_error{
        color: red;
      }
    }
    &.tabs_error{
      color: #FFB7AF;
    }
  }
}
.tabs_info {
  background: var(--card-color);
  border-radius: 0px 10px 10px 10px;
}
</style>
