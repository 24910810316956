<template>
  <div>
    <AuBreadcrumb />
    <div class="card buy-panel">
      <div class="buy-header">
        {{ $t("buyForm.title") }}
      </div>
      <FirstStage
        v-if="stage===1"
        :price="price"
        @next="getCount"
      />
      <SecondStage
        v-if="stage===2"
        :count="count"
        :price="price"
        @next="stage++"
      />
      <ThirdStage
        v-if="stage===3"
        :count="count"
        :price="price"
        @next="toMainPage"
      />
    </div>
  </div>
</template>

<script>
import FirstStage from "@/components/buyForm/FirstStage";
import SecondStage from "@/components/buyForm/SecondStage";
import ThirdStage from "@/components/buyForm/ThirdStage";
export default {
  name: "BuyForm",
  components: { ThirdStage, FirstStage, SecondStage },
  data() {
    return {
      stage: 1,
      count: 0,
      price: 1
    };
  },
  methods: {
    getCount(arg) {
      this.stage++;
      this.count = arg;
    },

    toMainPage() {
      this.$router.push({ name: "base" });
    }
  }
};
</script>

<style scoped>
.buy-panel {
  padding: 20px;
}
.buy-header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 35px;
}
</style>