<template>
  <div
    class="modal_screen"
    :class="{invisible: !modelValue, modal_light: isLight, modal_dark: !isLight}"
    @mousedown="hideModal"
    @mouseenter="startAnimation=true"
  >
    <div class="dialog_wrap">
      <div
        :class="{dialog_position: startAnimation}"
        class="dialog_box"
        :style="css"
        @mouseenter="onForm=true"
        @mouseleave="onForm=false"
      >
        <header>
          <div class="close_position">
            <slot name="header" />
            <div class="close-wrapper">
              <AuIcon
                clickable
                icon="close"
                @click="closeModal"
              />
            </div>
          </div>
        </header>
        <main>
          <slot name="body" />
        </main>
        <footer>
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuDialog",
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "300px"
    },
    height: {
      type: String,
      default: "auto"
    },
    borderRadius: {
      type: Number,
      default: 10
    }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      startAnimation: false,
      onForm: false
    };
  },
  computed: {
    isLight() {
      return this.$store.getters.isLightTheme;
    },

    css() {
      return `width:${this.width};` +
          `height:${this.height};` +
          `border-radius:${this.borderRadius}px;`;
    }
  },
  methods: {
    hideModal() {
      if (!this.onForm) {
        this.closeModal();
      }
    },
    closeModal() {
      this.startAnimation = false;
      this.$emit("closeModal");
      this.$emit("update:modelValue", false);
    }
  }
};
</script>

<style scoped lang="scss">
.invisible {
  display: none;
}
.modal_screen {
  position: fixed;
  z-index: 9998;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.modal_light{
  background: rgba(0, 0, 0, 0.5);
}
.modal_dark{
  background: rgba(21, 21, 21, 0.9);
}
.dialog_box{
  border: 2px solid var(--color-70);
  background: var(--card-color);
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  transition: margin-top 150ms linear;
  overflow: auto;
}
.dialog_wrap{
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  padding-bottom: 100px;
}
.dialog_position {
  margin-top: 110px;
}
.close_position {
  display: flex;
  justify-content: space-between;
}
.close_size {
  font-size: 8px;
}

.close-wrapper {
  margin-right: 12px;
}
</style>

<style>
.vertical-buttons {
  display: flex;
  margin-top: 10px;
  gap: 15px;
}
</style>