<template>
  <div class="card ch-main">
    <div class="ch-header">
      <div class="fs 24 ch-title">
        {{ $t("auCapitals.capitalHistory.title") }}
      </div>
      <div class="mb-2 fs15">
        {{ $t("auCapitals.capitalHistory.project") }}&nbsp;
        {{ currentCapital?.tokenBase?.nameEng }}
      </div>
    </div>
    <div v-if="Object.keys(capitalsHistory).length">
      <div
        v-for="(invest, date) in capitalsHistory"
        :key="date"
      >
        <div class="ch-date">
          {{ date }}
        </div>
        <div
          v-for="item of invest"
          :key="item.createdAt"
          class="d-flex justify-content-between mb-2"
        >
          <div>
            <img
              class="ch-image"
              :src="currentCapital.tokenQuote.logoMain"
              alt=""
            >
            <span class="ch-info">{{ $filters.shortAddress(item.address) }}</span>
          </div>
          <div>
            <div class="ch-info ch-green d-flex justify-content-end">
              <AuDecimalStatic
                :value="item.value"
                :addition="currentCapital.tokenQuote.mnemonicEng"
              />
            </div>
            <div class="ch-time d-flex justify-content-end">
              {{ item.createdAt }}
            </div>
          </div>
        </div>
      </div>
      <div class="ch-footer">
        <AuButton
          height="36px"
          bordered
          center
          @click="getMore"
        >
          {{ $t("auCapitals.capitalHistory.more") }}
        </AuButton>
      </div>
    </div>
    <div
      v-else
      class="mt-2"
    >
      <p>{{ $t("auCapitals.capitalHistory.noInvestment") }}</p>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "CapitalHistory",
  props: {
    id: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      capitalsHistory: {},
      currentCapital: null,
      page: 1
    };
  },

  async mounted() {
    this.currentCapital = await this.getAuCapitals(this.id);
    await this.createCapitalHistory(this.page);
  },

  methods: {
    ...mapActions({
      getTokenEvents: "getTokenEvents",
      getAuCapitals: "capitals/getAuCapitals"
    }),

    async createCapitalHistory(page) {
      const allData = await this.getTokenEvents({ pageId: page, capitalId: this.id, type: "token_capital_buy" });
      if (_.isEmpty(allData)) {
        return 0;
      }
      allData.forEach(invest => {
        const date = moment(invest.createdAt).format("DD.MM.YYYY");
        if (!(date in this.capitalsHistory)) {
          this.capitalsHistory[date] = [];
        }
        if (invest.value != 0) {
          this.capitalsHistory[date].push({
            createdAt: moment(invest.createdAt).format("DD.MM.YYYY HH:mm:ss"),
            value: invest.value * this.currentCapital.priceBase,
            address: _.isNil(invest?.address) || _.isEmpty(invest?.address) ? "-" : invest.address
          });
        }
      });
      return 1;
    },

    async getMore() {
      this.page += await this.createCapitalHistory(this.page + 1);
    }
  }
};
</script>

<style scoped lang="scss">
.ch-main{
  padding: 20px;
}
.ch-title{
  margin-bottom: 40px;
}
.ch-header{
  border-bottom: 1px solid var(--color-70);
}
.ch-date{
  font-size: 11px;
  margin: 20px 0;
}
.ch-info{
  font-size: 14px;
  margin-left: 8px;
}
.ch-time{
  font-size: 9px;
}
.ch-green{
  color: #5ac396;
}
.ch-image{
  width: 30px;
  height: 30px;
  border-radius: 40px;
  object-fit: cover;
}
.ch-footer{
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-top: 40px;
  border-top: 1px dashed var(--color-70);
}
</style>