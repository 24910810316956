<template>
  <div>
    <AuBreadcrumb />
    <div class="card goods">
      <div class="goods-title">
        {{ $t('goods.label') }}
        <div>
          <AuButton
            bordered
            center
            width="auto"
            @click="createGood"
          >
            {{ $t('goods.addGoodButton') }}
          </AuButton>
        </div>
      </div>
      <ProgressLoader
        v-if="dataLoading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />
      <div v-else>
        <div class="goods-count">
          {{ totalItems }} ед.
        </div>

        <div class="goods-explain">
          {{ $t('goods.goodsCount') }}
        </div>

        <div>
          <AuTable
            :columns="columns"
            :items="items"
            :icons="null"
            action-type="Dropdown"
            :action-column="actionColumn"
            goods-link
            is-icon-links
          >
            <template #default="{item}">
              <div
                class="buttons"
              >
                <AuButton
                  v-if="item?.status != $t('goods.statusIssued')"
                  bordered
                  center
                  @click="editGood(item)"
                >
                  {{ $t('goods.editAction') }}
                </AuButton>
                <AuButton
                  v-if="item.count != 0"
                  bordered
                  center
                  @click="deleteGood(item)"
                >
                  {{ $t('goods.deleteAction') }}
                </AuButton>
                <AuButton
                  v-if="item.count != item.exhibited"
                  bordered
                  center
                >
                  {{ $t('goods.sendAction') }}
                </AuButton>
                <AuButton
                  v-if="item?.status != $t('goods.statusIssued') && item.count != item.exhibited"
                  bordered
                  center
                  @click="tokenize(item)"
                >
                  {{ $t('goods.tokenizeAction') }}
                </AuButton>
                <AuButton
                  v-if="showMarketplaceButton(item)"
                  bordered
                  center
                  @click="setInMarketplace(item)"
                >
                  {{ $t('goods.setInMarketplace') }}
                </AuButton>
              <!--<AuButton
                bordered
                center
              >
                <div class="btn_metamask">
                  <div
                    class="metamask"
                    @click="metamaskAddNft(item)"
                  />
                  <div @click="metamaskAddNft(item)">
                    {{ $t("tokeninfo.addToWallet") }}
                  </div>
                </div>
              </AuButton> -->
              </div>
            </template>
            <template #price="{value}">
              <div>
                <AuDecimalStatic
                  v-if="value.isInMarketplace"
                  :value="value.average"
                  :decimal="value.decimals"
                  :addition="value.mnemonic"
                />
                <span v-else>-</span>
              </div>
            </template>
          </AuTable>
        </div>
      </div>
      <div class="footer">
        <AuPagination
          v-if="totalItems > 30"
          :total-items="totalItems"
          @pageChanged="pageChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import ProgressLoader from "@/loaders/progress-bar";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "MyGoods",

  components: {
    ProgressLoader
  },

  data() {
    return {
      columns: [{ name: this.$t("goods.firstColumn"), align: "start" },
        { name: this.$t("goods.secondColumn"), align: "start" },
        { name: this.$t("goods.thirdColumn"), align: "center" },
        { name: this.$t("goods.fourthColumn"), align: "center" },
        { name: this.$t("goods.fifthColumn"), align: "center" },
        { name: this.$t("goods.sixthColumn"), align: "center" },
        { name: this.$t("goods.seventhColumn"), align: "center", slot: "price" },
        { name: this.$t("goods.eightColumn"), align: "center" },
        { name: this.$t("goods.nineColumn"), align: "center" },
        { name: "", align: "end" }],
      status: { draft: this.$t("goods.statusDraft"),
        sended: this.$t("goods.statusSended"),
        issued: this.$t("goods.statusIssued")
      },
      actionColumn: { icon: ["chevron_down", "chevron_up"] },
      page: 1,
      totalItems: 1,
      nfts: []
    };
  },

  computed: {
    ...mapGetters({
      dataLoading: "goods/dataLoading",
      tokensFiat: "tokensFiat",
      tokensGold: "tokensGold"
    }),

    items() {
      return _.map(this.nfts, nft => ({
        id: nft.id,
        name: nft.productName,
        count: nft.amount || "-",
        status: this.status[nft.status],
        remainder: nft.remainder,
        exhibited: nft.exhibited,
        price: {
          average: nft.average,
          decimals: nft?.tokenQuote?.decimals,
          mnemonic: nft?.tokenQuote?.mnemonicEng === undefined ? "" :
            ` ${nft?.tokenQuote?.mnemonicEng}`,
          isInMarketplace: nft?.isInMarketplace
        },
        multiToken: nft?.isMultiToken ? "Да" : "Нет",
        fracted: nft?.isFracted ? "Да" : "Нет"
      }));
    },

    currentUser() {
      return `/api/users/${this.$auth.user().id}`;
    }
  },

  async created() {
    await this.getNftsPage();
  },

  methods: {
    ...mapActions({
      patchNft: "goods/patchNft",
      removeNft: "goods/removeNft",
      getNfts: "goods/getCurrentUserNfts",
      sendTokenEvents: "sendTokenEvents",
      getNftOwnersById: "marketplace/getNftOwnersById"
    }),

    async pageChanged(actualPage) {
      this.page = actualPage;
      await this.getNftsPage();
    },

    async getNftsPage() {
      const resp = await this.getNfts({
        page: this.page
      });
      this.nfts = resp.data;
      for (const item of this.nfts) {
        if (item?.isMultiToken) {
          const data = await this.getOwnersData(item.id);
          item.average = data.average;
          item.exhibited = data.exhibited;
          item.remainder = BigNumber(item.amount).minus(data.exhibited).toFixed(2);
        }
        else {
          item.average = _.get(item, "price", 0);
          item.exhibited = item.isInMarketplace ? 1 : 0;
          item.remainder = item.isInMarketplace ? 0 : 1;
        }
      }
      this.totalItems = resp.totalItems;
    },

    async getOwnersData(nftId) {
      const nftOwners = await this.getNftOwnersById({ id: nftId });
      let average = BigNumber(0);
      let exhibited = BigNumber(0);
      _.forEach(nftOwners, owner => {
        exhibited = exhibited.plus(_.get(owner, "amount", 0));
        average = average.plus(exhibited.multipliedBy(_.get(owner, "marketPrice", 0)));
      });
      if (!exhibited.isEqualTo(0)) {
        average = average.dividedBy(exhibited);
      }
      return { average: average.toFixed(2), exhibited: exhibited.toFixed(2) };
    },

    createGood() {
      this.$router.push({ name: "edit good", params: { id: "new" } });
    },

    editGood(item) {
      this.$router.push({ name: "edit good", params: { id: item.id } });
    },

    showMarketplaceButton(item) {
      const nft = _.find(this.nfts, el => el.id == item.id);
      return nft?.owner == this.currentUser && !nft?.isInMarketplace;
    },

    async deleteGood(item) {
      try {
        await this.removeNft(item.id);
        this.$toast.success(this.$t("toasts.success.goodDelete"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
      catch (e) {
        this.$toast.error(this.$t("toasts.error.goodDelete"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    },

    async tokenize(item) {
      const nft = { id: item.id, status: "sended" };

      try {
        await this.patchNft(nft);
        this.$toast.success(this.$t("toasts.success.goodSend"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
      catch (e) {
        this.$toast.error(this.$t("toasts.error.goodSend"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    },

    async setInMarketplace(item) {
      const params = {
        user: this.currentUser,
        type: "token_put_on_market",
        description: "on market",
        isTask: true,
        value: this.dialogCount,
        nftNumber: item.id
      };

      try {
        await this.sendTokenEvents(params);
        this.$toast.success(this.$t("toasts.success.goodMarketplace"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
      catch (e) {
        this.$toast.error(this.$t("toasts.error.goodMarketplace"), {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}
.goods{
  padding: 20px;
  font-family: 'Suisse Intl',serif;
  font-style: normal;
}
.goods-title{
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
}
.goods-count{
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}
.goods-explain{
  font-weight: 450;
  font-size: 12px;
  color: var(--color-60);
  margin-bottom: 40px;
}
.goods-divider{
  border: 1px dashed var(--color-60);
  margin-bottom: 20px;
}
.footer {
  padding-top: 20px;
  display: flex;
  justify-content: center;

  border-top: 1px dashed var(--color-70);
}
.btn_metamask {
  display: flex;
  justify-content: center;
  align-items: center;
}
.metamask {
  background: url(../assets/img/metamask.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px;
  margin-right: 10px;
}

</style>
