<template>
  <div class="left-panel panel">
    <div class="logo-panel">
      <div class="logo">
        <a :href="path">
          <AuIcon icon="logo" />
        </a>
      </div>
      <span
        v-if="showTestnet"
        class="testnet-title"
        :dark="!isLightTheme"
      >
        Testnet
      </span>
    </div>

    <div class="left-panel__panel">
      <Menu />
    </div>
    <Briefcase />
  </div>
</template>

<script>
import _ from "lodash";

import Briefcase from "./components/Briefcase";
import Menu from "@/components/menu/Menu.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Briefcase,
    Menu
  },

  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),

    path() {
      let parent = _.find(this.$route.matched, (match) => match.meta.isMain);
      return _.get(parent, "path");
    },

    showTestnet() {
      return process.env.VUE_APP_SHOW_TESTNET == 1;
    }
  },

  methods: {
    setRoute(path) {
      this.$router.push(path);
    }
  }
};
</script>

<style scoped lang="scss">
.logo {
  margin-top: 20px;
  margin-left: 25px;
  height: 50px;
}

.logo-panel {
  width: 100%;
  height: 67px;

  border-bottom: 1px solid var(--color-90);
  display: flex;
  align-items: center;
  gap: 9px;
}

.testnet-title {
  font-size: 12px;
  color: #00000066;
  &[dark=true] {
    color: #FFFFFF80;
  }
}

.left-panel {
  width: 210px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-right: 1px solid var(--color-90);

  @media screen and (max-width: 1200px) {
    width: 170px;
  }

  &__panel {
    margin: 18px 0px 15px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 160px;

    @media screen and (max-width: 1200px) {
      width: 150px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__card {
    width: 160px;
    height: 180px;

    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    border-radius: 13px;

    &-chard {
      margin-top: 8px;
    }

    &-value {
      position: absolute;
      top: 47px;
      font-size: 15px;
    }
  }

  &__help {
    margin-bottom: 96px;
  }
}
</style>
