<template>
  <div>
    <nav class="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <RouterLink :to="parentPath">
            {{ $t(parentName) }}
          </RouterLink>
        </li>

        <AuIcon icon="breadcrumb_arrow" />

        <li
          class="breadcrumb-item active"
        >
          <a v-if="currentPageTitle">
            {{ currentPageTitle }}
          </a>
          <a v-else>
            {{ $t($route.meta.title) }}
          </a>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      type: Array,
      default: () => []
    },
    parentName: {
      type: String,
      default: "router.wallet"
    },
    parentPath: {
      type: String,
      default: "/"
    },
    currentPageTitle: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss">
.breadcrumb {
  gap: 15px;
  margin-bottom: 15px;
}

.breadcrumb-item {
  cursor: pointer;

  a {
    text-decoration: none;
    font-size: 12px;
    font-weight: 450;
    color: var(--color-50);
    &:hover {
      color: var(--color-50);
    }
  }

  &.active{
    a:not([href]) {
      text-decoration: none;
      color: var(--text-color);
      font-size: 12px;
      font-weight: 450;
      &:hover {
        color: var(--text-color);
      }
    }
  }
}
</style>