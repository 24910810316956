<template>
  <span>Exit...</span>
</template>

<script>
export default {
  name: "Logout",
  mounted() {
    this.$auth.logout();
    this.$router.push("/login");
  }
};
</script>

<style scoped>

</style>