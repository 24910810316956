<template>
  <div>
    <AuBreadcrumb />

    <ProjectFormFirstStep
      v-if="currentStep == 1"
      :id="id"
      :first-part-of-project-info="firstPartOfProject"
      @nextStep="currentStep++"
      @firstPartOfProject="addFirstPartOfProject"
    />
    <ProjectFormSecondStep
      v-if="currentStep == 2"
      :id="id"
      :show-price-redeem="showPriceRedeem"
      :tokens-count="tokensCount"
      :second-part-of-project-info="secondPartOfProject"
      @second-part-of-project-info="addSecondPartOfProject"
      @prevStep="currentStep--"
      @nextStep="currentStep++"
    />
    <ProjectFormThirdStep
      v-if="currentStep == 3"
      :id="id"
      :third-part-of-project-info="thirdPartOfProject"
      @third-part-of-project-info="addThirdPartOfProject"
      @prevStep="currentStep--"
      @send="sendForm"
    />
  </div>
</template>

<script>
import _ from "lodash";

import ProjectFormFirstStep from "@/components/issuerForm/ProjectFormFirstStep.vue";
import ProjectFormSecondStep from "@/components/issuerForm/ProjectFormSecondStep.vue";
import ProjectFormThirdStep from "@/components/issuerForm/ProjectFormThirdStep";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ProjectFormThirdStep,
    ProjectFormFirstStep,
    ProjectFormSecondStep
  },

  props: {
    id: {
      type: String,
      default: "new"
    }
  },

  data() {
    return {
      currentStep: 1,
      firstPartOfProject: null,
      secondPartOfProject: null,
      thirdPartOfProject: null,
      index: null
    };
  },

  computed: {
    ...mapGetters({
      tokens: "tokens",
      ethAddresses: "ethAddresses",
      requestsToIssue: "requestsToIssue"
    }),
    tokensCount() {
      return this.firstPartOfProject ? this.firstPartOfProject.tokensCount : "0";
    },
    showPriceRedeem() {
      return this.firstPartOfProject.investType == "redeemable";
    }
  },

  async mounted() {
    if (_.isEmpty(this.requestsToIssue)) {
      await this.getRequestsToIssue();
    }
    if (this.id !== "new") {
      this.requestsToIssue.find((item, i) => {
        if (item.id == this.id) {
          this.index = i;
          return true;
        }
      });
    }
  },

  methods: {
    ...mapActions({
      getRequestsToIssue: "getRequestsToIssue",
      setRequestToIssue: "setRequestToIssue",
      editRequestToIssue: "editRequestToIssue",
      uploadFile: "files/uploadFile"
    }),
    addFirstPartOfProject(value) {
      this.firstPartOfProject = value;
    },
    addSecondPartOfProject(value) {
      this.secondPartOfProject = value;
    },
    addThirdPartOfProject(value) {
      this.thirdPartOfProject = value;
    },

    addOrEdit() {
      if (this.id === "new") {
        this.sendForm();
      }
      else {
        this.editForm();
      }
    },

    async sendForm() {
      let uploadImage = await this.sendFile(this.thirdPartOfProject.image);
      let iconImage = await this.sendFile(this.thirdPartOfProject.iconImage);
      let uploadDocs = await this.sendDocs();
      let requestToIssue = {
        user: `/api/users/${this.$auth.user().id}`,
        tokenNameEng: this.firstPartOfProject.projectNameEng,
        tokenMnemonicEng: this.firstPartOfProject.projectAbbrNameEng,
        descriptionCapitalRus: this.firstPartOfProject.projectDescriptionRus,
        descriptionCapitalEng: this.firstPartOfProject.projectDescriptionEng,
        tokenCount: this.firstPartOfProject.tokensCount,
        company: this.firstPartOfProject.company,
        cryptoActiveType: this.firstPartOfProject.cryptoActiveType,
        dateStart: this.formatDate(this.secondPartOfProject.dateStart),
        dateEnd: this.formatDate(this.secondPartOfProject.dateEnd),
        descriptionToken: this.secondPartOfProject.description,
        section: this.secondPartOfProject.section,
        priceBase: this.secondPartOfProject.priceBase,
        tokenQuote: this.secondPartOfProject.tokenQuote,
        quote: this.secondPartOfProject.quote,
        expectedProfit: this.secondPartOfProject.expectedProfit,
        minCap: parseInt(this.secondPartOfProject.minCap).toString(),
        maxCap: parseInt(this.secondPartOfProject.maxCap).toString(),
        logicType: "asset",
        isForCapitall: true,
        ethAddress: !_.isEmpty(this.ethAddresses) ? `/api/eth_addresses/${this.ethAddresses[0].id}` : "/api/eth_addresses/1"
      };
      if (Object.keys(this.firstPartOfProject).includes("investType")) {
        requestToIssue.investType = this.firstPartOfProject.investType;
      }
      if (Object.keys(this.firstPartOfProject).includes("isVoiting")) {
        requestToIssue.isVoiting = this.firstPartOfProject.isVoiting;
      }
      if (Object.keys(this.secondPartOfProject).includes("priceRedeem")) {
        requestToIssue.priceRedeem = this.secondPartOfProject.priceRedeem;
        requestToIssue.dateClose = this.secondPartOfProject.dateClose;
      }
      if (uploadImage !== null) {
        requestToIssue.mainImage = `/api/uploaded_files/${uploadImage.id}`;
      }
      if (iconImage !== null) {
        requestToIssue.icon = `/api/uploaded_files/${iconImage.id}`;
      }
      if (uploadDocs !== null) {
        requestToIssue.additionalFile = uploadDocs;
      }
      try {
        await this.setRequestToIssue(requestToIssue);
        this.showSuccess(this.$t("toasts.success.emissionSend"));
        this.$router.push({ name: "my projects" });
      }
      catch (error) {
        this.showError(this.$t("toasts.error.emissionSend"));
      }
    },

    async editForm() {
      let params = {
        user: `/api/users/${this.$auth.user().id}`,
        tokenNameEng: this.firstPartOfProject.projectNameEng,
        tokenMnemonicEng: this.firstPartOfProject.projectAbbrNameEng,
        descriptionCapitalRus: this.firstPartOfProject.projectDescriptionRus,
        descriptionCapitalEng: this.firstPartOfProject.projectDescriptionEng,
        tokenCount: this.firstPartOfProject.tokensCount,
        company: this.firstPartOfProject.company,
        cryptoActiveType: this.firstPartOfProject.cryptoActiveType,
        dateStart: this.secondPartOfProject.dateStart,
        dateEnd: this.secondPartOfProject.dateEnd,
        descriptionToken: this.secondPartOfProject.description,
        section: this.secondPartOfProject.section,
        priceBase: this.secondPartOfProject.priceBase,
        tokenQuote: this.secondPartOfProject.tokenQuote,
        quote: this.secondPartOfProject.quote,
        expectedProfit: this.secondPartOfProject.expectedProfit,
        minCap: parseInt(this.secondPartOfProject.minCap).toString(),
        maxCap: parseInt(this.secondPartOfProject.maxCap).toString(),
        logicType: "asset",
        isForCapitall: true,
        ethAddress: !_.isEmpty(this.ethAddresses) ? `/api/eth_addresses/${this.ethAddresses[0].id}` : "/api/eth_addresses/1"
      };
      if (Object.keys(this.firstPartOfProject).includes("investType")) {
        params.investType = this.firstPartOfProject.investType;
      }
      if (Object.keys(this.firstPartOfProject).includes("isVoiting")) {
        params.isVoiting = this.firstPartOfProject.isVoiting;
      }
      if (Object.keys(this.secondPartOfProject).includes("priceRedeem")) {
        params.priceRedeem = this.secondPartOfProject.priceRedeem;
        params.dateClose = this.secondPartOfProject.dateClose;
      }
      try {
        const resp = await this.editRequestToIssue([this.id, params]);
        this.requestsToIssue[this.index] = resp;
        this.showSuccess(this.$t("toasts.success.emissionEdit"));
        this.$router.push({ name: "my projects" });
      }
      catch (error) {
        this.showError(this.$t("toasts.error.emissionEdit"));
      }
    },

    formatDate(date) {
      return date.toISOString();
    },

    async sendFile(file) {
      let upload = null;
      try {
        upload = await this.uploadFile(file);
      }
      catch (e) {
        this.showError(this.$t("toasts.error.emissionFile"));
      }
      return upload;
    },

    async sendDocs() {
      let ret = [];
      for (const doc of this.thirdPartOfProject.fileList) {
        const upload = await this.sendFile(doc);
        if (upload === null) {
          return null;
        }
        ret.push(`/api/uploaded_files/${upload.id}`);
      }
      return ret;
    },

    showSuccess(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    }
  }

};
</script>

<style>

</style>