export default {
  state: {
    app: null,
    notify: null,
    capital: null,
    reconnectTime: null,
    websocketMessage: null,
    investTokenCount: null,
    capitalSendAfterConnect: null,
    notifySendAfterConnect: null
  },

  getters: {
    app: state => {
      return state.app;
    },

    notify: state => {
      return state.notify;
    },

    capital: state => {
      return state.capital;
    },

    reconnectTime: state => {
      return state.reconnectTime;
    },

    websocketMessage: state => {
      return state.websocketMessage;
    },

    capitalSendAfterConnect: state => {
      return state.capitalSendAfterConnect;
    },

    notifySendAfterConnect: state => {
      return state.notifySendAfterConnect;
    },

    investTokenCount: state => {
      return state.investTokenCount;
    }
  },

  mutations: {
    setApp(state, app) {
      state.app = app;
    },

    setNotify(state, notify) {
      state.notify = notify;
    },

    setCapital(state, capital) {
      state.capital = capital;
    },

    setReconnectTime(state, reconnectTime) {
      state.reconnectTime = reconnectTime;
    },

    setWebsocketMessage(state, websocketMessage) {
      state.websocketMessage = websocketMessage;
    },

    setCapitalSendAfterConnect(state, capitalSendAfterConnect) {
      state.capitalSendAfterConnect = capitalSendAfterConnect;
    },

    setNotifySendAfterConnect(state, notifySendAfterConnect) {
      state.notifySendAfterConnect = notifySendAfterConnect;
    },

    setInvestTokenCount(state, investTokenCount) {
      state.investTokenCount = investTokenCount;
    }
  },

  actions: {
    setWebsocketMessage({ commit }, websocketMessage) {
      commit("setWebsocketMessage", websocketMessage);
    },

    setInvestTokenCount({ commit }, investTokenCount) {
      commit("setInvestTokenCount", investTokenCount);
    },

    sendCapital(context, arrayOfId) {
      const socket = context.getters.capital;
      if (socket) {
        socket.send(JSON.stringify({ type: "client" }));
        socket.send(JSON.stringify({ capital: arrayOfId }));
        context.commit("setCapitalSendAfterConnect", null);
      }
      else {
        context.commit("setCapitalSendAfterConnect", arrayOfId);
      }
    },

    sendNotify(context, token) {
      const socket = context.getters.notify;
      if (socket) {
        socket.send(JSON.stringify({ apiToken: token }));
        context.commit("setNotifySendAfterConnect", null);
      }
      else {
        context.commit("setNotifySendAfterConnect", token);
      }
    },

    initializeWebsocket(context, { app: app, reconnectTime: reconnectTime }) {
      context.commit("setApp", app);
      context.commit("setReconnectTime", reconnectTime);
      context.dispatch("initializeNotify");
      context.dispatch("initializeCapital");
    },

    initializeNotify(context) {
      const socket = new WebSocket(`${process.env.VUE_APP_NOTIFY}/message`);
      context.commit("setNotify", socket);
      socket.onopen = (event) => context.dispatch("notifyOpen", event);
      socket.onmessage = (event) => context.dispatch("notifyMessage", event.data);
      socket.onclose = (event) => context.dispatch("notifyClose", event);
    },

    initializeCapital(context) {
      const socket = new WebSocket(`${process.env.VUE_APP_NOTIFY}/capital`);
      context.commit("setCapital", socket);
      socket.onopen = (event) => context.dispatch("capitalOpen", event);
      socket.onmessage = (event) => context.dispatch("capitalMessage", event.data);
      socket.onclose = (event) => context.dispatch("capitalClose", event);
    },

    notifyOpen(context) {
      const notifySendAfterConnect = context.getters.notifySendAfterConnect;
      if (notifySendAfterConnect) {
        context.dispatch("sendNotify", notifySendAfterConnect);
      }
    },

    notifyMessage(context, message) {
      if (message?.body) {
        context.getters.app.config.globalProperties.$toast.success(message.body, {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
        context.commit("setWebsocketMessage", message?.body);
      }
    },

    notifyClose(context) {
      context.commit("setNotify", null);
      const reconnectTime = context.getters.reconnectTime;
      setTimeout(() => context.dispatch("initializeNotify"), reconnectTime);
    },

    capitalOpen(context) {
      const capitalSendAfterConnect = context.getters.capitalSendAfterConnect;
      if (capitalSendAfterConnect) {
        context.dispatch("sendCapital", capitalSendAfterConnect);
      }
    },

    capitalMessage(context, message) {
      let id = message?.info === undefined ? "" : message.info.auCapital;
      id = id.slice(id.lastIndexOf("/") + 1);
      const msg = id === "" ? "Совершен инвестиционный вклад" :
        `Совершен инвестиционный вклад в капитал с id - ${id}`;
      context.getters.app.config.globalProperties.$toast.success(msg, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
      context.commit("setInvestTokenCount", message?.info);
    },

    capitalClose(context) {
      context.commit("setCapital", null);
      const reconnectTime = context.getters.reconnectTime;
      setTimeout(() => context.dispatch("initializeCapital"), reconnectTime);
    }
  }
};