import axios from "axios";

export default class axiosClient {
  async getAllAuCapitals(params) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    try {
      const resp = await axios.get("api/au_capitals", { headers, params });
      const data = resp.data["hydra:member"];
      const totalItems = resp.data["hydra:totalItems"];
      return { data, totalItems };
    }
    catch (error) {
      console.log(error);
    }
  }

  async getSearchedAuCapitals(search) {
    const params = {
      page: 1,
      itemsPerPage: 30,
      description: search
    };
    const resp = await axios.get("api/au_capitals/search", { params });
    return resp.data;
  }

  async getAuCapitals(id) {
    const resp = await axios.get(`api/au_capitals/${id}`);
    return resp.data;
  }

  async getAuCapitalsBySectorId(sectionId) {
    const params = {
      page: 1,
      itemsPerPage: 4,
      sectionId: sectionId
    };

    const resp = await axios.get("api/au_capitals", { params });
    return resp.data;
  }

  async getCapitalInvestor(params) {
    const headers = {
      accept: "application/ld+json",
      "Content-Type": "application/ld+json"
    };
    const resp = await axios.get("api/au_capital_investors", {
      headers: headers,
      params: params
    });
    const data = resp.data["hydra:member"];
    const totalItems = resp.data["hydra:totalItems"];
    return { data, totalItems };
  }
}