<template>
  <div class="card heading">
    <div>
      <div class="h-line">
        <div class="h-head-text">
          {{ $t("dashboard.products.label") }}
        </div>
        <RouterLink
          to="/wallet/my-goods"
          class="h-head-action"
        >
          {{ $t("dashboard.products.goTo") }}
          <AuIcon icon="chevron_right" />
        </RouterLink>
      </div>
      <div class="h-head-divider" />
    </div>
    <div
      v-for="(item, index) in items"
      :key="index"
    >
      <div class="h-line">
        <div
          class="h-body-text"
          @click="goToMarket(item.id)"
        >
          {{ item.name }}
        </div>
        <div class="d-flex">
          <div class="h-body-action">
            {{ item.value }}
          </div>
          <!-- <div class="h-chart">
            <Apexchart
              :series="series[index]"
              :options="options"
              width="100"
              height="20"
            />
          </div> -->
        </div>
      </div>
      <div class="h-body-divider" />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Heading",
  data() {
    return {
      page: 1,
      greenChart: [{ data: [0, 3, 0, 2, 3, 8, 4], color: "#2ABA7C" }],
      redChart: [{ data: [0, 3, 0, 2, 3, 8, 1], color: "#E94545" }],
      options: {
        chart: {
          type: "line",
          zoom: { enabled: false },
          toolbar: { show: false },
          sparkline: { enabled: true }
        },
        stroke: { curve: "smooth", width: 3 },
        tooltip: { enabled: false },
        yaxis: {
          labels: { show: false },
          max: 9,
          min: -1
        },
        xaxis: {
          labels: { show: false }
        }
      }
    };
  },

  computed: {
    ...mapGetters({
      nfts: "goods/nfts"
    }),

    items() {
      return _.map(this.nfts, nft => ({
        id: nft.id,
        name: nft.productName,
        value: nft.amount || "-"
      })).slice(0, 10);
    },

    series() {
      return [this.greenChart, this.greenChart, this.redChart, this.greenChart, this.greenChart];
    }
  },

  methods: {
    goToMarket(id) {
      this.$router.push({ name: "marketplace card", params: { id: id } });
    }
  }
};
</script>

<style scoped lang="scss">
.heading{
  font-family: 'Suisse Intl',serif;
  font-style: normal;
  padding-bottom: 25px;
}
.h-line{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px 20px 20px;
}
.h-head-text{
  font-weight: 450;
  font-size: 15px;
}
.h-body-text{
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
.h-head-action
{
  font-weight: 450;
  font-size: 12px;
  text-decoration: none;
  color: var(--color-60);
}
.h-body-action{
  font-weight: 400;
  font-size: 14px;
  color: var(--color-60);
  margin-right: 15px;
}
.h-head-divider{
  border-bottom: 1px solid var(--color-70);
  margin-left: 20px;
  margin-right: 20px;
}
.h-body-divider{
  border-bottom: 1px dashed var(--color-70);
  margin-left: 20px;
  margin-right: 20px;
}
.h-chart{
  position: relative;
  bottom: 10px;
}
</style>
