<template>
  <div>
    <EthAddresses />
  </div>
</template>

<script>
import EthAddresses from "@/panels/components/EthAddresses.vue";
export default {
  name: "Security",
  components: {
    EthAddresses
  }
};
</script>

<style scoped></style>
