<template>
  <div class="card">
    <div class="image-card">
      <div class="line image-card__type">
        <div class="rating">
          <AuIcon icon="star_gold" />
          <div class="rating__value">
            {{ 5 }}
          </div>
        </div>

        <div class="type">
          {{ product.type }}
        </div>
      </div>

      <div
        class="image-card__image"
        :style="style"
        :alt="product.name"
      />

      <div class="image-card__name">
        {{ product.name }}
      </div>

      <div class="image-card__date">
        <div
          class="image-card__date-text fs11"
        >
          Размещение на листинге:
        </div>
        <div
          class="image-card__date-value fs11"
        >
          {{ product.date }}
        </div>
      </div>
    </div>

    <div class="parameters">
      <div
        v-if="product.isMultiToken"
        class="price"
      >
        Продажи в {{ product.mnemonic }}
      </div>
      <div
        v-else
        class="price"
      >
        <AuDecimalStatic
          :value="product.price"
          :decimal="product.decimals"
          :indent="product.indent"
          :addition="product.mnemonic"
        />
      </div>
      <div class="line">
        <div class="left_text">
          Продавец
        </div>
        <div class="right_text">
          {{ product.owner }}
        </div>
      </div>
      <div class="line">
        <div class="left_text">
          Страна
        </div>
        <div class="right_text">
          {{ product.countryName }}
        </div>
      </div>
      <div class="line">
        <div class="left_text">
          Остаток товара
        </div>
        <div class="right_text">
          {{ product.amount }} шт.
        </div>
      </div>
    </div>

    <AuButton
      bordered
      width="100%"
      center
      @click="showCard"
    >
      Узнать больше
    </AuButton>

    <AuButton
      bordered
      width="100%"
      center
      active-bg-color="#279063"
      @click="showDialog=true"
    >
      Купить
    </AuButton>
    <AuModal
      v-model="showDialog"
      width="400px"
    >
      <template #header>
        <div class="modal_header">
          Покупка продукта
        </div>
      </template>
      <template #body>
        <div class="modal_text_small">
          Вы собираетесь приобрести продукт: {{ product.name }}
        </div>
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80"
              @click="buyNft"
            >
              Купить
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80"
              @click="showDialog=false"
            >
              Отмена
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import moment from "moment";

import { mapActions } from "vuex";

export default {
  name: "MarketCard",
  props: {
    product: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      showDialog: false
    };
  },

  computed: {
    style() {
      return {
        "--url": `url(${this.product.image})`
      };
    }
  },

  methods: {
    ...mapActions({
      sendTokenEvents: "sendTokenEvents"
    }),

    showCard() {
      this.$router.push({ name: "marketplace card", params: { id: this.product.id } });
    },

    showSuccess(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    async buyNft() {
      this.showDialog = false;
      const params = {
        user: `api/users/${this.$auth.user().id}`,
        createdAt: moment().format("DD.MM.YYYY"),
        type: "token_nft_buy",
        description: "buy",
        value: "1",
        address: "",
        isTask: true,
        nftNumber: this.product.id
      };

      try {
        await this.sendTokenEvents(params);
        this.showSuccess("Распоряжение на покупку NFT товара отправлено!");
      }
      catch (e) {
        this.showError("Ошибка при совершении покупки!");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.card{
  border: 1px solid var(--color-90);
  width: 375px;
  padding: 15px;
  position: relative;
  background-color: var(--card-color);
  gap: 10px
}

.type {
  background: #FFFFFF;
  border-radius: 13px;
  color: #080808;
  font-size: 11px;
  padding: 10px 15px;
}

.line{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.parameters {
  margin-bottom: 10px;
}

.rating {
  display: flex;
  align-items: center;
  gap: 5px;

  &__value {
    color: #FFFFFF;
    font-size: 11px
  }
}

.grey-text {
  color: var(--color-60)
}

.date {
  position: absolute;
  left: 25px;
  bottom: 10px;
}

.price
{
  font-weight: 600;
  font-size: 20px;
  color: #28E593;
  margin-top: 7px;
  margin-bottom: 10px;
}

.left_text
{
  font-size: 12px;
  color: var(--color-60);
}

.right_text{
  font-size: 12px;
  color: var(--text-color);
}

.image-card
{
  position: relative;

  &__type {
    position: absolute;
    left: 15px;
    top: 10px;
    width: 90%;;
  }

  &__image {
    width: 345px;
    height: 180px;
    border-radius: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: var(--url)
  }

  &__name {
    color: var(--text-color);
    left: 15px;
    bottom: 30px;
  }

  &__date {
    left: 15px;
    bottom: 12px;
    display: flex;
    gap: 10px;

    &-text {
      color: var(--color-60);
    }

    &-value {
      color: var(--text-color);
    }
  }
}
.modal_button {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.modal_button_margin {
  margin-right: 10px;
}
.modal_header{
  margin-bottom: 10px;
  border-bottom: 1px solid var(--color-70);
}
.modal_text_small {
  font-size: 12px;
}
</style>
