<template>
  <div class="panel">
    <nav class="navbar top-panel menu navbar-expand-lg">
      <div class="container-fluid">
        <div class="buttons-container">
          <div class="buttons-container-sub">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Переключатель навигации"
            >
              <AuIcon
                class="navbar-toggler__icon-close"
                icon="close"
              />
              <AuIcon
                class="navbar-toggler__icon-toggler"
                icon="toggler"
              />
            </button>
          </div>

          <div>
            Меню
          </div>
        </div>

        <div
          id="navbarSupportedContent"
          class="collapse navbar-collapse"
        >
          <div class="navbar-nav">
            <div class="sections">
              <RouterLink
                v-for="section in allSections"
                :key="section.name"
                :to="section.route"
                class="section"
                @click="hideMobileMenu"
              >
                <AuIcon :icon="section.icon" />

                <div>
                  <div class="section__title">
                    {{ section.title }}
                  </div>

                  <div class="section__sub-title">
                    {{ section.subTitle }}
                  </div>
                </div>
              </RouterLink>
            </div>

            <div>
              <div class="sections">
                <RouterLink
                  v-for="(page, index) in pages"
                  :key="index"
                  :to="page.path"
                  class="section"
                  @click="hideMobileMenu"
                >
                  <AuIcon :icon="page.meta.icon" />

                  <div class="section__title">
                    {{ $t(page.meta.title) }}
                  </div>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>

        <AuButton
          v-if="!mmInstalled"
          width="175"
          height="40px"
          :border-radius="12"
          active-bg-color="#279063"
          hovered-bg-color="#279063aa"
        >
          <a
            class="a_link"
            href="https://metamask.io/download"
            target="_blank"
          >
            {{ $t("profile.connectWallet") }}
          </a>
        </AuButton>
      </div>
    </nav>
  </div>
</template>

<script>
import _ from "lodash";
import MetaMask from "@/mixins/MetaMask";

export default {
  mixins: [MetaMask],

  computed: {
    allSections() {
      return [
        {
          title: this.$t("router.wallet"),
          subTitle: this.$t("router.walletSubTite"),
          icon: "grid_round_green",
          name: "wallet",
          route: "/wallet"
        },
        {
          title: this.$t("router.capital"),
          subTitle: this.$t("router.capitalSubTite"),
          icon: "graph_green",
          name: "autentic capital",
          route: "/autentic-capital"
        },
        {
          title: this.$t("router.market"),
          subTitle: this.$t("router.marketSubTite"),
          icon: "bag-green",
          name: "autentic market",
          route: "/autentic-market"
        }
      ];
    },

    path() {
      let parent = _.find(this.$route.matched, match => match.meta.isMain);
      return _.get(parent, "path");
    },

    title() {
      let parent = _.find(this.$route.matched, (match) => match.meta.isMain);
      return _.get(parent, "meta.title");
    },

    pages() {
      let parent = _.find(this.$route.matched, match => match.meta.isMain);
      if (parent) {
        return _.filter(parent.children, child => _.some(_.get(child, "meta.access"), acess => acess == this.$auth.user().type));
      }
      else {
        return [];
      }
    }
  },

  methods: {
    hideMobileMenu() {
      document.getElementsByClassName("navbar-toggler")[0].setAttribute("aria-expanded", false);
      document.getElementById("navbarSupportedContent").classList.remove("show");
    }
  }
};
</script>

<style scoped lang="scss">
.panel {
  display: none;

  @media screen and (max-width: 640px) {
    display: block;
  }
}

.buttons-container {
  display: flex;
  align-items: center;
  gap: 25px;
  margin: -19px 0px;
  padding: 19px 25px 19px 0px;

  &-sub {
    margin: -19px 0px;
    padding: 19px 13px 19px 0px;
    border-right: 1px solid var(--color-70);

    // &:has(>button:not(.collapsed)) { для Лёхи
    //   +img {
    //     background: red;
    //   }
    // }
  }
}

.a_link {
  color: #000000;
}

.nav-item {
  white-space: nowrap;
  text-decoration: none;
  color: var(--text-color);
}

.container-fluid {
  padding: 14px 25px 14px 13px;
}

.navbar {
  width: 100%;
  min-height: 69px;
  padding: 0px;
  z-index: 2000;
  background: var(--card-color);
  border-top: 1px solid var(--color-70);
  position: static;

  div.profile-item {
    position: absolute;
    right: 15px;
  }

  &-toggler {
    border: none !important;
    box-shadow: none !important;

    &__icon-close {
      display: none;
    }

    &__icon-toggler {
      display: none;
    }
  }

  &-toggler[aria-expanded=true] {
    .navbar-toggler__icon-close {
      display: block;
    }
  }

  &-toggler[aria-expanded=false] {
    .navbar-toggler__icon-toggler {
      display: block;
    }
  }

  &-collapse {
    position: absolute;
    top: 69px;
    left: 0px;
    width: 100%;
    height: calc(100% - 138px);
    background: var(--main-color);
    border-bottom: 1px solid var(--color-90);
    z-index: 10;
  }

  &-nav {
    width: auto;
    height: 100%;
    padding-bottom: 100px;
    margin-left: 20px;
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.top-panel__logo {
  font-weight: bold;
  font-size: 16px;
}

.section {
  display: flex;
  gap: 10px;
  align-items: start;

  &__title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 2px;
    text-decoration: none;
    color: var(--text-color)
  }

  &__sub-title {
    font-size: 12px;
    line-height: 12px;
    color: var(--color-50)
  }
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.collapsing {
  overflow: hidden;
  transition: height 0s ease;
}

.router-link-active {
  .section__title {
    color: #279063;
  }
}
</style>
