<template>
  <div class="page-wrapper">
    <div
      class="main-panel"
    >
      <ProgressLoader
        v-if="loading"
        mode="indeterminate"
        color="#5AC396"
        size="150px"
      />
      <div
        v-else
        class="project-page"
      >
        <AuBreadcrumb
          parent-path="/autentic-capital/projects"
          parent-name="router.capital"
          :current-page-title="project.name"
        />
        <div class="project-page-wrapper">
          <div class="card project-card">
            <div class="project-cover">
              <img
                class="project-image"
                :src="project.icon"
                :alt="project.name"
              >
              <div class="info">
                <div
                  class="status"
                  :style="style"
                >
                  <AuIcon :icon="project.statusIcon" /> {{ project.status }}
                </div>

                <div class="tag">
                  {{ project.tag }}
                </div>
              </div>
            </div>

            <div class="project-header">
              <h1>
                {{ project.name }}
              </h1>
              <AuButton
                class="invest-button"
                bordered
                width="153px"
                height="52px"
                center
                active-bg-color="#279063"
                :disabled="project.status !== 'Live'"
                @click="showHide"
              >
                {{ $t("auCapitals.projectCard.buttonInvest") }}
              </AuButton>
            </div>

            <div class="project-info-short">
              <div class="line">
                <div class="link-item">
                  <AuIcon icon="common_stok" />
                  <span>
                    {{ $t("auCapitals.projectCard.commonStock") }}
                  </span>
                </div>
                <div class="link-item">
                  <img
                    src="/img/project-card/binance-icon.svg"
                    alt="binance"
                  >
                  <span>
                    Binance
                  </span>
                </div>
                <a
                  href="#"
                  class="link-item link"
                >
                  <AuIcon icon="ABCD" />

                  <span>
                    ABCD
                  </span>
                </a>
              </div>
              <div class="line second">
                <div class="link-item">
                  <img
                    src="/img/project-card/price-icon.svg"
                    alt="price"
                  >
                  <span>
                    {{ $t("auCapitals.projectCard.priceToken") }} —
                  </span>
                  <span class="value">
                    $ {{ project.priceBase }}
                  </span>
                </div>
                <div class="link-item">
                  <img
                    src="/img/project-card/min-size-icon.svg"
                    alt="min-size"
                  >
                  <span>
                    {{ $t("auCapitals.projectCard.minimumSize") }} —
                  </span>
                  <span class="value">
                    $ 10,000
                  </span>
                </div>
              </div>
            </div>

            <div class="sep-line" />

            <div class="project-progress">
              <div class="above-info">
                <div
                  :style="softStyle"
                  class="info-wrapper soft"
                >
                  <span class="label">
                    {{ $t("auCapitals.projectCard.softCap") }}:
                  </span>
                  <span class="value">
                    <AuDecimalStatic
                      :value="project.minCap"
                      :addition="project.mnemonicEng"
                    />
                  </span>
                </div>
              </div>
              <ProgressBar
                class="project-progressbar"
                :procent="project.progressProcent"
                :second-procent="getPercentage(project.minCap, project.maxCap)"
              />
              <div class="under-info">
                <div class="info-wrapper raised">
                  <span class="label">
                    {{ $t("auCapitals.projectCard.amountRaised") }}:
                  </span>
                  <span class="value">
                    <AuDecimalStatic
                      :value="project.currentCap"
                      :addition="project.mnemonicEng"
                    />
                  </span>
                </div>
                <div class="info-wrapper hard">
                  <span class="label">
                    {{ $t("auCapitals.projectCard.hardCap") }}:
                  </span>
                  <span class="value">
                    <AuDecimalStatic
                      :value="project.maxCap"
                      :addition="project.mnemonicEng"
                    />
                  </span>
                </div>
              </div>
            </div>

            <div class="grouped-block">
              <div
                v-if="project.minCap > project.currentCap"
                class="project-notification"
              >
                <div class="content">
                  <img
                    src="/img/project-card/warning-icon.svg"
                    alt="warning"
                  >
                  <span>
                    {{ $t("auCapitals.projectCard.note") }}
                  </span>
                </div>
              </div>

              <div class="project-raised">
                <h3 class="title">
                  {{ $t("auCapitals.projectCard.percentage") }}
                </h3>
                <div class="values">
                  <div class="value">
                    <span class="label">
                      {{ $t("auCapitals.projectCard.softCap") }}
                    </span>
                    <span
                      class="amount"
                      :success="getPercentage(project.currentCap, project.minCap) == 100"
                    >
                      {{ getPercentage(project.currentCap, project.minCap) }} %
                    </span>
                  </div>
                  <div class="value">
                    <span class="label">
                      {{ $t("auCapitals.projectCard.hardCap") }}
                    </span>
                    <span
                      class="amount"
                      :success="getPercentage(project.currentCap, project.maxCap) == 100"
                    >
                      {{ getPercentage(project.currentCap, project.maxCap) }}  %
                    </span>
                  </div>
                </div>
              </div>

              <div
                class="project-description"
                v-html="project.descriptionCapital"
              />
            </div>

            <div class="sep-line" />

            <div class="project-info-detailed">
              <h3 class="title">
                {{ $t("auCapitals.projectCard.information.information") }}
              </h3>
              <div class="info-table">
                <div class="table-body">
                  <div class="table-column">
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.country") }}
                      </div>
                      <div class="sep" />
                      <div class="value">
                        {{ project.country || "" }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.offering") }}
                      </div>
                      <div class="sep" />
                      <div class="value yes">
                        {{ $t("auCapitals.projectCard.information.privateOffering") }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.SPV") }}
                      </div>
                      <div class="sep" />
                      <div class="value">
                        {{ project.company || "" }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.dividends") }}
                      </div>
                      <div class="sep" />
                      <div class="value yes">
                        {{ $t("auCapitals.projectCard.information.yes") }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.revenueShare") }}
                      </div>
                      <div class="sep" />
                      <div class="value yes">
                        {{ $t("auCapitals.projectCard.information.yes") }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.lockupPeriod") }}
                      </div>
                      <div class="sep" />
                      <div class="value yes">
                        {{ $t("auCapitals.projectCard.information.yes") }}
                      </div>
                    </div>
                  </div>
                  <div class="table-column">
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.date") }}
                      </div>
                      <div class="sep" />
                      <div class="value">
                        Янв, 31, 2023 - Мар 31, 2023
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.equity") }}
                      </div>
                      <div class="sep" />
                      <div class="value yes">
                        {{ $t("auCapitals.projectCard.information.yes") }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.redemption") }}
                      </div>
                      <div class="sep" />
                      <div class="value no">
                        {{ $t("auCapitals.projectCard.information.no") }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.votingRight") }}
                      </div>
                      <div class="sep" />
                      <div class="value no">
                        {{ $t("auCapitals.projectCard.information.no") }}
                      </div>
                    </div>
                    <div class="column-line">
                      <a
                        href="#"
                        class="label link"
                      >
                        {{ $t("auCapitals.projectCard.information.specialConditions") }}
                      </a>
                      <div class="sep" />
                      <div class="value no">
                        {{ $t("auCapitals.projectCard.information.no") }}
                      </div>
                    </div>
                    <div class="column-line">
                      <div class="label">
                        {{ $t("auCapitals.projectCard.information.lockupDuration") }}
                      </div>
                      <div class="sep" />
                      <div class="value no">
                        6&nbsp;{{ $t("auCapitals.projectCard.information.month") }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table-footer">
                  <div class="column-line">
                    <div class="label">
                      {{ $t("auCapitals.projectCard.information.projectAnnual") }}
                    </div>
                    <div class="sep" />
                    <div class="value">
                      16%
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="sep-line" />

            <div class="project-documents">
              <h3 class="title">
                {{ $t("auCapitals.projectCard.documents.documents") }}
              </h3>

              <div class="project-documents-list">
                <div
                  v-for="(file, index) in project.files"
                  :key="index"
                  class="document"
                >
                  <a
                    :href="file.name"
                    target="_blank"
                  >
                    <img
                      src="/img/project-card/file-icon.svg"
                      alt="file-icon"
                    >
                    <span>
                      {{ file.nameOriginal }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="similar-projects">
            <h1>
              {{ $t("auCapitals.projectCard.similarProjects") }}
            </h1>
            <div
              v-for="proj in similarProjects"
              :key="proj.id"
              class="card similar-project"
            >
              <img
                class="similar-project__image"
                :src="proj.icon"
                :alt="proj.tokenBase.nameEng"
              >

              <div class="similar-project__info">
                <span class="fs18">
                  {{ proj.tokenBase.nameEng }}
                </span>

                <div class="flex fs12">
                  <span>
                    {{ $t("auCapitals.projectCard.from") }} <AuDecimalStatic
                      :value="proj?.priceBase"
                      :addition="proj?.tokenQuote?.mnemonicEng"
                    />
                  </span>

                  <span class="profitability">
                    {{ $t("auCapitals.projectCard.profitability") }}: 40%
                  </span>
                </div>
              </div>

              <div>
                <AuButton
                  bordered
                  center
                  height="39px"
                  @click="showCard(proj.id)"
                >
                  <div class="similar-project__button-text">
                    {{ $t("auCapitals.projectCard.more") }}
                  </div>
                </AuButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BigNumber from "bignumber.js";
import MetaMask from "@/mixins/MetaMask";
import moment from "moment";
import ProgressBar from "@/components/progressBar/ProgressBar";
import ProgressLoader from "@/loaders/progress-bar";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProjectCard",

  components: {
    ProgressBar,
    ProgressLoader
  },

  mixins: [MetaMask],

  data() {
    return {
      project: {},
      showDialog: false,
      tokenCount: "0",
      descriptionMode: 1,
      correctToken: {},
      loading: true,
      similarProjects: [],
      issue: null
    };
  },

  computed: {
    ...mapGetters({
      sectionCapital: "sectionCapital",
      auOwners: "auOwners",
      uloadFile: "files/uploadedFile",
      language: "language",
      investTokenCount: "investTokenCount"
    }),

    softStyle() {
      return {
        "margin-left": this.getPercentage(this.project.minCap, this.project.maxCap) + "%"
      };
    },

    owner() {
      return this.auOwners.find(item => item.auToken.id == this.project.tokenQuoteId);
    },

    isEnough() {
      let count = BigNumber(this.tokenCount);
      count = count.multipliedBy(this.project.priceBase);
      if (this.owner === undefined) {
        return false;
      }
      return count.isLessThanOrEqualTo(this.owner.amountFree);
    },

    isCorrectNumber() {
      return this.tokenCount.match(/^\d+$/);
    },

    link() {
      return `${process.env.VUE_APP_SCAN_BASE_URL}token/`;
    },

    style() {
      return {
        "--status-color": this.project.statusColor
      };
    }
  },

  watch: {
    investTokenCount(newValue) {
      if (newValue) {
        let id = _.get(newValue, "auCapital", "");
        id = id.slice(id.lastIndexOf("/") + 1);
        if (id == this.project.id) {
          let count = BigNumber(newValue.value);
          count = count.multipliedBy(this.project.priceBase).multipliedBy(100);
          count = count.dividedBy(this.project.maxCap);
          this.project.progressProcent += count.toNumber();
          this.setInvestTokenCount(null);
        }
      }
    }

    // loading: {
    //   immediate: true,
    //   handler(value) {
    //     if (!value) {
    //       this.$nextTick(() => {
    //         let progress = document.querySelector(".progress-bar.bg-success");
    //         console.log(progress);
    //       });
    //     }
    //   }
    // }
  },

  async mounted() {
    await this.getSectionCapital();
    await this.getCapital();
    await this.getSimilarProjects();
    this.issue = await this.getRequestToIssue({ page: 1, itemsPerPage: 30 });

    // if (process.env.VUE_APP_USE_WEBSOCKET !== "false") {
    //   await this.sendCapital([this.$route.params.id]);
    // }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getSectionCapital: "getSectionCapital",
      getAuCapitals: "capitals/getAuCapitals",
      getAuCapitalsBySectorId: "capitals/getAuCapitalsBySectorId",
      sendTokenEvents: "sendTokenEvents",
      getUploadedFile: "files/getUploadedFile",
      sendCapital: "sendCapital",
      setInvestTokenCount: "setInvestTokenCount",
      getRequestToIssue: "getHydraRequestToIssue"
    }),

    showCard(id) {
      this.$router.push({ name: "project card", params: { id: id } });
    },

    async getSimilarProjects() {
      const similarProjects = await this.getAuCapitalsBySectorId(this.project.section);

      this.similarProjects = _.chain(similarProjects).reject({ id: this.project.id }).take(3).value();

      for (let index = 0; index < this.similarProjects.length; index++) {
        this.similarProjects[index].icon = await this.getImgByIri(this.similarProjects[index].mainImage);
      }
    },

    getPercentage(dividend, divider) {
      const percentage = dividend / divider * 100;
      if (percentage >= 100) {
        return 100;
      }

      return Math.round(percentage);
    },

    async getCapital() {
      try {
        const item = await this.getAuCapitals(this.$route.params.id);
        // console.log(item);
        this.project.id = item.id;
        this.project.section = this.getId(item.section);
        this.project.tag = this.getSectionName(item.section);
        this.project.name = item.tokenBase.nameEng;
        this.project.icon = item.mainImage?.name;
        this.project.dateStart = moment(item.dateStart).format("DD.MM.YYYY");
        this.project.dateEnd = moment(item.dateEnd).format("DD.MM.YYYY");
        this.project.dateClose = "dateClose" in item ? moment(item.dateClose).format("DD.MM.YYYY") : "-";
        this.project.profitability = _.get(item, "expectedProfit", "-");
        this.project.payments = item.id % 2 !== 0 ?
          this.$t("auCapitals.projects.quarterly") : this.$t("auCapitals.projects.monthly");
        this.project.maxCap = item.maxCap;
        let percent = BigNumber(item.currentCap);
        percent = percent.multipliedBy(100);
        percent = percent.dividedBy(item.maxCap);
        this.project.progressProcent = percent.toNumber();
        this.project.listedAt = moment(item.listedAt).format("DD.MM.YYYY");
        this.project.decimals = item.tokenQuote.decimals;
        this.project.mnemonicEng = item.tokenQuote.mnemonicEng;
        this.project.tokenQuoteId = item.tokenQuote.id;
        this.project.priceBase = item.priceBase;
        this.project.country = item.country;
        this.project.company = item.company?.nameEng;
        this.project.files = item.additionalFile;
        this.project.minCap = item.minCap;
        this.project.currentCap = item.currentCap;
        this.project.quote = _.get(item, "quote", "-");
        this.project.descriptionCapital = this.language === "ru" ?
          _.get(item, "descriptionRus", "-") : _.get(item, "descriptionEng", "-");
        this.project.descriptionToken = this.project.descriptionCapital;
        this.correctToken = item.tokenBase;
        this.correctToken.logoMain = this.project.icon;
        if (moment(item.dateStart).isAfter(new Date)) {
          this.project.status = "Waiting";
          this.project.statusColor = "rgba(25, 37, 54, 1)";
          this.project.statusIcon = "clock";
        }
        else if ("isClosed" in item) {
          this.project.statusColor = "rgba(25, 54, 42, 1)";
          this.project.statusIcon = "rocketc";
          this.project.status = item.isClosed ? "Closed" : "Live";
          if (!item.isClosed && (percent >= 100 || moment(item.dateEnd).isBefore(new Date))) {
            this.project.status = "Finish";
          }
        }
      }
      catch (e) {
        console.log("error", e);
      }
    },

    getId(value) {
      if (value === null || value === undefined) {
        return null;
      }
      const slashPos = value.lastIndexOf("/") + 1;
      if (slashPos === 0) {
        return null;
      }
      return value.slice(slashPos);
    },

    getSectionName(value) {
      const id = this.getId(value);
      const res = this.sectionCapital.find(item => item.id == id);
      if (res === undefined) {
        return null;
      }
      return res.name;
    },

    showHide() {
      this.showDialog = !this.showDialog;
    },

    showSuccess() {
      this.$toast.success(this.$t("auCapitals.capitalCard.success"), {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    async investCapital() {
      this.showDialog = false;
      const params = {
        user: `/api/users/${this.$auth.user().id}`,
        createdAt: moment(),
        type: "token_capital_buy",
        description: "buy",
        value: this.tokenCount,
        address: "",
        isTask: true,
        auCapital: `api/au_capitals/${this.project.id}`
      };
      try {
        await this.sendTokenEvents(params);
        this.setInvestTokenCount(params);
        this.showSuccess();
      }
      catch (e) {
        this.showError(this.$t("auCapitals.capitalCard.error"));
      }
    },

    goToHistory() {
      this.$router.push({ name: "capital history", params: { id: this.project.id } });
    },

    goToDividendsPay() {
      this.$router.push({ name: "dividends pay", params: { id: this.correctToken.id } });
    },

    async getImgByIri(iri) {
      const fileId = iri.slice(iri.lastIndexOf("/") + 1);
      await this.getUploadedFile(fileId);
      return this.uloadFile.name;
    }
  }
};
</script>

<style lang="scss">
button.btn.invest-button {
  div.btn__text {
    color: white;
  }
}
.project-page {
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.project-progress {
  .indent_progress {
    height: 10px;
    border-radius: 2px;

    &[isLightTheme=true] {
      background-color: #28E59340;
    }

    &[isLightTheme=false] {
      background-color: #28E59340;
    }
  }
}
</style>

<style lang="scss" scoped>
.project {
  &-page {
    &-wrapper {
      display: flex;
      gap: 25px;

      @media screen and (max-width:900px) {
        flex-direction: column;
      }

      .project-card {
        max-width: 840px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 25px;

        .sep-line {
          width: 100%;
          height: 3px;
          background-color: var(--sep-line-color);
        }

        .grouped-block {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
        .project {
          &-cover {
            position: relative;
            img {
              width: 100%;
              max-width: 800px;
              max-height: 400px;
              border-radius: 15px;
            }
            .info {
              position: absolute;
              top: 15px;
              right: 15px;
              display: flex;
              gap: 10px;
              .status {
                display: flex;
                gap: 5px;
                color: white;
                padding: 12px;
                background-color: rgba(40, 149, 102, 0.6);
                border-radius: 12px;
              }
              .tag {
                background-color: white;
                color: black;
                padding: 12px;
                border-radius: 12px;

              }
            }
          }

          &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 576px) {
              flex-direction: column;
              gap: 20px;
              align-items: baseline;
              .invest-button {
                width: 100%;
              }
            }

            h1 {
              color: var(--text-color);
              font-size: 24px;
            }
          }

          &-info {
            &-short {
              display: flex;
              flex-direction: column;
              gap: 15px;

              @media screen and (max-width:1320px) {
                .second {
                  flex-direction: column;
                }
              }

              .line {
                display: flex;
                gap: 15px;
                .link-item {
                  color: var(--text-color);
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  &.link {
                    color: #2ABA7C;
                    cursor: pointer;
                  }
                  .value {
                    color: #2ABA7C;
                  }
                }
              }
            }
            &-detailed {
              display: flex;
              flex-direction: column;
              gap: 15px;
              .title {
                color: var(--text-color);
                font-size: 16px;
                font-weight: 500;
              }
              .info-table {
                display: flex;
                flex-direction: column;
                gap: 8px;
                .table-body {
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  gap: 25px;

                  @media screen and (max-width:576px) {
                    grid-template-columns: 1fr;
                    gap: 12px;
                  }

                  .table-column {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    .column-line {
                      display: flex;
                      gap: 10px;
                      align-items: flex-end;
                      width: 100%;
                      .label {
                        text-wrap: nowrap;
                        font-size: 12px;
                        color: var(--color-50);
                        &.link {
                          color: #279063;
                          cursor: pointer;
                        }
                      }
                      .sep {
                        height: 2px;
                        flex: 1;
                        border-bottom: 1px dashed var(--color-50);
                        margin-bottom: 4px;
                        opacity: 0.5;
                      }
                      .value {
                        font-size: 12px;
                        color: var(--text-color);
                        text-wrap: nowrap;
                        text-align: end;

                        &.yes {
                          display: flex;
                          align-items: center;
                          gap: 4px;
                          &::after {
                            content: url("/public/img/project-card/success-mark.svg");
                            position: relative;
                            bottom: -2px;
                          }
                        }
                        &.no {
                          display: flex;
                          align-items: center;
                          gap: 4px;
                          &::after {
                            content: url("/public/img/project-card/no-mark.svg");
                            position: relative;
                            bottom: -2px;
                          }
                        }
                      }
                    }
                  }
                }
                .table-footer {
                  .column-line {
                    display: flex;
                    gap: 10px;
                    align-items: flex-end;
                    width: 100%;
                    .label {
                      text-wrap: nowrap;
                      font-size: 12px;
                      color: var(--color-50);
                      &:has(>img) {
                        display: flex;
                        gap: 3px;
                        align-items: center;
                        color: #279063;
                      }
                    }
                    .sep {
                      height: 2px;
                      flex: 1;
                      border-bottom: 1px dashed var(--color-50);
                      margin-bottom: 4px;
                      opacity: 0.5;
                    }
                    .value {
                      font-size: 12px;
                      color: var(--text-color);
                      text-wrap: nowrap;
                      padding: 3px 8px;
                      background-color: #2ABA7C15;
                      border-radius: 5px;
                    }
                  }
                }

              }
            }
          }

          &-progress {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: -12px;
            .under-info {
              display: flex;
              justify-content: space-between;
            }
            .info-wrapper {
              display: flex;
              flex-direction: column;
              gap: 5px;
              width: fit-content;
              .label {
                font-size: 12px;
                color: var(--color-50);
              }
              .value {
                font-size: 15px;
                color: var(--text-color);
              }

              &.soft {
                padding-left: 5px;

                .label {
                  text-align: center;
                }
              }
              &.raised {
                .label {
                  text-align: start;
                }
              }
              &.hard {
                .label {
                  text-align: end;
                }
              }
            }
          }

          &-notification {
            padding: 15px;
            background-color: var(--sep-line-color);
            color: var(--text-color);
            .content {
              display: flex;
              align-items: center;
              gap: 10.5px;
              img {
                width: 34px;
                height: 34px;
              }
              span {
                font-weight: 500;
                font-size: 13px;
              }
            }
          }

          &-raised {
            display: flex;
            align-items: baseline;
            gap: 50px;
            color: var(--text-color);

            @media screen and (max-width:1320px) {
              flex-direction: column;
              gap: 10px;
            }

            .title {
              color: var(--text-color);
              font-size: 16px;
              font-weight: 500;
            }
            .values {
              display: flex;
              gap: 15px;
              .value {
                display: flex;
                align-items: center;

                gap: 5px;
                .label {
                  font-weight: 500;
                }
                .amount {
                  padding: 12px 15px;
                  border: 1px solid var(--color-50);
                  border-radius: 10px;
                  font-weight: bold;
                  &[success=true] {
                    color: #2ABA7C;
                  }
                }
              }
            }
          }

          &-description {
            font-weight: bold;
            font-size: 12px;
            color: var(--color-50);
            p {
              margin: 0;
            }
          }

          &-documents {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .title {
              color: var(--text-color);
              font-size: 16px;
              font-weight: 500;
            }
            &-list {
              display: flex;
              flex-wrap: wrap;
              gap: 15px;
              .document {
                padding: 15px;
                justify-content: flex-start;
                align-items: center;
                width: 255px;
                border: 1px solid var(--color-50);
                border-radius: 10px;

                @media screen and (max-width:576px) {
                  width: auto;
                }

                a {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  text-decoration: none;
                  span {
                    color: var(--text-color);
                    span {
                      font-weight: bold;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .similar-projects {

        h1 {
          font-size: 24px;
          margin-bottom: 20px;;
        }

        .similar-project {
          padding: 20px;
          max-width: 425px;

          margin-bottom: 20px;

          @media screen and (max-width:900px) {
            max-width: fit-content;
          }

          &__image {
            @media screen and (max-width:900px) {
              width: 100%;
            }
          }

          &__button-text  {
            font-size: 11px;
          }

          &__info {
            display: flex;
            flex-direction: column;

            gap: 12px;

            margin: 20px 0px;

            color: var(--text-color);

            .profitability{
              color: var(--color-50);

              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>