<template>
  <div>
    <div
      v-if="!is2FaEnable"
      class="title"
    >
      2FA {{ $t("twoFA.title2faNoActive") }}
    </div>
    <div
      v-if="!is2FaEnable"
      class="panel"
    >
      <img
        v-if="imgURL"
        class="twofa-img"
        :src="imgURL"
        alt="2FA"
      >
      <span class="text-explain">{{ $t("twoFA.descriptionOne") }}</span>
      <input
        v-if="!is2FaEnable"
        v-model="authCode"
        class="data-in"
        type="text"
        :placeholder="$t(`twoFA.enterGoogleCode`)"
      >

      <AuButton
        :disabled="!codeLength"
        class="add-btn"
        bordered
        center
        border-radius="10"
        @click="toEnable2Fa"
      >
        <img
          v-if="progress"
          src="/svg/dark/progress_rolling_green.svg"
          alt="progress"
        >
        {{ $t("twoFA.on") }}
      </AuButton>
    </div>
    <div
      v-if="is2FaEnable"
      class="title"
    >
      2FA {{ $t("twoFA.title2faActive") }}
    </div>
    <div
      v-if="is2FaEnable"
      class="panel"
    >
      <span class="text-explain">{{ $t("twoFA.descriptionTwo") }}</span>
      <input
        v-model="authCode"
        class="data-in"
        type="text"
        :placeholder="$t(`twoFA.enterGoogleCode`)"
      >
      <AuButton
        class="add-btn"
        bordered
        center
        border-radius="10"
        @click="toDisable2Fa"
      >
        <img
          v-if="progress"
          src="/svg/dark/progress_rolling_green.svg"
          alt="progress"
        >
        {{ $t("twoFA.off") }}
      </AuButton>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Au2FA",

  data() {
    return {
      progress: false,
      is2FaEnable: false,
      token2fa: "",
      imgURL: "",
      authCode: "",
      mTitle: ""
    };
  },
  mounted() {
    if (!this.$auth.user().is2faEnabled) {
      this.get2FaQr();
    }
    this.is2FaEnable = this.$auth.user().is2faEnabled;
  },
  computed: {
    ...mapGetters({
      isLightTheme: "isLightTheme"
    }),
    codeLength() {
      return this.authCode.length == 6;
    }
  },

  methods: {
    ...mapActions({
      get2Fa: "get2Fa",
      postEnable2Fa: "postEnable2Fa",
      postDisable2Fa: "postDisable2Fa"
    }),

    async get2FaQr() {
      try {
        let resp = await this.get2Fa(this.$auth.user().id);
        if (resp.status == "200") {
          this.token2fa = resp.data.token2fa;

          QRCode.toDataURL(resp.data.url)
            .then((url) => {
              this.imgURL = url;
            })
            .catch((error) => {
              console.log(error);
            });
        }
        else {
          console.log("Error:", resp.response.data.detail);
        }
      }
      catch (error) {
        console.log(error);
      }
    },

    async toEnable2Fa() {
      this.progress = true;
      const params = {
        is2faEnabled: true,
        token2fa: this.token2fa,
        authCode: this.authCode
      };
      try {
        let resp = await this.postEnable2Fa({ params: params });
        if (resp.status == "202") {
          this.$toast.success("2FA is on", {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
          await this.$auth.fetch();
          setTimeout(() => {
            this.progress = false;
            this.is2FaEnable = this.$auth.user().is2faEnabled;
            this.authCode = "";
          }, 2000);
        }
        else {
          this.progress = false;
          console.log("Error:", resp.response.data.detail);
          this.$toast.error("Error", {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
        }
      }
      catch (error) {
        this.progress = false;
        console.log(error);
        this.$toast.error("Error", {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    },

    async toDisable2Fa() {
      this.progress = true;
      const params = {
        is2faEnabled: false,
        authCode: this.authCode
      };
      try {
        let resp = await this.postDisable2Fa({ params: params });
        if (resp.status == "202") {
          this.$toast.success("2FA is off", {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
          await this.$auth.fetch();
          setTimeout(() => {
            this.progress = false;
            this.is2FaEnable = this.$auth.user().is2faEnabled;
            this.authCode = "";
            if (!this.$auth.user().is2faEnabled) {
              this.get2FaQr();
            }
          }, 2000);
        }
        else {
          this.progress = false;
          console.log("Error:", resp.response.data.errors);
          this.$toast.error(`Error <br> ${resp.response.data.errors}`, {
            position: "bottom-right",
            duration: 4000,
            dismissible: true
          });
        }
      }
      catch (error) {
        this.progress = false;
        console.log(error);
        this.$toast.error("Error", {
          position: "bottom-right",
          duration: 4000,
          dismissible: true
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 30px;
  justify-content: start;
  text-align: left;
  align-items: left;
}
.panel {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 350px;
  border: 1px solid var(--color-70);
  border-radius: 20px;
  padding: 20px;
  background: var(--card-color);
}
.data-in {
  border-radius: 10px;
  border: 1px solid var(--color-70);
  padding: 15px;
  margin-bottom: 10px;
}

.twofa-img {
  margin: 0 auto;
  max-width: 200px;
}

.text-explain {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
  color: var(--color-50);
}
</style>
