<template>
  <div>
    <AuBreadcrumb />

    <AuButton
      bordered
      center
      @click="$router.push({ name: 'blockdex' })"
    >
      {{ $t("briefcase.buy") }}
    </AuButton>

    <AuTabView @changeIndex="changePanel">
      <AuTabPanel :title="$t('briefcase.fiatTabLabel')">
        <AuTable
          class="briefcase-table"
          :columns="columns"
          :items="fiat"
          :action-column="actionColumn"
          action-type="contextMenu"
          :loading="loading"
          @send="send"
          @topUp="topUp"
        >
          <template #company="{ value }">
            <div
              class="link"
              @click="toLink(value.link)"
            >
              <img
                :src="value.icon"
                class="icon-with-bg"
                alt=""
              >
              {{ value.name }}
            </div>
          </template>
          <template #tokensCount="{ value }">
            <div class="split-balance">
              <AuDecimalStatic
                :value="value.crypto"
                :addition="value.mnemonic"
                :decimal="value.decimals"
                :indent="value.indent"
              />
              <div
                v-if="value.spot != '0'"
                class="spot-balance"
              >
                Спот:
                <AuDecimalStatic
                  :value="value.spot"
                  :addition="value.mnemonic"
                  :decimal="value.decimals"
                />
              </div>
            </div>
          </template>
          <template #tokenSum="{ value }">
            <AuDecimalStatic
              :value="value.value"
              :decimal="value.decimals"
              :indent="value.indent"
            />
          </template>
        </AuTable>
        <AuPagination
          v-if="fiatTotalItems > itemsPerPage"
          :total-items="fiatTotalItems"
          @pageChanged="pageChanged"
        />
      </AuTabPanel>
      <AuTabPanel :title="$t('briefcase.cryptoTabLabel')">
        <AuTable
          class="briefcase-table"
          :columns="columns"
          :items="crypto"
          :action-column="actionColumn"
          action-type="contextMenu"
          :loading="loading"
          @send="send"
          @topUp="topUp"
        >
          <template #company="{ value }">
            <div
              class="link"
              @click="toLink(value.link)"
            >
              <img
                :src="value.icon"
                class="icon-with-bg"
                alt=""
              >
              {{ value.name }}
            </div>
          </template>
        </Autable>
        <AuPagination
          v-if="cryptoTotalItems > itemsPerPage"
          :total-items="cryptoTotalItems"
          @pageChanged="pageChanged"
        />
      </AuTabPanel>
      <AuTabPanel :title="$t('briefcase.investTabLabel')">
        <AuTable
          class="briefcase-table"
          :columns="columns"
          :items="invest"
          :action-column="actionColumn"
          action-type="contextMenu"
          :loading="loading"
          @send="send"
          @topUp="topUp"
        >
          <template #company="{ value }">
            <div
              class="link"
              @click="toLink(value.link)"
            >
              <img
                :src="value.icon"
                class="icon-with-bg"
                alt=""
              >
              {{ `${value.name} (${value.mnemonic})` }}
            </div>
          </template>
        </Autable>
        <AuPagination
          v-if="investTotalItems > itemsPerPage"
          :total-items="investTotalItems"
          @pageChanged="pageChanged"
        />
      </AuTabPanel>
    </AuTabView>

    <div class="briefcase-accordion">
      <AuAccordion
        v-for="(owner, index) in edditedOwners"
        :key="owner"
        :last-child="(index == edditedOwners.length - 1)"
      >
        <template #title>
          <span class="company-header">{{ (index + 1) }}</span>
        </template>
        <template #content>
          <div class="company-info container-fluid">
            <div class="row">
              <div class="col company-info-title">
                <span>
                  {{ $t("briefcase.firstColumn") }}
                </span>
              </div>
              <div class="col">
                <img
                  class="company-icon"
                  :src="companyIcons[index]"
                  :alt="owner.companyName"
                >
                <span>{{ owner.companyName }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col company-info-title">
                <span>
                  {{ $t("briefcase.secondColumn") }}
                </span>
              </div>
              <div class="col">
                <span>{{ owner.tokensCount }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col company-info-title">
                <span>
                  {{ $t("briefcase.thirdColumn") }}
                </span>
              </div>
              <div class="col">
                <span>{{ owner.tockenCost }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <AuButton
                  width="100%"
                  center
                  @click="send(index)"
                >
                  {{ $t("briefcase.sendAction") }}
                </AuButton>
              </div>
            </div>
          </div>
        </template>
      </AuAccordion>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import AuTabPanel from "@/components/extended/tabs/AuTabPanel";
import AuTabView from "@/components/extended/tabs/AuTabView";
import BigNumber from "bignumber.js";
import CurrencySupport from "@/help/currencySupport";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { AuTabPanel, AuTabView },
  data() {
    return {
      actionColumn: { label: "···", icon: "more" },
      currencySup: new CurrencySupport(),
      currentPanel: 0,
      fiatPage: 1,
      cryptoPage: 1,
      investPage: 1,
      itemsPerPage: 30,
      fiatOwners: null,
      fiatTotalItems: 0,
      cryptoOwners: null,
      cryptoTotalItems: 0,
      investOwners: null,
      investTotalItems: 0,
      defaultToken: null,
      loading: false
    };
  },

  computed: {
    ...mapGetters({
      tokens: "tokens",
      exchangeBalances: "exchangeBalances",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      defaultTokenId: "defaultToken/defaultTokenId"
    }),

    columns() {
      return [{ name: this.$t("briefcase.firstColumn"), align: "start", slot: "company" },
        { name: this.$t("briefcase.secondColumn"), slot: "tokensCount" },
        { name: `${this.$t("briefcase.thirdColumn")} (${this.defaultTokenMnemonic})` },
        { name: `${this.$t("briefcase.sumColumn")} (${this.defaultTokenMnemonic})`, slot: "tokenSum" }];
    },

    fiat() {
      let ret = [];
      _.forEach(this.fiatOwners, auOwner => {
        const count = BigNumber(auOwner.amount).dividedBy(BigNumber(10).pow(auOwner.auToken.decimals));
        let currency = BigNumber(this.currencySup.getCurrency(auOwner.auToken.id, this.defaultTokenMnemonic));
        let spotBalance = _.get(_.find(this.exchangeBalances, { token: `/api/au_tokens/${auOwner.auToken.id}` }), "amount", "0");
        let sum = count.multipliedBy(currency).plus(spotBalance);

        currency = currency ? currency.toFixed(this.defaultToken.indent).replace(",", ".") : "0";
        sum = sum ? sum.toString().replace(",", ".") : "0";
        ret.push({
          company: {
            icon: auOwner.auToken.logoMain || "/svg/companies/" + _.get(auOwner, "icon", "s_logo") + ".svg",
            link: auOwner.auToken.id,
            name: auOwner.auToken.nameEng
          },
          tokensCount: {
            crypto: count.toString(10),
            spot: spotBalance,
            decimals: auOwner.auToken.decimals,
            indent: auOwner.auToken.indent,
            mnemonic: auOwner.auToken.mnemonicEng
          },
          tockenCost: currency,
          tokenSum: {
            value: sum,
            decimals: this.defaultToken.decimals,
            indent: this.defaultToken.indent
          }
        });
      });

      return ret;
    },

    crypto() {
      let ret = [];
      _.forEach(this.cryptoOwners, auOwner => {
        const count = BigNumber(auOwner.amount).dividedBy(BigNumber(10).pow(auOwner.auToken.decimals));
        let currency = BigNumber(this.currencySup.getCurrency(auOwner.auToken.id, this.defaultTokenMnemonic));
        let sum = count.multipliedBy(currency);

        currency = currency ? currency.toFixed(this.defaultToken.indent).replace(",", ".") : "0";
        sum = sum ? sum.toFixed(auOwner.auToken.indent).replace(",", ".") : "0";
        ret.push({
          company: {
            icon: auOwner.auToken.logoMain || "/svg/companies/" + _.get(auOwner, "icon", "s_logo") + ".svg",
            link: auOwner.auToken.id,
            name: auOwner.auToken.nameEng
          },

          tokensCount: count.toString(10),
          tockenCost: currency + ` (${this.defaultTokenMnemonic})`,
          tokenSum: sum
        });
      });
      return ret;
    },

    invest() {
      let ret = [];
      _.forEach(this.investOwners, auOwner => {
        const count = BigNumber(auOwner.amount).dividedBy(BigNumber(10).pow(auOwner.auToken.decimals));
        let currency = BigNumber(this.currencySup.getCurrency(auOwner.auToken.id, this.defaultTokenMnemonic));
        let sum = count.multipliedBy(currency);

        currency = currency ? currency.toFixed(this.defaultToken.indent).replace(",", ".") : "0";
        sum = sum ? sum.toFixed(auOwner.auToken.indent).replace(",", ".") : "0";
        ret.push({
          company: {
            icon: auOwner.auToken.logoMain || "/svg/companies/" + _.get(auOwner, "icon", "s_logo") + ".svg",
            link: auOwner.auToken.id,
            mnemonic: auOwner.auToken.mnemonicEng,
            name: auOwner.auToken.nameEng
          },
          tokensCount: count.toString(10),
          tockenCost: currency + ` (${this.defaultTokenMnemonic})`,
          tokenSum: sum
        });
      });
      return ret;
    }
  },

  async mounted() {
    this.defaultToken = _.find(this.tokens, token => token.id == this.defaultTokenId);
    await this.getDataPage();
  },

  methods: {
    ...mapActions({
      getOwnerPage: "getOwnerPage"
    }),

    toLink(link) {
      this.$router.push({ name: "token info", params: { id: link } });
    },

    send(index) {
      let id = null;
      switch (this.currentPanel) {
      case 0:
        id = this.fiatOwners[index].id;
        break;
      case 1:
        id = this.cryptoOwners[index].id;
        break;
      case 2:
        id = this.investOwners[index].id;
        break;
      }
      this.$router.push({ name: "send token", params: { id: id } });
    },

    topUp() {
      this.$router.push({ name: "buy form" });
    },

    async changePanel(index) {
      this.currentPanel = index;
      await this.getDataPage();
    },

    async getDataPage() {
      let resp;
      switch (this.currentPanel) {
      case 0:
        if (!this.fiatOwners) {
          this.loading = true;
          resp = await this.getOwnerPage({
            page: this.fiatPage,
            itemsPerPage: this.itemsPerPage,
            type: "fiat"
          });
          this.fiatOwners = resp.data;
          this.fiatTotalItems = resp.totalItems;
          this.loading = false;
        }
        break;
      case 1:
        if (!this.cryptoOwners) {
          this.loading = true;
          resp = await this.getOwnerPage({
            page: this.cryptoPage,
            itemsPerPage: this.itemsPerPage,
            type: "gold,utility"
          });
          this.cryptoOwners = resp.data;
          this.cryptoTotalItems = resp.totalItems;
          this.loading = false;
        }
        break;
      case 2:
        if (!this.investOwners) {
          this.loading = true;
          resp = await this.getOwnerPage({
            page: this.investPage,
            itemsPerPage: this.itemsPerPage,
            type: "asset"
          });
          this.investOwners = resp.data;
          this.investTotalItems = resp.totalItems;
          this.loading = false;
        }
        break;
      }
    },

    async pageChanged(actualPage) {
      switch (this.currentPanel) {
      case 0:
        this.fiatOwners = null;
        this.fiatPage = actualPage;
        break;
      case 1:
        this.cryptoOwners = null;
        this.cryptoPage = actualPage;
        break;
      case 2:
        this.investOwners = null;
        this.investPage = actualPage;
        break;
      }
      await this.getDataPage();
    }
  }
};
</script>

<style lang="scss" scoped>
.briefcase {
  &-info {
    padding: 11px 20px 0 18px;

    .title {
      font-weight: 600;
      font-size: 24px;
      color: var(--text-color);
      margin-bottom: 30px;
    }
  }

  &-table {
    @media screen and (max-width:520px) {
      display: none;
    }
  }

  &-accordion {
    display: none;

    @media screen and (max-width:520px) {
      display: block;
    }
  }
}

.spot-balance {
  color: var(--color-50);
  font-weight: 500;
  font-size: 12px;
}

.company {
  &-header {
    color: var(--text-color);
    font-size: 12px;
  }

  &-info {
    color: var(--text-color);
    display: flex;
    padding-right: 0;
    padding-left: 0;
    flex-direction: column;
    gap: 20px;

    &-title {
      color: var(--color-60)
    }

    .row {
      font-weight: 450;
      font-size: 12px;

      .col:last-child {
        text-align: end;
      }
    }
  }

  &-icon {
    width: 26px;
    height: 26px;
    margin-right: 5px;
  }
}

.icon-with-bg {
  width: 26px;
  height: 26px;
  margin-right: 8px;
  // background: var(--color-00);
  border-radius: 7px;
  padding: 4px;
}

.link {
  &:hover {
    cursor: pointer;
  }
}
</style>
