<template>
  <div class="p-form">
    <div class="d-flex">
      <div
        class="p-tab p-text"
        :class="{'p-checked-tab': !isEdit}"
        @click="isEdit=false"
      >
        Отображение
      </div>
      <div
        class="p-tab p-text"
        :class="{'p-checked-tab': isEdit}"
        @click="isEdit=true"
      >
        Редактирование
      </div>
    </div>
    <div v-if="isEdit">
      <div class="d-flex justify-content-end mb-4">
        <AuButton
          bordered
          border-radius="10"
          center
          @click="showAddOrEdit(null)"
        >
          Добавить поле
        </AuButton>
      </div>
      <div>
        <AuTable
          :columns="columns"
          :items="itemsView"
          :action-column="actionColumn"
          action-type="company"
          @del="showDeleteDialog"
          @edit="showAddOrEdit"
        />
      </div>
    </div>
    <div v-else>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="mb-4"
      >
        <AuInput
          v-model="item.value"
          :type="item.type"
          :label="item.name"
          :required="item.isRequired"
          @input="sendBody"
        />
      </div>
    </div>
    <AuModal
      v-model="editDialog"
      width="500px"
    >
      <template #header>
        <span class="modal_head_text">
          {{ editHeader }}
        </span>
      </template>
      <template #body>
        <div>
          <AuInput
            v-model="nameFiled"
            placeholder="Введите название поля"
            label="Название"
            required
          />
        </div>
        <div class="mt-3">
          <AuSelect
            :value="typeField"
            :options="typeOptions"
            placeholder="Выберите тип поля"
            select-label="Тип поля"
            required
            @input="(value) => typeField = value"
            @clearValue="typeField = null"
          />
        </div>
        <div class="mt-3 d-flex align-items-center">
          <input
            v-model="isRequiredFiled"
            type="checkbox"
          >
          <span class="modal_label">&nbsp;Обязательное поле</span>
        </div>
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="100px"
              @click="modifyIndex === null ? addItems() : editItems()"
            >
              {{ editButton }}
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="100px"
              @click="editDialog=false"
            >
              Отмена
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
    <AuModal
      v-model="deleteDialog"
      width="500px"
    >
      <template #header>
        <span class="modal_head_text">
          Удалить поле
        </span>
      </template>
      <template #body>
        <span>
          Вы уверены что хотите удалить поле {{ deleteName }}?
        </span>
      </template>
      <template #footer>
        <div class="modal_button">
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="deleteItem"
            >
              Да
            </AuButton>
          </div>
          <div class="modal_button_margin">
            <AuButton
              bordered
              center
              width="80px"
              @click="deleteDialog=false"
            >
              Нет
            </AuButton>
          </div>
        </div>
      </template>
    </AuModal>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "Params",
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isEdit: false,
      columns: [{ name: "Название поля" },
        { name: "Тип поля" },
        { name: "Статус" }],
      actionColumn: { label: "Действие", icon: "more" },
      deleteDialog: false,
      editDialog: false,
      nameFiled: null,
      typeField: null,
      isRequiredFiled: false,
      typeOptions: [{ display: "Строка", value: "Text" },
        { display: "Текст", value: "TextArea" },
        { display: "Число", value: "Number" }
      ],
      modifyIndex: null
    };
  },
  computed: {
    editHeader() {
      return this.modifyIndex === null ? "Добавить новое поле" : "Редактировать существующее поле";
    },
    editButton() {
      return this.modifyIndex === null ? "Добавить" : "Сохранить";
    },
    deleteName() {
      return this.modifyIndex === null ? null : this.items[this.modifyIndex].name;
    },
    items() {
      if (!_.isEmpty(this.modelValue)) {
        return _.map(this.modelValue.params, str => JSON.parse(str));
      }
      else {
        return [];
      }
    },
    itemsView() {
      return _.map(this.items, item => (
        {
          name: item.name,
          type: _.find(this.typeOptions, type => type.value === item.type)?.display,
          status: item.isRequired ? "Обязательно" : "Не обязательно"
        }));
    }
  },
  methods: {
    addItems() {
      this.editDialog = false;
      this.items.push(
        { name: this.nameFiled,
          type: this.typeField,
          isRequired: this.isRequiredFiled });
      this.showSuccess("Поле добавлено");
      this.sendBody();
    },

    editItems() {
      this.editDialog = false;
      this.items[this.modifyIndex] =
      { name: this.nameFiled,
        type: this.typeField,
        isRequired: this.isRequiredFiled };
      this.showSuccess("Поле отредактировано");
      this.sendBody();
    },

    deleteItem() {
      this.deleteDialog = false;
      this.items.splice(this.modifyIndex, 1);
      this.showSuccess("Поле удалено");
      this.modifyIndex = null;
      this.sendBody();
    },

    showAddOrEdit(index) {
      if (index === null) {
        this.nameFiled = null;
        this.typeField = null;
        this.isRequiredFiled = null;
      }
      else {
        this.nameFiled = this.items[index].name;
        this.typeField = this.items[index].type;
        this.isRequiredFiled = this.items[index].isRequired;
      }
      this.modifyIndex = index;
      this.editDialog = true;
    },

    showDeleteDialog(index) {
      this.modifyIndex = index;
      this.deleteDialog = true;
    },

    showSuccess(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    showError(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 4000,
        dismissible: true
      });
    },

    sendBody() {
      this.$emit("update:modelValue", { params: _.map(this.items, item => JSON.stringify(item)) });
    }
  }
};
</script>

<style scoped lang="scss">
.p-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
}
.p-tab{
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 9px;
  color:var(--color-60);
  background: var(--color-90);
  margin-right: 10px;
  cursor: pointer;
}
.p-checked-tab{
  color: var(--text-color);
  background: var(--menu-color);
}
.p-text{
  font-size: 15px;
  margin-bottom: 15px;
}
.modal_head_text {
  font-weight: 450;
  font-size: 20px;
  margin-bottom: 20px;
}
.modal_button {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}
.modal_button_margin {
  margin-right: 10px;
}
.modal_label{
  font-size: 13px;
}
</style>