<template>
  <div class="card">
    <div class="card__header">
      <div class="fs13">
        {{ $t("dashboard.transactions.label") }}
      </div>

      <a
        href="/wallet/transactions"
        class="card__action"
      >
        {{ $t("dashboard.transactions.goTo") }}
        <AuIcon icon="chevron_right" />
      </a>
    </div>

    <div class="period" />

    <div class="transactions">
      <LastTransaction
        v-for="(transaction, index) in transactions"
        :key="index"
        class="transaction"
        :icon="transaction.icon"
        :name="transaction.name"
        :transaction-type="transaction.type"
        :amount="transaction.amount"
        :is-refill="transaction.isRefill"
        :card-type="transaction.cardType"
        :decimals="transaction.decimals"
        :mnemonic="transaction.mnemonic"
        :indent="transaction.indent"
        :hash="transaction.hash"
        :address-eth="transaction.addressEth"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import LastTransaction from "./LastTransaction.vue";
import moment from "moment/moment";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    LastTransaction
  },

  data() {
    return {
      addressEth: null,
      transactions: [],
      totalItems: 1,
      page: 1
    };
  },

  async mounted() {
    await this.getTransactions();
  },

  computed: {
    ...mapGetters({
      arrEth: "ethAddresses"
    }),

    ourEth() {
      return _.find(this.arrEth, eth => !_.isNil(eth.verifiedAt))?.value;
    }
  },

  methods: {
    ...mapActions({
      getTransactionsPage: "getTransactionsPage"
    }),

    async getTransactions() {
      let resp = await this.getTransactionsPage({
        pageId: this.page
      });
      let data = resp.data;
      this.totalItems = resp.totalItems;

      const transfer = data.filter(item => !_.isUndefined(item.amount) && !(_.isUndefined(item.auToken)));
      if (transfer.length === 0) {
        return;
      }
      for (let i = 0; i < 4; i++) {
        this.arrEth.forEach((eth) => {
          if (_.lowerCase(eth.value) === _.lowerCase(transfer[i]?.addressFrom)) {
            this.addressEth = transfer[i]?.addressTo;
          }
          if (_.lowerCase(eth.value) === _.lowerCase(transfer[i]?.addressTo)) {
            this.addressEth = transfer[i]?.addressFrom;
          }
          if (transfer[i]?.addressFrom == 0) {
            this.addressEth = transfer[i]?.addressTo;
          }
        });
        this.transactions.push({
          icon: transfer[i].auToken.logoMain,
          name: this.addressEth ? this.addressEth.slice(0, 4) + "..." + this.addressEth.slice(-4) : "",
          addressEth: this.addressEth,
          hash: transfer[i].hash,
          type: transfer[i].addressFrom == 0 ? this.$t("transactions.emissionType") : this.$t("transactions.transferType"),
          amount: transfer[i].amount,
          cardType: moment(transfer[i].createdAt).format("DD.MM.YYYY"),
          isRefill: this.ourEth == transfer[i].addressTo,
          decimals: transfer[i].auToken.decimals,
          mnemonic: transfer[i].auToken.mnemonicEng,
          indent: transfer[i].auToken.indent
        });
      }
    }
  }
};

</script>

<style scoped lang="scss">
.period {
  margin: 15px 0px;
  font-size: 11px;
}

.transactions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.indention{
  margin-top: 50px;
}

.card {
  width: 100%;
  padding: 15px 22px 22px 22px;

  &__header {
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid var(--color-70);
    padding-bottom: 11px;
  }

  &__action {
    font-size: 11px;
    color: var(--color-60);
    text-decoration: none;
  }
}
</style>