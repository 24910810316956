<template>
  <button
    type="button"
    class="btn"
    :style="cssVars"
    :bordered="bordered"
    :customBordered="borderColor != ''"
    :center="center"
    :disabled="disabled || isProgress"
    @click="$emit('click')"
  >
    <SpinnerLoader
      v-if="isProgress"
      color="#5AC396"
      :size="loaderHeight"
    />
    <AuIcon
      v-if="icon && !isProgress"
      :icon="icon"
    />
    <div
      v-if="$slots['default'] && !isProgress"
      class="btn__text"
    >
      <slot />
    </div>
    <AuIcon
      v-if="iconAfter && !isProgress"
      :icon="iconAfter"
    />
  </button>
</template>

<script>
import SpinnerLoader from "@/loaders/progress-spinner";
export default {
  components: {
    SpinnerLoader
  },
  props: {
    icon: {
      type: String,
      default: ""
    },
    iconAfter: {
      type: String,
      default: ""
    },
    bordered: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: ""
    },
    borderRadius: {
      type: [Number, String],
      default: 8
    },
    borderColor: {
      type: String,
      default: ""
    },
    center: {
      type: Boolean,
      default: false
    },
    activeBgColor: {
      type: String,
      default: ""
    },
    hoveredBgColor: {
      type: String,
      default: "rgba(39, 144, 99, 0.04)"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: String,
      default: "14px"
    },
    isProgress: {
      type: Boolean,
      default: false
    }
  },

  emits: ["click"],

  data() {
    return {
      basicWidth: "auto",
      basicHeight: "44px"
    };
  },

  computed: {
    cssVars() {
      return {
        "--width": this.currentWidth,
        "--height": this.currentHeight,
        "--border-radius": this.borderRadius + "px",
        "--border-color": this.borderColor,
        "--background-color": this.activeBgColor,
        "--background-hovered-color": this.hoveredBgColor,
        "--font-size": this.fontSize
      };
    },

    onlyIcon() {
      return this.$slots["default"] == null;
    },

    currentWidth() {
      if (this.width) {
        return this.width;
      }
      else if (this.onlyIcon) {
        return "auto";
      }

      return this.basicWidth;
    },

    currentHeight() {
      if (this.height) {
        return this.height;
      }
      else if (this.onlyIcon) {
        return "auto";
      }

      return this.basicHeight;
    },

    loaderHeight() {
      return this.height ? this.height : "34px";
    }
  }
};
</script>

<style scoped lang="scss">
button[width-limit] {
  max-width: 10px;
  margin-right: -6px;
}
button.btn {
  border-radius: var(--border-radius);
  background: none;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  gap: 10px;

  width: var(--width);
  height: var(--height);

  border: none;

  .btn__text {
    color: var(--text-color);
    font-size: var(--font-size);
  }

  &[active=true] {
    background-color: rgba(39, 144, 99, 0.06);
    transition: background-color 0.5s ease;
    .btn__text {
      color: #5AC396;
      transition: color 0.5s ease;
      font-weight: 450;
    }
  }

  &:hover, &:active {
    background-color: var(--background-hovered-color);
    transition: background-color 0.5s ease;

    .btn__text {
      color: #5AC396;
      transition: color 0.5s ease;
    }
  }

  &[bordered=true] {
    border: 1px solid var(--color-70);
  }

  &[customBordered=true] {
    border: 1px solid var(--border-color);
  }

  &[center=true] {
    justify-content: center;
  }
}
</style>
